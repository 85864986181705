import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
// import "./style/RadioButton.css";

function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="RadioButtons">
      <label>{label}</label>
      <Field name={name}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div className="RadioButtons__input" key={option.key}>
                <br />
                <input
                  type="radio"
                  className="RadioButtons__input--rb"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RadioButtons;
