export function getName(value, number) {
  const TITLES_SIMULATION = {
    CountMultiples: `Contar de<br /><span>${number}</span> en <span>${number}</span>`,
    ConmutativeSum: `<span>Propiedad</span><br /> conmutativa`,
    AssociativeSum: `<span>Propiedad</span><br /> asociativa`,
    addSumaTab: `Tabla de sumar<br /> del <span>${number}</span>`,
    TableSum: `Tabla completa<br /> <span>de sumar del ${number}</span>`,
    DescomposeNumber: `<span>Descomposición de </span><br /> números de <span>${number}</span> digitos`,
    SumByDecomposition: `<span>Suma por</span><br />  descomposición`,
    TableSubtract: `<span>Tabla de</span><br />la resta del ${number}`,
    SubtractAdding: `Restar Sumando`,
    SubtractByApproximation: `Restar por aproximación ${
      number === 2
        ? 'a la <span>decena superior'
        : 'a la <span>centena superior'
    }</span>`,
    subtractByDecomposition: `Restar por descomposición`,
    ConmutativeMultiplication: `Propiedad conmutativa`,
    AssociativeMultiplication: `Propiedad asociativa`,
    DistributiveMultiplication: `Propiedad distributiva`,
    NumberComplements: `Complemento de un número`,
    FoldNumbers: `Doblar números`,
    MultiplationByFive: `Multiplicacion por 5`,
    AddConsecutiveNumbers: `Sumar números Consecutivos`,
    Adding5NumbersIn3Seconds: `Sumar mentalmente 5<br /> números en 3 segundos`,
    MultiplicationTable: `Tabla de la multiplicación`,
    MultiplicationDecomposition: `Multiplicación por descomposición`,
    BladeMethod: `Método Aspa`,
    MultiplyNumberBy10: `Multiplicar un número<br /> por otro que es potencia de 10`,
    MultiplyNumberByAnotherThatEndsIn0: `Multiplicar un número<br /> por otro que termine en ceros`,
    'MultiplicationBy9´s': 'Multiplicar por un<br /> número que tiene 9´s',
    MultiplicationByEleven: `Multiplicar un número por 11`,
    Squared: `Elevar un número<br /> al cuadrado`,
    'Square2-DigitNumber': `Elevar un número<br /> al cuadrado de 2 dígitos`,
    RaiseAnySquareNumber: `Elevar cualquier número al cuadrado`,
    SumByApproximation: `Suma por aproximación`,
    AspaSimple: `Método Aspa`,
    DivisionByVédicoMethod: `División por el<br /> método <span>Védico</span>`,
    DivisionByTraditionalMethod: `División con el<br /> método tradicional`,
    DivisionTable: `Tablas de la División`,
    SquaredFive: `Elevar un número<br /> al cuadrado que termina en 5`,
  }

  return TITLES_SIMULATION[value] || undefined
}

export const titleSimulation = {
  CountMultiples: `Contar en Múltiplos`,
  ConmutativeSum: `Propiedad conmutativa`,
  AssociativeSum: `Propiedad asociativa`,
  addSumaTab: `Tablas de la suma`,
  TableSum: `Tabla completa de la suma`,
  DescomposeNumber: `Descomposición de números`,
  SumByDecomposition: `Suma por descomposición`,
  TableSubtract: `Tabla de la Resta`,
  SubtractAdding: `Restar Sumando`,
  SubtractByApproximation: `Restar Por Aproximación`,
  subtractByDecomposition: `Restar Por Descomposición`,
  ConmutativeMultiplication: `Propiedad Conmutativa`,
  AssociativeMultiplication: `Propiedad Asociativa`,
  NumberComplements: `Complemento de un número`,
  DistributiveMultiplication: `Propiedad Distributiva`,
  FoldNumbers: `Doblar números`,
  MultiplationByFive: `Multiplicar por 5`,
  AddConsecutiveNumbers: `Sumar números Consecutivos`,
  Adding5NumbersIn3Seconds: `Sumar mentalmente 5 números en 3 segundos`,
  MultiplicationTable: `Tabla de la multiplicación`,
  MultiplicationDecomposition: `Multiplicación por Descomposición`,
  BladeMethod: `Método Aspa`,
  MultiplyNumberBy10: `Multiplicar un número por otro que es potencia de 10`,
  MultiplyNumberByAnotherThatEndsIn0: `Multiplicar un número por otro que termine en ceros`,
  'MultiplicationBy9´s': `Multiplicar por un número que tiene 9´s`,
  MultiplicationByEleven: `Multiplicar un número por 11`,
  Squared: `Elevar un número al cuadrado`,
  'Square2-DigitNumber': `Elevar un número al cuadrado de 2 dígitos`,
  RaiseAnySquareNumber: `Elevar cualquier número al cuadrado`,
  SumByApproximation: `Suma por aproximación`,
  AspaSimple: `Método Aspa`,
  DivisionByVédicoMethod: `División por el método Védico`,
  DivisionByTraditionalMethod: `División con el método tradicional`,
  DivisionTable: `Tablas de la División`,
  SquaredFive: `Elevar un número al cuadrado que termina en 5`,
}
