import './style/RegisterChildren.css'
import { Formik, Form } from 'formik'
import { H2, H3 } from '../Text'
import { useState } from 'react'
import { DefaultButton } from '../buttons'
import { FormikControl } from '../inputs'
import { BoxFlex } from '../boxes'
import * as Yup from 'yup'
import Loading from '../animation/Loading'
import { validateStatus } from '../../utils/Validation'
import { postAction, putAction } from '../../services/actions/AuthAction'
const RegisterChildren = (props) => {
  const { reload, onClose, handleSignIn, submit = true, data, setshowForme, showForme } = props
  const [view, setview] = useState(0)
  function handleOnClick(_, number) {
    if (number !== undefined) {
      setview(view + number)
    } else {
      setview(view + 1)
    }
  }
  async function handleValidateUser(username) {
    const res = await putAction('/users/username', { Username: username })
    if (validateStatus(res.status)) {
      handleOnClick()
    } else {
      props.openSnackbar('El usuario ya existe', true)
    }
  }
  const [load, setload] = useState(true)
  const initialValues = {
    Character: data ? data.Character : '',
    Name: data ? data.Name : '',
    LastName: data ? data.LastName : '',
    BirthDate: data ? data.BirthDate : '',
    Username: data ? data.Username : '',
    password: data ? data.password : '',
    password_confirmation: data ? data.password_confirmation : '',
  }
  const validationSchema = Yup.object({
    Character: Yup.string().required('Campo Obligatorio'),
    Name: Yup.string().required('Campo Obligatorio'),
    LastName: Yup.string().required('Campo Obligatorio'),
    BirthDate: Yup.string().required('Campo Obligatorio'),
    Username: Yup.string().required('Campo Obligatorio'),
    password: Yup.string()
      .required('Ingrese una contraseña')
      .min(4, 'Mínimo 4 caracteres'),
    password_confirmation: Yup.string()
      .required('Este campo es requerido')
      .min(4, 'Mínimo 4 caracteres')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(
        `/auth/${window.localStorage.getItem('id')}/fatherId`,
        values,
      ).then((res) => {
        setload(true)
        if (validateStatus(res.status)) {
          handleSignIn(res.data)
          reload()
          setview(4)
          props.openSnackbar('Se inscribió correctamente', true, true)
        } else {
          props.openSnackbar('Error al guardar', true)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  function SquareProgress({ view, step }) {
    return (
      <div
        style={{ background: view <= step ? '#B1D9D1' : '#AA7CBC' }}
        className="SquareProgress"
      ></div>
    )
  }
  return (
    <div className="RegisterChildren" style={{ marginTop: '10px' }}>
      <div className="RegisterChildren__progress">
        <SquareProgress view={view} step={0} />
        <SquareProgress view={view} step={1} />
        <SquareProgress view={view} step={2} />
        <SquareProgress view={view} step={3} />
      </div>
      <br />
      <div className="RegisterChildren__form">
        <div className="RegisterChildren__circle circle1"></div>
        <div className="RegisterChildren__circle circle2"></div>
        <div className="RegisterChildren__circle circle3"></div>
        <div className="RegisterChildren__circle circle4"></div>
        {view < 1 &&
          <div className="RegisterChildren__textContainer">
            <H2 color="var(--white)" textAlign="center">
              {view === 4 ? 'REGISTRO COMPLETADO' : `COMPRAR PARA MI NIÑ@`}
            </H2>
          </div>
        }
        <br />
        {view === 4 ? (
          <>
            <H3 color="var(--purple)" textAlign="center" fontSize="2rem">
              Realizaste con éxito el registro de tu hij@
            </H3>
            <br />
            <div style={{ display: 'flex', gap: '10px' }}>
              <DefaultButton
                onClick={onClose}
                fontSize="1.8rem"
                width="100%"
                padding="8px 20px"
                background="white"
                border="1px solid var(--orange)"
                color="var(--orange)"
              >
                Finalizar registro
              </DefaultButton>
              <DefaultButton
                fontSize="1.8rem"
                width="100%"
                padding="8px 20px"
                onClick={() => setview(0)}
              >
                Registrar otro niñ@
              </DefaultButton>
            </div>
          </>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submit ? onSubmit : handleSignIn}
          >
            {(formik) => {
              return (
                <Form>
                  {view === 0 && (
                    <>
                      <H3
                        color="var(--purple)"
                        textAlign="center"
                        fontSize="2.5rem"
                      >
                        ¿Para quién es esta cuenta?
                      </H3>
                      <div className="RegisterChildren__character">
                        <div
                          onClick={() =>
                            formik.setValues((a) => ({
                              ...a,
                              Character: 'boy',

                            }))
                          }
                          className={`RegisterChildren__male ${formik.values.Character === 'boy' ? 'activate' : ''
                            }`}
                        >
                          <div className="RegisterChildren__rectangle">
                            <H3
                              margin="0"
                              color="var(--white)"
                              textAlign="center"
                            >
                              Niño
                            </H3>
                          </div>
                          <img
                            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/boy_feliz_jp5bbf_vnjy7y.png"
                            alt="male"
                          />
                        </div>
                        <div
                          onClick={() =>
                            formik.setValues((a) => ({
                              ...a,
                              Character: 'girl',
                            }))
                          }
                          className={`RegisterChildren__female ${formik.values.Character === 'girl' ? 'activate' : ''
                            }`}
                        >
                          <div className="RegisterChildren__rectangle">
                            <H3
                              margin="0"
                              color="var(--white)"
                              textAlign="center"
                            >
                              Niña
                            </H3>
                          </div>
                          <img
                            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473177/assets/girl_feliz_tnkmki_dmd5lw.png"
                            alt="female"
                          />
                        </div>
                      </div>
                      <br />
                      <br />
                      <center>
                        {!showForme ?
                          <BoxFlex>

                            <DefaultButton
                              width="50%"
                              background="white"
                              border="1px solid #AA7CBC"
                              color="#AA7CBC"
                              onClick={() => { setshowForme(true) }}
                            >
                              Desmarcar
                            </DefaultButton>
                            <DefaultButton
                              width="50%"
                              disabled={formik.values.Character === ''}
                              background="#AA7CBC"
                              onClick={() => { handleOnClick(); setshowForme(false); }}
                            >
                              Siguiente
                            </DefaultButton>
                          </BoxFlex> :

                          <DefaultButton
                            width="50%"
                            disabled={formik.values.Character === ''}
                            background="#AA7CBC"
                            onClick={() => { handleOnClick(); setshowForme(false); }}
                          >
                            Siguiente
                          </DefaultButton>
                        }
                      </center>
                    </>
                  )}
                  {view === 1 && (
                    <>
                      <H3
                        color="var(--purple)"
                        textAlign="center"
                        fontSize="2.5rem"
                        fontWeight="bold"
                      >
                        ¿CÓMO SE LLAMA TU {formik.values.Character == 'boy' ? "NIÑO" : "NIÑA"}?
                      </H3>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Nombres"
                        name="Name"
                      />
                      <FormikControl
                        control="input"
                        type="text"
                        label="Apellidos"
                        name="LastName"
                      />
                      {/* <H3
                        color="var(--purple)"
                        textAlign="center"
                        fontSize="2rem"
                      >
                        ¿Cúando es su cumpleaños?
                      </H3> */}
                      <FormikControl
                        control="input"
                        type="date"
                        label="Fecha de nacimiento"
                        name="BirthDate"
                      />
                      <BoxFlex>
                        <DefaultButton
                          width="50%"
                          background="white"
                          border="1px solid #AA7CBC"
                          color="#AA7CBC"
                          onClick={(e) => { handleOnClick(e, -1) }}
                        >
                          Volver
                        </DefaultButton>
                        <DefaultButton
                          width="50%"
                          background="#AA7CBC"
                          onClick={handleOnClick}
                          disabled={
                            formik.values.Name === '' ||
                            formik.values.LastName === ''
                          }
                        >
                          Siguiente
                        </DefaultButton>
                      </BoxFlex>
                    </>
                  )}
                  {view === 2 && (
                    <>
                      <H3 textAlign="center" fontSize="2rem">
                        Elija el nombre de usuario para su {formik.values.Character == 'boy' ? "niño" : "niña"}
                      </H3>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Usuario"
                        name="Username"
                      />
                      <BoxFlex>
                        <DefaultButton
                          width="50%"
                          background="white"
                          border="1px solid #AA7CBC"
                          color="#AA7CBC"
                          onClick={(e) => handleOnClick(e, -1)}
                        >
                          Volver
                        </DefaultButton>
                        <DefaultButton
                          width="50%"
                          disabled={formik.values.Username === ''}
                          background="#AA7CBC"
                          onClick={() =>
                            handleValidateUser(formik.values.Username)
                          }
                        >
                          Siguiente
                        </DefaultButton>
                      </BoxFlex>
                    </>
                  )}
                  {view === 3 && (
                    <>
                      <H3 textAlign="center" fontSize="2rem">
                        Elija la contraseña para este usuario
                      </H3>
                      <FormikControl
                        control="input"
                        type="password"
                        label="Contraseña"
                        name="password"
                      />
                      <FormikControl
                        control="input"
                        type="password"
                        label="Confirmar Contraseña"
                        name="password_confirmation"
                      />
                      {load ? (
                        <BoxFlex>
                          <DefaultButton
                            width="50%"
                            background="white"
                            border="1px solid #AA7CBC"
                            color="#AA7CBC"
                            onClick={(e) => handleOnClick(e, -1)}
                          >
                            Volver
                          </DefaultButton>
                          <DefaultButton
                            width="50%"
                            disabled={!formik.isValid}
                            type="submit"
                          >
                            {submit ? 'Registrar' : 'Siguiente paso'}
                          </DefaultButton>
                        </BoxFlex>
                      ) : (
                        <Loading />
                      )}
                    </>
                  )}
                </Form>
              )
            }}
          </Formik>
        )}
      </div>
    </div>
  )
}

export default RegisterChildren
