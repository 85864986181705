import { validateArray } from '../../utils/Validation'
import { useState } from 'react'
import { H2 } from '../Text'
import './style/RenderGame.css'
import RenderOptions from './RenderOptions'
import { useEffect } from 'react'
const RenderGame = (props) => {
  const {
    gameData,
    optionList,
    aciertos,
    fallos,
    property = null,
    indexGame,
    setindexGame,
  } = props
  useEffect(() => {
    if (fallos !== 0 || aciertos !== 0) {
      handleIndex(parseInt(indexGame.principal) + parseInt(gameData.baseNumber))
    }
  }, [fallos, aciertos])

  function handleIndex(nextNumber) {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
        principal: nextNumber,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      property={property}
      indexGame={indexGame}
      {...props}
    />
  )
}
function OptionGame(props) {
  const { dataGame, principal, property, operation, indexGame } = props
  const ListColor = ['#6A3081', '#D17742', '#458E7F']
  const decimalSystem = ['unidad', 'decena', 'centena', 'unidad de mil']

  function concatNumber(list, op) {
    let aux = ''
    list.forEach((item, index) => {
      aux = aux + ` ${item} ${list.length - 1 <= index ? '' : op}`
    })
    return aux
  }

  function handleRigth(number, list) {
    let response = ''
    list.forEach((opt, index) => {
      if (number === opt) {
        response = decimalSystem[index]
      }
    })
    return response
  }

  const render = {
    listRandom: concatNumber(dataGame.random, operation),
    listBaseNumber:
      indexGame.baseNumber +
      ' ' +
      operation +
      concatNumber(dataGame.random, operation),
    listRandom: concatNumber(dataGame.random, operation),

    textDescompose: `Elige la ${handleRigth(
      dataGame.rightAnswer,
      dataGame.answers,
    )} del
    ${validateArray(dataGame?.random) ? dataGame.random[0] : ''}`,
  }

  return (
    <div className="RenderGame">
      <H2
        textAlign="center"
        fontWeight="900"
        color="var(--purple)"
        fontSize="6rem"
      >
        {render[property] || principal}
      </H2>
      {validateArray(dataGame?.answers) ? (
        <div className="RenderGame__list">
          {dataGame.answers
            .map((number, index) => (
              <RenderOptions
                key={index}
                number={number}
                background={ListColor[index % 3]}
                {...props}
              />
            ))
            .reverse()}
        </div>
      ) : null}
    </div>
  )
}
export default RenderGame
