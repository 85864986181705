const Title = (props) => {
  const {
    children,
    textAlign = 'start',
    fontWeight = '500',
    margin = '0',
    fontSize = '2.6rem',
    color = 'var(--black)',
    innerHTML = false,
    width = '100%',
    textTransform = 'none',
    ...rest
  } = props

  return innerHTML ? (
    <h1
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      className="Title"
      style={{
        width: width,
        color: color,
        fontFamily: "'Spartan', sans-serif",
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
        textAlign: textAlign,
        textTransform: textTransform,
      }}
      {...rest}
    ></h1>
  ) : (
    <h1
      className="Title"
      style={{
        width: width,
        color: color,
        fontFamily: "'Spartan', sans-serif",
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
        textAlign: textAlign,
        textTransform: textTransform,
      }}
      {...rest}
    >
      {children}
    </h1>
  )
}

export default Title
