import ModalDefault from './ModalDefault'
import { Title } from '../Text'
import { DefaultButton } from '../buttons'
import { useEffect } from 'react'
import { postAction } from '../../services/actions/Action'

const ModalLocked = (props) => {
  const { modal, handleModal, viewVideo } = props
  useEffect(() => {
    async function onSubmit() {
      try {
        await postAction('/notification', {
          Content:
            'Tu hijo intento continuar con el curso. Aqui puede ver su avance y adquirir el curso.',
          Url: '/management',
        })
      } catch (e) {
        console.log(e)
      }
    }
    if (modal?.notify) {
      onSubmit()
    }
  }, [modal])

  // [POST] http://localhost:5000/api/notification/
  // {
  //     "Content" : "contenido de la notification",
  //     "Url" : "www,asd,asd.com"
  // }
  function handleVideo() {
    viewVideo()
    handleModal('')
  }
  return modal.state ? (
    <ModalDefault
      background="rgba(0, 0, 0, 0.5)"
      onClose={() => handleModal('')}
    >
      <div
        style={{ background: 'white', padding: '10px', borderRadius: '10px' }}
      >
        <Title textAlign="center" width="auto">
          {modal.text}
        </Title>
        <br />
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <DefaultButton
            background={`var(--${modal.index === 0 ? 'gray' : 'purple'})`}
            width="50%"
            onClick={() => handleModal('')}
          >
            Cerrar
          </DefaultButton>
          {modal.index === 0 && (
            <DefaultButton
              background="var(--purple)"
              width="50%"
              onClick={handleVideo}
            >
              Ver tutorial
            </DefaultButton>
          )}
        </div>
        <br />
      </div>
    </ModalDefault>
  ) : null
}

export default ModalLocked
