import './style/GlobalStyle.css'
const DefaultButton = (props) => {
  const {
    children,
    type,
    onClick,
    style = '',
    width = '60%',
    disabled = false,
    background = 'var(--orange)',
    color = 'var(--white)',
    border = 'none',
    padding = '8px 0',
    fontSize = '3rem',
    borderRadius = '10px',
  } = props

  return (
    <button
      type={type ? type : 'button'}
      onClick={onClick}
      className="DefaultButton"
      style={{
        background: style,
        width: width,
        padding: padding,
        fontSize: fontSize,
        background: background,
        color: color,
        border: border,
        cursor: 'pointer',
        borderRadius: borderRadius,
      }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default DefaultButton
