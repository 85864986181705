import { isAuthenticated } from "./Protected";
export function Safeguard(user, validations) {
  let sw = { bool: true, route: "/" };
  for (let i = 0; i < validations.length; i++) {
    switch (validations[i]) {
      case "isAuthenticated":
        sw = isAuthenticated(user);
        break;
      default:
    }
    if (!sw) {
      i = validations.length;
    }
  }
  return sw;
}
