export const fakeData = [
  {
    id: 1,
    name: 'Contar de 2 en 2',
    suma: 2,
    tope: 12,
    timeLimit: '00:01:15',
    timeGame: '00:00:13',
    nextLevel: 2,
  },
  {
    id: 2,
    name: 'Contar de 3 en 3',
    suma: 3,
    tope: 12,
    timeLimit: '00:00:15',
    timeGame: '00:00:03',
    nextLevel: 3,
  },
  {
    id: 3,
    name: 'Contar de 4 en 4',
    suma: 4,
    tope: 12,
    timeLimit: '00:00:15',
    timeGame: '00:00:03',
    nextLevel: 4,
  },
  {
    id: 4,
    name: 'Contar de 5 en 5',
    suma: 5,
    tope: 10,
    timeLimit: '00:00:15',
    timeGame: '00:00:03',
    nextLevel: null,
  },
]
export const wrongData = {
  wrong_usernameOrEmail: 'No se encuentra el usuario',
  invalid_password: 'Contraseña incorrecta',
  username_exists: 'El usuario ya existe',
  email_exists: 'El correo ya esta siendo usado',
  contact_phone_exist: 'Ya enviaste anteriormente tus datos',
}

export const optionLevel = {
  easy: 'Fácil',
  medium: 'Medio',
  hard: 'Difícil',
  extraHard: 'Muy difícil',
}

export const backgroundImage = {
  6: 'https://res.cloudinary.com/wmcgi/image/upload/v1660917742/assets/Fotos-02_lumv9e.jpg',
  5: 'https://res.cloudinary.com/wmcgi/image/upload/v1660917739/assets/Fotos_Mesa_de_trabajo_1_bdqljc.jpg',
  4: 'https://res.cloudinary.com/wmcgi/image/upload/v1660917734/assets/Foto_teleferico_ukhpgr.jpg',
  3: 'https://res.cloudinary.com/wmcgi/image/upload/v1660917750/assets/Fotos-05_t5hvpy.jpg',
  2: 'https://res.cloudinary.com/wmcgi/image/upload/v1660917746/assets/Fotos-06_uz7nxg.jpg',
}
