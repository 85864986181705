import React, { useState } from 'react'
import './ButtonFloating.css'
const ButtonFloating = ({ text }) => {
  const [state, setState] = useState(true)
  const handleState = () => {
    setState(!state)
  }
  if (state) {
    return (
      <div className="ButtonFloating" onClick={handleState}>
        <i className="fab fa-whatsapp"></i>
      </div>
    )
  } else {
    return (
      <div className="CardFloating">
        <div className="CardFloating__header">
          <div className="CardFloating__title">
            <i className="fab fa-whatsapp"></i>
            <h3>Whatsapp</h3>
          </div>
          <div className="CardFloating__close" onClick={handleState}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="CardFloating__main">
          <ul>
            <li>
              ¿Quieres inscribirte a alguno de los cursos?, te ayudamos
              encantados.
            </li>
            <li>Si tienes otras consultas nos gustará escucharte.</li>
          </ul>
        </div>
        <div className="CardFloating__btn">
          <a
            href="https://wa.me/59170140353?text=%C2%A1Hola%21+Quisiera+m%C3%A1s+informaci%C3%B3n+acerca+del+curso+de+matemáticas&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Abrir chat</span>
          </a>
        </div>
        <br />
      </div>
    )
  }
}

export default ButtonFloating
