import './Auth.css'
import { Switch, Route, withRouter, Link } from 'react-router-dom'
import { Log_in, Recover_in, PasswordRecovery } from '../../components/forms'
import Home from '../Home/Home'
import ModalDefault from '../../components/modal/ModalDefault'
import log from '../../assets/img/logo2.png'
import { Facebook, Google } from '../../components/buttons'
import { Title, H3 } from '../../components/Text'
import { startSession, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import { Tutorial } from '../Tutorial/Tutorial'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const Auth = (props) => {
  const { history } = props 
  const location = useLocation(); // Obtiene la información de la ruta actual
  const isRecoveryRoute = location.pathname.startsWith('/auth/recover') || location.pathname.startsWith('/auth/passwordrecovery');
  return (
    <div className="Auth">
      
      <Home modalDefault={false} playVideo={false} />
      <ModalDefault closeOutside={true} onClose={() => history.push('/')}>
        <div className="Auth__rotate">
          <div className="Auth__view">
          {!isRecoveryRoute && (
            <div>
              <H3
                fontSize="2rem"
                textAlign="end"
                margin="0 0 5px"
                color="var(--green)"
              >
               <Link
                  to="/register"
                // to={{
                //   pathname: '/register',
                //   state: {
                //     from: props.location.state
                //       ? props.location.state.from
                //       : '/',
                //   },
                // }}
                >
                  REGÍSTRATE
                </Link>
              </H3>
              <Title textAlign="center" fontSize="2.5rem" color="#6A3081">Iniciar Sesión</Title>
            </div>
          )}


            <div className="Auth__container">
              <div className="Auth__contImagButtons">
                <div className="Auth__img">
                  <img alt="logo" src={log} />
                </div>
                {/* <Tutorial
                  title="¿Tienes problemas con el registro?"
                  subTitle="Mira el tutorial"
                  textBtn="Tutorial"
                  {...props}
                  videoModalTitle="Tutorial de registro"
                /> */}
                      {/* <div>
                        <div style={{ minWidth: '180px' }}></div>
                        <br />
                        {/* <Facebook text="Facebook" {...props} /> }
                        <Google text="Google" {...props} />
                      </div> */}
              </div>
              <div className="Auth__form">
              {!isRecoveryRoute && (
              <div>
                  <div style={{ minWidth: '180px' }}></div>
                  <br />
                  {/* <Facebook text="Facebook" {...props} /> */}
                  <Google text="Continuar con Google" {...props} />
              </div>)}

                <Switch>
                  <Route path="/auth/login" component={Log_in} />
                  <Route path="/auth/recover" component={Recover_in} />
                  <Route
                    path="/auth/passwordrecovery/:verifyKey"
                    component={PasswordRecovery}
                  />
                  {/* <Route path="/auth/register" component={Register_in} /> */}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
  )
}

export default connect(null, { startSession, openSnackbar })(withRouter(Auth))
