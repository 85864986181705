import { useState, useEffect } from "react";
const useActionParam = ({ action, param }) => {
    const [request, setRequest] = useState({
        dataRequest: null,
        statusRequest: null,
        headersRequest: null,
    });
    function reload() {
        setRequest({ dataRequest: null });
    }
    useEffect(() => {
        if (request.dataRequest === null) {
        if (param) {
            action(param).then((response) => {
            let dataRequest;
            let statusRequest;
            let headersRequest;
            if (response) {
                statusRequest = response.status;
                dataRequest = response.data;
                headersRequest = response.headers;
            } else {
                statusRequest = 500;
                dataRequest = undefined;
                headersRequest = undefined;
            }
            setRequest({
                dataRequest,
                statusRequest,
                headersRequest,
            });
            });
        } else {
            setRequest({
            dataRequest: undefined,
            statusRequest: 400,
            headersRequest: undefined,
            });
        }
        }
    }, [action, param, request]);
    return { ...request, reload };
};

export default useActionParam;
