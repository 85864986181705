import './style/ExternalLogin.css'
import GoogleLogin from 'react-google-login'
import { postAction } from '../../services/actions/AuthAction'
import { verifyStudent } from '../../utils/Validation'
const Google = (props) => {
  const {
    // data,
    // setData,
    fillingFileds,
    handleOnChange2,
    startSession,
    history,
    openSnackbar,
    text = 'Regístrate con Google',
    isRegister = false
  } = props
  // console.log(startSession, history, openSnackbar);
  const responseGooglef = (e) => {
    if (e.error !== 'popup_closed_by_user')
      openSnackbar('Error al iniciar sesión', true, false)
  }

  const responseGoogle = async (response) => {
    const usuario = {
      RegisterOption: 'Google',
      AccessToken: response.accessToken,
      UserID: response.googleId,
      ExpiresIn: response.tokenObj.expires_in,
      ReauthorizeRequiredIn: '',
      Email: response.profileObj.email,
      FirstName: response.profileObj.givenName,
      LastName: response.profileObj.familyName,
      PhotoUrl: response.profileObj.imageUrl,
    }
    await postAction('/auth/registerExternalLogin', usuario).then((res) => {
      if (res.status === 200 && res) {
        openSnackbar('Inicio Exitoso', true, true)
        const { institutions, user, token, rols, userDataTest, firstSession} = res.data
        window.localStorage.setItem('id', user.id)
        window.localStorage.setItem('token_seguridad', token)
        startSession(user, rols || [], institutions, true, userDataTest)
        // setData((prevData) => ({
        //   ...prevData,
        //   user: {
        //     ...prevData.user,
        //     name: 'wilson'
        //   }
        // }));
        if (fillingFileds) {
          handleOnChange2('name', res.data?.user?.firstName ?? '')
          handleOnChange2('lastname', res.data?.user?.lastName ?? '')
          handleOnChange2('email', res.data?.user?.email ?? '')
          // handleOnChange2('paisIndex', 'BO' ?? '')
          // handleOnChange2('estado_lbl', res.data?.user?.city ?? '')

        }
        if(isRegister && !firstSession && history){
                   
          history.push('/management');
          
        }
        if(!isRegister){

        
        if (history && rols && verifyStudent(rols[0])) {
          history.push('/lessons')
        }
        else if (history) {
          history.push('/welcome')
        }
      }
        // } else if (res.data.firstSession) {
        //   props.history.push('/management')
      } else {
        openSnackbar(
          res.data ? res.data : 'Error al iniciar sesión',
          true,
          false,
        )
      }
    })
  }

  return (
    <GoogleLogin
      clientId="1091756036227-gel7jr3i1daoaqmo1u0gq3lfn45cdp10.apps.googleusercontent.com"
      render={(renderProps) => (
        <button className="btnExternal" onClick={renderProps.onClick}>
          {text} <i className="fab fa-google"></i>
        </button>
      )}
      buttonText="Google"
      onSuccess={responseGoogle}
      onFailure={responseGooglef}
      cookiePolicy={'single_host_origin'}

    />
  )
}
export default Google
