import './style/GlobalButton.css'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const GlobalLinkButton = (props) => {
  const { children, type = 'button', to = '/', style = '' } = props
  return (
    <LinkStyle>
      <Link
        className="link_style"
        type={type}
        to={to}
        style={{ background: style, textDecoration: 'none' }}
      >
        {children}
      </Link>
    </LinkStyle>
  )
}

const LinkStyle = styled.div`
  margin-top: 18px;
  .link_style {
    border-radius: 25px 10px 20px 10px;
    padding: 5px 50px;
    /* background-color: #458e7f; */
    color: white;
    font-weight: 500;
  }
  /* .link_style:hover {
    background: #458e7f37;
    transition: 0.5s;
    padding: 8px 51px;
  } */
`

export default GlobalLinkButton
