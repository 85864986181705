import { BrowserRouter } from 'react-router-dom'
import { Main } from '../../routes'
import Nav from './Nav/Nav'
import './Layout.css'
const Layout = (props) => {
  return (
    <BrowserRouter>
      {props.statusNavbar && (
        <div className="itemNav">
          <Nav {...props} />
        </div>
      )}
      <div className="itemContent">
        <Main {...props} />
      </div>
    </BrowserRouter>
  )
}

export default Layout
