import './Scores.css'
import { useAxiosAuth } from '../../hook'
import { capitalizeFirstLetter, validateArray } from '../../utils/Validation'
import { H2 } from '../../components/Text'
import { ButtonIcon } from '../../components/buttons'

import TableMain from '../Table/TableMain'
import Loading from '../../components/animation/Loading'
const Scores = () => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: '/Score/Top',
  })

  const header = [
    { key: 'position', name: 'Posición' },
    { key: 'nickname', name: 'Nombre' },
    { key: 'total', name: 'Puntaje Total' },
  ]

  function preConfig(list) {
    let auxList = []
    list.forEach((u) => {
      u.nickname = capitalizeFirstLetter(u.nickname)
      auxList.push(u)
    })

    return auxList
  }
  return (
    <div className="Scores">
      <ButtonIcon to="/management" />
      <div className="Scores__container">
        <div className="Scores__main">
          <H2
            textAlign="center"
            color="var(--white)"
            fontSize="3rem"
            fontWeight="bold"
          >
            TABLA DE POSICIONES
          </H2>
          <br />
          {loading ? <Loading background="var(--white)"/>: validateArray(response) ? (
            <TableMain header={header} main={preConfig(response)} />
            
          ) : (
            <H2 textAlign="center" color="var(--gray)">
              Su hijo debe jugar con los simuladores para tener estadísticas
            </H2>
          )}
        </div>
      </div>
    </div>
  )
}



export default Scores
