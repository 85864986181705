import { BoxFlexPagination } from '../../components/boxes'
import Icon from '../../components/buttons/Icons'
import H4 from '../../components/Text/H4'

function generatePage(header) {
  if (header && header.pagination) {
    return JSON.parse(header.pagination)
  }
  return null
}
const TableFooter = ({ amount, pageInfo, seturl, url, reload }) => {
  const header = generatePage(pageInfo)
  function handlePageNumber(number) {
    seturl((a) => ({ ...a, PageNumber: a.PageNumber + number }))
    // reload()
  }
  let leftpages = []
  let rigthpages = []
  function updatePages() {
    if (url) {
      for (
        let i = url.PageNumber + 1;
        i <=
        (header.totalPages > url.PageNumber + 2
          ? url.PageNumber + 2
          : header.totalPages);
        i++
      ) {
        rigthpages.push({ index: i })
      }
      for (
        let i = url.PageNumber - 1 > 2 ? url.PageNumber - 3 : 0;
        i < url.PageNumber - 1;
        i++
      ) {
        leftpages.push({ index: i + 1 })
      }
    }
  }
  function changePage(index) {
    seturl((a) => ({ ...a, PageNumber: index }))
    // reload()
  }
  updatePages()
  if (header) {
    return (
      <BoxFlexPagination>
        <H4 margin="0" fontSize="1.4rem">
          Mostrando del {(url.PageNumber - 1) * header.itemsPerPage + 1} -{' '}
          {(url.PageNumber - 1) * header.itemsPerPage + amount} de{' '}
          {header.totalItems}
        </H4>
        <BoxFlexPagination direction="row">
          {leftpages[0] ? (
            leftpages[0].index !== 1 ? (
              <Icon
                handleModal={() => changePage(1)}
                disabled={false}
                children={'1'}
              />
            ) : null
          ) : null}
          <Icon
            handleModal={() => handlePageNumber(-1)}
            disabled={header.currentPage === 1 ? true : false}
            ico="chevron-left"
          />
          {leftpages.map((element) => (
            <Icon
              handleModal={() => changePage(element.index)}
              disabled={false}
              children={element.index}
            />
          ))}
          <Icon disabled={true} children={url.PageNumber} />
          {rigthpages.map((element) => (
            <Icon
              handleModal={() => changePage(element.index)}
              disabled={false}
              children={element.index}
            />
          ))}
          <Icon
            disabled={header.totalPages === url.PageNumber ? true : false}
            handleModal={() => handlePageNumber(1)}
            ico="chevron-right"
          />
          {rigthpages[0] ? (
            rigthpages[rigthpages.length - 1].index !== header.totalPages ? (
              <Icon
                handleModal={() => changePage(header.totalPages)}
                disabled={false}
                children={header.totalPages}
              />
            ) : null
          ) : null}
        </BoxFlexPagination>
      </BoxFlexPagination>
    )
  } else {
    return (
      <H4 margin="0" fontSize="1.4rem">
        Total: {amount}
      </H4>
    )
  }
  
}

export default TableFooter
