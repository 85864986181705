import styled from 'styled-components'
const BoxFlex = (props) => {
  const { children, ...rest } = props
  return <div style={{ display: 'flex', gap: '10px', ...rest }}>{children}</div>
}

export default BoxFlex

const BoxFlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  @media screen and (max-width: 425px) {
    flex-direction: ${(props) => props.direction};
    gap: 5px;
  }
`
export const BoxFlexPagination = ({
  children,
  justify = 'center',
  gap = '10px',
  direction = 'column',
  wrap = 'wrap',
}) => {
  return (
    <BoxFlexStyle direction={direction} justify={justify} gap={gap} wrap={wrap}>
      {children}
    </BoxFlexStyle>
  )
}
