import * as Yup from "yup";
import { Formik, Form } from "formik";
import "./style/TopicContent.css";
import { useEffect, useState } from "react";
import { useAxiosAuth } from "../../../hook";
import { Title } from "../../../components/Text";
import { FormikControl } from "../../../components/inputs";
import { ModalLocked } from "../../../components/modal";
import { VideoPlayer } from "../../../components/common";
import Load from "../../../components/Loadings/Loading/Load";
import { putAction } from "../../../services/actions/Action";
import {
  ButtonIcon,
  ButtonLink,
  DefaultButton,
} from "../../../components/buttons";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { titleSimulation } from "../../../utils/NameSimulators";
const TopicContent = (props) => {
  const { dataRequest, match, location } = props;
  const [modal, setmodal] = useState({
    state: false,
    text: "",
    notify: false,
  });
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/Theme/${dataRequest ? dataRequest.id : match.params.tid}`,
  });
  const initialValues = {
    simulation:
      response && validateArray(response.simulationList)
        ? response.simulationList[0].id + ""
        : "" || "",
  };
  const validationSchema = Yup.object({
    simulation: Yup.string().required("Campo Obligatorio"),
  });
  function handleModal(text, notify) {
    setmodal((a) => ({
      state: !a.state,
      text: text,
      notify: notify,
    }));
  }

  useEffect(() => {
    if (response && parseInt(match.params.tid) !== response.id) {
      reload();
    }
  }, [match, response]);
  async function handleViewVideo() {
    await putAction(`/Theme/${match.params.tid}/viewVideo`, {}).then(
      (response) => {
        if (response && validateStatus(response.status)) {
          reload();
        }
      }
    );
  }
  function handleSimulation(list) {
    let aux = [];
    list.forEach((element) => {
      aux.push({ key: titleSimulation[element.name], value: element.id });
    });
    return aux;
  }

  function onSubmit(values) {
    props.history.push(
      `/lessons/${response.lessonId}/topic/${match.params.tid}/simulation/${values.simulation}`
    );
  }
  return loading ? (
    <Load />
  ) : (
    <>
      <div className="TopicContent">
        <div className="TopicContent__container">
          <br />
          <ButtonIcon
            to={
              location.pathname.includes("/topics")
                ? `/lessons`
                : `/lessons/${match.params.lid}/topic/topics`
            }
            text="Volver al curso"
          />

          <div className="TopicContent__btns">
            <ButtonLink
              fontSize="2.5rem"
              borderRadius="30px"
              boxShadow="15px 10px 10px rgba(0, 0, 0, 0.16)"
              disabled={response.previousThemeId === -1}
              background="var(--purple)"
              to={`/lessons/${match.params.lid}/topic/${response.previousThemeId}`}
            >
              <i className="fas fa-chevron-left"></i> Clase anterior
            </ButtonLink>
            {response.nextThemeStatus !== null && (
              <ButtonLink
                onClick={
                  response.nextThemeStatus === "pending"
                    ? () =>
                        handleModal(
                          "Si quieres seguir con esta aventura debes adquirir el curso completo",
                          true
                        )
                    : response.nextThemeStatus === "locked"
                    ? () =>
                        handleModal(
                          "¡Upss! Debes terminar esta lección para avanzar a la siguiente",
                          false
                        )
                    : () => {}
                }
                fontSize="2.5rem"
                borderRadius="30px"
                boxShadow="15px 10px 10px rgba(0, 0, 0, 0.16)"
                disabled={response.nextThemeId === -1}
                background="var(--purple)"
                to={
                  response.nextThemeStatus === "pending" ||
                  response.nextThemeStatus === "locked"
                    ? "#"
                    : `/lessons/${match.params.lid}/topic/${response.nextThemeId}`
                }
              >
                Clase siguiente <i className="fas fa-chevron-right"></i>
              </ButtonLink>
            )}
          </div>
          <Title
            margin="0"
            color="var(--white)"
            fontSize="3.5rem"
            textAlign="center"
          >
            {response.name}
          </Title>
          <br />
          {validateArray(response.videoList)
            ? response.videoList.map((data) => (
                <div key={data.id} className="TopicContent__videoContainer">
                  <VideoPlayer
                    url={data.url}
                    width="100%"
                    handlePlay={handleViewVideo}
                    controls={true}
                  />
                  <div className="TopicContent__camera"></div>
                  <div className="TopicContent__btn"></div>
                </div>
              ))
            : null}
          <br />
          <br />
          {validateArray(response.simulationList) && (
            <div className="TopicContent__form">
              <img
                src="https://res.cloudinary.com/wmcgi/image/upload/v1655473164/assets/boy_adentro_dqar9h_ghr3lo.png"
                alt=""
              />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Form>
                      {response.simulationList.length > 1 ? (
                        <FormikControl
                          control="select"
                          label=""
                          name="simulation"
                          options={handleSimulation(response.simulationList)}
                          values={response.simulationList[0].id}
                        />
                      ) : null}

                      <div className="TopicContent__btnContainer">
                        <DefaultButton
                          width="100%"
                          type="submit"
                          padding="10px 30px 10px 10px"
                          background="var(--purple)"
                          borderRadius="50px"
                        >
                          <div className="TopicContent__TextBtn">
                            <i className="fas fa-play-circle"></i>
                            <span>
                              PRACTICAR CON EL <br /> SIMULADOR
                            </span>
                          </div>
                        </DefaultButton>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <img
                src="https://res.cloudinary.com/wmcgi/image/upload/v1655473172/assets/girl_adentro_cwhxkt_r6zq4u.png"
                alt=""
              />
            </div>
          )}
        </div>
      </div>
      <ModalLocked modal={modal} handleModal={handleModal} />
    </>
  );
};
export default TopicContent;
