const H4 = (props) => {
  const {
    children,
    textAlign = 'start',
    fontWeight = '500',
    margin = '0 0 10px',
    fontSize = '1.6rem',
    background = 'none',
    color = 'black',
    border = '',
    innerHTML = false,
    cursor,
    ...rest
  } = props
  const StyleH4 = {
    width: border ? 'fit-content' : '100%',
    fontSize: fontSize,
    margin: margin,
    fontWeight: fontWeight,
    textAlign: textAlign,
    color: color,
    border: border,
    padding: border ? '5px' : '0',
    borderRadius: border ? '5px' : 'auto',
    background: background,
    cursor:cursor
  }
  return innerHTML ? (
    <h4
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      className="H4"
      style={StyleH4}
      {...rest}
    ></h4>
  ) : (
    <h4 className="H4" style={StyleH4} {...rest}>
      {children}
    </h4>
  )
}

export default H4
