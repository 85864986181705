import { LessoContainer } from '../../containers/lessons'
import useActionParam from '../../hook/useActionParam'
import { getAction } from '../../services/actions/LessonAction'
import { validateArray, validateStatus } from '../../utils/Validation'
import { subtractDates } from '../../utils/ConvertDate'
import { Title, H3, Section } from '../../components/Text'
import { ButtonIcon } from '../../components/buttons'
import { ModalLocked } from '../../components/modal'
import { VideoPlayer } from '../../components/common'
import { putAction } from '../../services/actions/Action'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'
import './Lessons.css'

const Lessons = (props) => {
  const {
    changeModal,
    sesionUser: { userDataTest },
  } = props
  const remaining =
    userDataTest?.status === 'completed'
      ? 1
      : subtractDates(userDataTest.finishTestDate, userDataTest.registerDate)
  const [modal, setmodal] = useState({
    state: false,
    text: '',
    notify: false,
    index: null,
  })
  useEffect(() => {
    if (remaining < 0) {
      changeModal(
        true,
        <>
          <Title
            margin="0"
            fontSize="3rem"
            fontWeight="700"
            textAlign="center"
            color="var(--purple)"
          >
            Finalizó su tiempo de prueba
          </Title>
          <H3 textAlign="center" fontSize="2rem" color="gray">
            Si quieres seguir con esta aventura debes adquirir el curso
            completo.
          </H3>
        </>,
      )
    }
  }, [remaining])
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: '/lesson/all',
  })
  function handleModal(text, notify, index) {
    setmodal((a) => ({
      state: !a.state,
      text: text,
      notify: notify,
      index: index,
    }))
  }
  async function onSubmit() {
    try {
      await putAction('/Lesson/viewtutorial', {}).then((res) => {
        if (validateStatus(res.status)) {
          reload()
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  function handleCustomModal() {
    onSubmit()
    changeModal(
      true,
      <div className="SimulationMenu__videoLoad">
        <Title
          margin="10px 0"
          fontSize="4rem"
          fontWeight="900"
          textAlign="center"
          color="var(--green)"
        >
          Tutorial del Curso Online
        </Title>
        {/* <VideoPlayer url="https://vimeo.com/700942386" controls={true} /> */}
        <div  className="TopicContent__videoContainer">
                  <VideoPlayer
                    url= "https://vimeo.com/700942386"
                    width="100%"
                    controls={true}
                  />
                  <div className="TopicContent__camera"></div>
                  <div className="TopicContent__btn"></div>
        </div>
      </div>,
      null,
    )
  }
  function handlePush() {
    props.history.push('/mobile-app')
  }
  return (
    <>
      <div className="Lessons">
        <br />
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <ButtonIcon icon="home" />
          {/* <Title
            margin="0"
            color="#458E7F"
            textAlign="center"
            fontSize="3.5rem"
            fontWeight="800"
          >
            CONTENIDO
          </Title> */}
        </div>
        <br />
        <div className="Lessons__main">
          <div className="Lessons__notebook">
            <Notebook onClick1={handleCustomModal} onClick2={handlePush} />
          </div>
          <div className="Lessons__Cards">
            <LessoContainer
              key={0}
              right={true}
              textBtn="Vamos al Tutorial"
              background="#82b5ae"
              dataRequest={{
                name: 'tutorial',
                status: 'pending',
                color: 'green',
                description:
                  'En este video verás como funciona la plataforma y una guía básica de cómo interactuar con el curso. Es importante ver el video para entender mejor el funcionamiento del curso',
                imageUrl:
                  'https://res.cloudinary.com/wmcgi/image/upload/v1655473161/assets/WhatsApp_Image_2022-01-24_at_09.49.23_qtvc6o_jxdkbk.jpg',
              }}
              handleModal={handleCustomModal}
            />
            <LessoContainer
              key={0}
              right={true}
              textBtn="Vamos al videojuego"
              background="#d3875b"
              dataRequest={{
                name: 'VIDEOJUEGO',
                status: 'pending',
                color: 'green',
                description:
                  'Al mismo tiempo de practicar con los simuladores, el videojuego te ayudará a mejorar tus habilidades de manera divertida. Además de conocer lugares importantes de La Paz, la ciudad maravilla.',
                imageUrl:
                  'https://res.cloudinary.com/wmcgi/image/upload/v1655473162/assets/WhatsApp_Image_2022-01-24_at_11.53.19_pwtgwi_fbon0c.jpg',
              }}
              handleModal={handlePush}
            />
          </div>
          <br />
          <br />
          <Title
            margin="20px 0"
            color="#458E7F"
            textAlign="center"
            fontSize="3.5rem"
            fontWeight="800"
          >
            LECCIONES
          </Title>
          {validateStatus(statusRequest) ? (
            validateArray(dataRequest) ? (
              dataRequest
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((data, index) => (
                  <LessoContainer
                    key={data.lesssonId}
                    right={index % 2 === 0 ? true : false}
                    index={remaining < 0 ? 1 : index}
                    dataRequest={{
                      ...data,
                      status: remaining < 0 ? 'locked' : data.status,
                    }}
                    handleModal={handleModal}
                  />
                ))
            ) : (
              <div className="Lessons__Topics">
                No hay Lecciones registradas
              </div>
            )
          ) : null}
        </div>
      </div>
      <ModalLocked
        modal={modal}
        handleModal={handleModal}
        viewVideo={handleCustomModal}
      />
    </>
  )
}
const Notebook = (props) => {
  const { onClick1, onClick2 } = props
  return (
    <div className="Notebook">
      <div
        className="Notebook__page"
        style={{
          boxShadow: '10px 0 0 0 #448d7fcc',
          background: '#82b5ae',
        }}
      >
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Title
            margin="15px 0"
            color="white"
            textAlign="center"
            fontSize="3rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            tutorial del curso online
          </Title>
          <center>
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473161/assets/WhatsApp_Image_2022-01-24_at_09.49.23_qtvc6o_jxdkbk.jpg"
              alt="Portada del tema"
            />
          </center>
          <br />
          <Section
            width="100%"
            fontWeight="400"
            color="white"
            fontSize="2.0rem"
          >
            {/* En este video verás como funciona la plataforma y una guía básica de
            cómo interactuar con el curso. Es importante ver el video para
            entender mejor el funcionamiento del curso. */}
            En este tutorial te mostramos una guía básica y el contenido del curso online Wonder Math.
          </Section>
          <Link
            to="#"
            onClick={onClick1}
            style={{
              float: 'right',
            }}
            className="LessoContainer__button"
          >
            Ir al tutorial
          </Link>
        </div>
      </div>
      <div className="Notebook__ringed">
        <Ringed />
        <Ringed />
        <Ringed />
        <Ringed />
        <Ringed />
      </div>
      <div
        className="Notebook__page"
        style={{
          boxShadow: '-10px 0 0 0 #448d7fcc',
          background: '#d3875b',
        }}
      >
        <div
          style={{
            marginLeft: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Title
            margin="15px 0"
            color="white"
            textAlign="center"
            fontSize="3rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            VideoJuego Educativo
          </Title>
          <center>
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473162/assets/WhatsApp_Image_2022-01-24_at_11.53.19_pwtgwi_fbon0c.jpg"
              alt="Portada del videojuego"
            />
          </center>
          <br />
          <Section
            width="100%"
            fontWeight="400"
            color="white"
            fontSize="1.8rem"
          >
            {/* Al mismo tiempo de practicar con los simuladores, el videojuego te
            ayudará a mejorar tus habilidades de manera divertida. Además de
            conocer lugares importantes de La Paz, la ciudad maravilla. */}
            Descarga nuestra app en la versión de Android o PC, para que puedas practicar de una manera divertida y prepararte para el Torneo de Cálculo Mental. La ambientación del juego está basada en lugares icónicos de la Ciudad Maravilla de La Paz.
          
          
          </Section>
          <Link
            style={{
              float: 'right',
            }}
            to="#"
            onClick={onClick2}
            className="LessoContainer__button"
          >
            Ir a descargar
          </Link>
        </div>
      </div>
    </div>
  )
}
const Ringed = () => {
  return (
    <div className="Ringeds">
      <div className="Ringeds__squaresL"></div>
      <div className="Ringeds__lines">
        <div className="Ringeds__line"></div>
        <div className="Ringeds__line"></div>
      </div>
      <div className="Ringeds__squaresR"></div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal })(Lessons)
