import { useState, useEffect } from 'react'
import axios from 'axios'
import config from '../config.json'

axios.defaults.baseURL = config.requestURL

const instancia = axios.create()
instancia.CancelToken = axios.CancelToken
instancia.isCancel = axios.isCancel

const useAxios = (axiosParams) => {
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchData = async () => {
    try {
      const result = axiosParams?.url
        ? await instancia.request(axiosParams)
        : null
      setResponse(result.data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }
  function reload() {
    fetchData()
  }

  useEffect(() => {
    const ac = new AbortController()
    fetchData()
    return () => ac.abort()
  }, [])

  return { response, error, loading, reload }
}
export default useAxios
