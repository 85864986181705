import axios from "axios";
import HttpClient from "../HttpClient";

const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const getAction = (url) => {
        return new Promise((resolve, eject) => {
            HttpClient.get(url)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                console.log(error.response);
                resolve(error.response);
              });
          });
};