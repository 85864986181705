import { Link } from 'react-router-dom/cjs/react-router-dom'
import { H2, H3 } from '../../components/Text'
import { ToolTip } from '../../components/boxes'
import { useAxiosAuth } from '../../hook'
import { capitalizeFirstLetter, validateArray } from '../../utils/Validation'
import { useState } from 'react'
const SelectChild = (props) => {
  const { data, setdata, handleView, setShowRegister, setview } = props
  const [child, setChild] = useState({})
  function handleSignIn(children) {
    setdata((a) => ({
      ...a,
      child: children,
    }))
    // handleView(2, children)
    setChild(children)
    setShowRegister(false)
  }
  function handleReset(children) {
    setdata((a) => ({
      ...a,
      child: children,
    }))
    setShowRegister(true)
  }
  const { response } = useAxiosAuth({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/listChild`,
  })

  // console.log(response)
  // function handleDisabled() {
  //   let aux = false
  //   if (data) {
  //     for (let key in data) {
  //       if (data[key] === '') {
  //         aux = true
  //       }
  //     }
  //   } else {
  //     aux = true
  //   }
  //   return aux
  // }

  return (
    response?.filter(a => a.statusPayment === 'in_progress' || a.statusPayment === 'empty').length > 0 &&
    <div style={{ maxWidth: '600px', margin: '0 auto', marginTop:'20px' }} className="RegisterChildren__form">
      <div className="RegisterChildren__circle circle1"></div>
      <div className="RegisterChildren__circle circle2"></div>
      <div className="RegisterChildren__circle circle3"></div>
      <div className="RegisterChildren__circle circle4"></div>
      <div className="RegisterChildren__textContainer">
        <H2 color="var(--white)" textAlign="center">
          SELECCIONAR UN NIÑO EXISTENTE
        </H2>
      </div>
      <br />
      {
        validateArray(response) && response.filter(a => a.statusPayment === 'in_progress' || a.statusPayment === 'empty').length > 0 ? response.filter(a => a.statusPayment === 'in_progress' || a.statusPayment === 'empty').map((item) => (
          <CardChild
            {...props}
            handleSignIn={handleSignIn}
            handleReset={() => {
              handleReset()
            }}
            key={item.id}
            children={item}
          />
        ))
          : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <button
          type="button"
          onClick={() => setview(0)}
          className="UserData__btn"
          style={{ background: 'white', color: '#6a3081' }}
        >
          Volver al paso anterior
        </button>
        <button
          type="button"
          onClick={() => handleView(2, child)}
          className="UserData__btn"
          disabled={data?.Id ? false : true}
        >
          Siguiente paso
        </button>
      </div>
    </div>
  )
}

const CardChild = ({
  children,
  data,
  setdata,
  handleSignIn,
  handleReset,
  changeModal,
  openSnackbar,
}) => {
  function handleSelect() {
    handleSignIn({
      Id: children.id,
      Character: children.character,
      Name: children.firstName,
      LastName: children.lastname,
      BirthDate: children.birthDate ?? new Date(),
      Username: children.username,
      password: '',
      password_confirmation: '',
    })
  }
  function handleResetData() {
    let resetData = {
      Id: null,
      Character: '',
      Name: '',
      LastName: '',
      BirthDate: '',
      Username: '',
      password: '',
      password_confirmation: '',
    }
    handleReset(resetData)
    window.localStorage.setItem('ChildDate', JSON.stringify(resetData))
  }
  return (
    <div className="CardChildren">
      <div className="CardChildren__text">
        <img
          src={
            children.character === 'boy'
              ? 'https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/boy_feliz_jp5bbf_vnjy7y.png'
              : 'https://res.cloudinary.com/wmcgi/image/upload/v1655473177/assets/girl_feliz_tnkmki_dmd5lw.png'
          }
          alt=""
        />
        <H3
          textAlign="center"
          color={"var(--green)"}
          fontSize="2.5rem"
          width="fit-content"
        >
          {`${capitalizeFirstLetter(children.firstName ?? '')} ${capitalizeFirstLetter(children.lastName ?? '')}`}
        </H3>
      </div>
      <div className="CardChildren__option">
        <ToolTip content="Seleccionar niñ@">
          <Link
            onClick={() => handleSelect(children)}
            style={{ background: data?.Id === children.id ? 'var(--green)' : 'gray' }}
            to="#"
          >
            {data?.Id === children.id ? <i className='fa fa-check'/> : 'Seleccionar'}
          </Link>
        </ToolTip>
        {
          data?.Id === children.id &&
          <ToolTip content="Desmarcar">
            <Link
              onClick={() => handleResetData(children)}
              style={{ background: 'silver', color: 'white' }}
              to="#"
            >
              {/* <i className='fa fa-check' /> */}
              Quitar
            </Link>
          </ToolTip>
        }
      </div>
    </div>
  )
}

export default SelectChild
