import React from 'react'
import './Statistics.css'
import { H2, H3 } from '../../components/Text'
const Statistics = () => {
  return (
    <div className="Statistics">
      <div className="Statistics__content">
        <H2 margin="20px 0">Gráfico de ingresos</H2>
        <center>
          <img
            src="https://res.cloudinary.com/wmcgi/image/upload/v1661523019/assets/Mi_proyecto-1_1_fxpix5.png"
            alt=""
          />
        </center>
        <br />
        {/* <div className="Statistics__Clist">
          <div className="Statistics__Cinput">
            <H3>Vetas</H3>
            <H3>149</H3>
          </div>
          <div className="Statistics__Cinput">
            <H3>Click</H3>
            <H3>61579</H3>
          </div>
          <div className="Statistics__Cinput">
            <H3>Leads</H3>
            <H3>3922</H3>
          </div>
        </div> */}
      </div>
      <div className="Statistics__inf">
        <H2 margin="20px 0">Desempeño</H2>
        <div className="Statistics__Clist">
          <div className="Statistics__Cinput">
            <H3>Ventas</H3>
            <H3>514</H3>
          </div>
          <div className="Statistics__Cinput">
            <H3>Click</H3>
            <H3>61579</H3>
          </div>
          <div className="Statistics__Cinput">
            <H3>Inicios de sesión</H3>
            <H3>3922</H3>
          </div>
        </div>
        {/* <center>
          <div className="Statistics__Iavatar"></div>
        </center> */}
      </div>
    </div>
  )
}

export default Statistics
