import { H2, H3 } from '../../../../components/Text'
import { DefaultButton } from '../../../../components/buttons'
import Load from '../../../../components/Loadings/Loading/Load'
import { useAxiosAuth } from '../../../../hook'
import { changeModal } from '../../../../store/actions'
import { convertDate } from '../../../../utils/ConvertDate'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { titleSimulation } from '../../../../utils/NameSimulators'
import { optionLevel } from '../../../../utils/Constant'
import './SimulationResults.css'
import useWindowDimensions from '../../../../hook/useWindowsSize'

import TableFooter from '../../../Table/TableFooter'
import TableMain from '../../../Table/TableMain'

const listAvatar = [
  'https://res.cloudinary.com/wmcgi/image/upload/v1655473168/assets/bruno_llora_a7qcew_heatbx.png',
  'https://res.cloudinary.com/wmcgi/image/upload/v1655473170/assets/bruno_trsite_pj9rp2_meams9.png',
  'https://res.cloudinary.com/wmcgi/image/upload/v1655473170/assets/bruno_trsite_pj9rp2_meams9.png',
  'https://res.cloudinary.com/wmcgi/image/upload/v1655473168/assets/bruno_33_zlllgg_qir3pw.png',
  'https://res.cloudinary.com/wmcgi/image/upload/v1655473169/assets/bruno_feliz_pn0ued_pwqmdi.png',
]
const SimulationResults = (props) => {
  const [simulationName, setSimulationName] = useState('')
  const {
    match: { params },
    changeModal,
    sesionUser: { user },
  } = props
  const { response, loading } = useAxiosAuth({
    method: 'POST',
    url: `/Simulation/${params.mid}/MechanicUserId`,
  })

  function handleRight(number) {
    let index = 0
    if (number > 30) {
      index = 4
    } else if (number > 20) {
      index = 3
    } else if (number > 10) {
      index = 2
    } else {
      index = 1
    }
    return index
  }
  // useEffect(() => {
  //   if (!loading && response) {
  //     changeModal(
  //       true,
  //       <LinkVideoGame {...props} result={response.right > response.wrong} />,
  //       null,
  //     )
  //     // if (response.right > response.wrong) {
  //     // }
  //   }
  // }, [response, loading])

  function handleRecord() {
    setSimulationName(titleSimulation[response.simulationName])
    changeModal(
      true,
      <div className="ModalDefault__result">
        <H2
          textAlign="center"
          fontSize="2.5rem"
          fontWeight="600"
          color="var(--purple)"
          textTransform="uppercase"
        >
          HISTORIAL DE {user.firstName}
        </H2>
        <br />
        <Statistics
          simulationName={simulationName}
          {...props}
          mid={params.mid}
        />
      </div>,
      null,
    )
  }

  return loading ? (
    <Load />
  ) : (
    <div className="SimulationResults">
      <div className="SimulationResults__container">
        <div className="SimulationResults__cardContent">
          <img
            className="Bruno"
            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473165/assets/boy_afuera_i1gdyn_zd9bmw.png"
            alt=""
          />
          <div className="SimulationResults__card">
            <H2
              id="name"
              textAlign="center"
              fontWeight="700"
              fontSize="3rem"
              color="var(--black)"
              textTransform="uppercase"
              border="1px solid white"
              textShadow="2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff"
            >
              RESULTADOS DE {user.firstName ? user.firstName : user.username}
            </H2>
            <br />
            <H2
              textAlign="center"
              fontWeight="700"
              fontSize="2.5rem"
              color="var(--purple)"
              textTransform="uppercase"
              textShadow="2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff"
            >
              NIVEL : {optionLevel[response.level]}
            </H2>
            <div className="SimulationResults__textContent">
              <H2
                textAlign="center"
                fontWeight="700"
                fontSize="2.5rem"
                color="#51C0B1"
              >
                INTENTOS : {response.completed}
              </H2>
            </div>
            <br />
            <div className="SimulationResults__textContent">
              <H2
                textAlign="center"
                fontWeight="700"
                fontSize="2.5rem"
                color="var(--purple)"
              >
                ACIERTOS : {response.right}
              </H2>
            </div>
            <br />
            <div className="SimulationResults__textContent">
              <H2
                textAlign="center"
                fontWeight="700"
                fontSize="2.5rem"
                color="#F0593A"
              >
                FALLOS : {response.wrong}
              </H2>
            </div>
          </div>
          <img
            className="Sofia"
            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/girl_afuera_pkzttp_ml0g8u.png"
            alt=""
          />
        </div>
        <DefaultButton
          onClick={handleRecord}
          width="fit-content"
          background="var(--purple)"
          borderRadius="30px"
          padding="20px 50px"
          fontSize="2rem"
        >
          Ver historial
        </DefaultButton>
        <div className="SimulationResults__btns">
          <Link
            to={`/lessons/${params.lid}/topic/${params.tid}`}
            className="SimulationResults__button"
          >
            Ir a la clase
          </Link>
          <Link
            to={`/lessons/${params.lid}/topic/${params.tid}/simulation/${params.sid}`}
            className="SimulationResults__button"
          >
            Jugar de nuevo
          </Link>
        </div>
      </div>
    </div>
  )
}

const Statistics = (props, { simulationName }) => {
  const [url, setUrl] = useState({
    PageNumber: 1,
    OrderBy: '',
  })
  const { response, loading, headersRequest, reload } = useAxiosAuth({
    method: 'GET',
    url: `Score?UserId=${window.localStorage.getItem('id')}&PageNumber=${
      url.PageNumber
    }&MechanicUserId=${props.match.params.mid}`,
  })
  const header = [
    { key: 'playedDate', name: 'Fecha', type: 'date' },
    { key: 'playedDate', name: 'Hora', type: 'time' },
    { key: 'level', name: 'Nivel', type: 'levelOption' },
    { key: 'failed', name: 'Errores' },
    { key: 'right', name: 'Aciertos' },
    { key: 'total', name: 'Total' },
  ]
  useEffect(() => {
    if(!loading){
      reload();
    }
  }, [url])
  
  if (loading) {
    return <Load />
  } else {
    return (
      <div className="Table__simulation_container">
        <H3
          color="var(--white)"
          fontSize="2rem"
          textAlign="center"
          margin="1rem 0"
        >
          Del {convertDate(response.finishDate, 'LL')} al{' '}
          {convertDate(response.initDate, 'LL')}
        </H3>

        <TableMain header={header} main={response.statisticsList} />

        <TableFooter
          seturl={setUrl}
          url={url}
          reload={reload}
          pageInfo={headersRequest}
          amount={response.statisticsList.length}
        />
      </div>
    )
  }
}
function Drilling() {
  return (
    <div>
      <div className="Drilling__rectangle"></div>
      <div className="Drilling__circle"></div>
    </div>
  )
}
const LinkVideoGame = (props) => {
  const {
    result,
    match: { params },
  } = props
  function handleRecord() {
    props.changeModal(false, '')
  }
  return (
    <div className="ModalDefault__result">
      <img
        style={{ height: '150px' }}
        src={listAvatar[result ? 4 : 3]}
        alt="avatar"
      />
      <H2
        textAlign="center"
        fontSize="2.5rem"
        fontWeight="600"
        color="var(--purple)"
        margin="10px 0"
      >
        {result
          ? '¡FELICIDADES! LOGRASTE SUPERAR ESTE NIVEL'
          : '¡LO SENTIMOS! AUN NO SUPERASTE ESTE NIVEL'}
      </H2>

      <H2
        textAlign="center"
        fontSize="2.5rem"
        fontWeight="500"
        color="var(--purple)"
        margin="5px 0 10px 0"
      >
        {result
          ? 'Gracias a tu esfuerzo puedes practicar con el videojuego'
          : 'Sigue practicando'}
      </H2>
      <div style={{ display: 'flex', gap: '10px' }}>
        <DefaultButton
          border="1px solid #aa7cbc"
          onClick={handleRecord}
          width="100%"
          background="var(--white)"
          color="#aa7cbc"
        >
          Cerrar
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            handleRecord()
            props.history.push(
              result
                ? '/mobile-app'
                : `/lessons/${params.lid}/topic/${params.tid}/simulation/${params.sid}`,
            )
          }}
          width="100%"
          background="#aa7cbc"
        >
          {result ? 'Practicar con el videojuego' : 'Volver a intentar'}
        </DefaultButton>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal })(SimulationResults)
