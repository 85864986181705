import { Link } from 'react-router-dom'
const ButtonLink = (props) => {
  const {
    children,
    to,
    background = '#D17742',
    fontSize = '1.6rem',
    color = '#FFF',
    padding = '10px 20px',
    borderRadius = '10px',
    disabled = false,
    onClick = null,
    ...rest
  } = props
  return (
    <Link
      style={{
        backgroundColor: disabled ? 'gray' : background,
        opacity: disabled ? '0.5' : '1',
        fontSize: fontSize,
        color: color,
        padding: padding,
        textDecoration: 'none',
        borderRadius: borderRadius,
        ...rest,
      }}
      onClick={onClick}
      className="ButtonLink"
      to={disabled ? '#' : to}
    >
      {children}
    </Link>
  )
}

export default ButtonLink
