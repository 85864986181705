const H3 = (props) => {
  const {
    children,
    textAlign = 'start',
    fontWeight = '500',
    margin = '0 0 0',
    fontSize = '2.6rem',
    color = 'var(--black)',
    innerHTML = false,
    padding,
    width = '100%',
    uppercase = false,
    ...rest
  } = props

  const textStyle = {
    width: width,
    color: color,
    fontSize: fontSize,
    margin: margin,
    fontWeight: fontWeight,
    textAlign: textAlign,
    padding: padding,
    textTransform: uppercase ? 'uppercase' : 'none',
  }

  return innerHTML ? (
    <h3
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      className="H3"
      style={textStyle}
      {...rest}
    ></h3>
  ) : (
    <h3 className="H3" style={textStyle} {...rest}>
      {children}
    </h3>
  )
}

export default H3
