import React, { useState } from 'react'
import { H2 } from '../Text'
import { DefaultButton } from '../buttons'
import { useEffect, Fragment } from 'react'
const DivisionTraditional = (props) => {
  const { optionList, aciertos, fallos, indexGame, setindexGame } = props

  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return <OptionGame dataGame={optionList[indexGame.index]} {...props} />
}
const OptionGame = (props) => {
  const { dataGame, fallos, aciertos, handleSuccess, handleWrong } = props
  const [answers, setanswers] = useState({
    answers: [...new Array(dataGame.random[0].length).fill('')],
    rightAnswer: '',
  })
  function handleAnswer(e) {
    const { name, value } = e.target
    setanswers((a) => ({
      ...a,
      [name]: value,
    }))
  }
  function handleAnswers(e, index) {
    const { value } = e.target
    let aux = answers.answers
    aux[index] = value
    setanswers((a) => ({
      ...a,
      answers: [...aux],
    }))
  }
  useEffect(() => {
    setanswers({
      rightAnswer: '',
      answers: new Array(dataGame.random[0].length).fill(''),
    })
  }, [fallos, aciertos])
  function handleTotal() {
    const resp =
      answers.answers[answers.answers.length - 1] + ',' + answers.rightAnswer
    if (dataGame.rightAnswer === resp) {
      handleSuccess(dataGame, resp)
    } else {
      handleWrong(dataGame, resp)
    }
  }
  console.log(dataGame.answers)
  const listRandom = dataGame.random[0].split('')
  return (
    <div className="TableSum">
      <br />
      <div className="DivisionTraditional__main">
        <div className="DivisionTraditional__dividend">
          <div className="DivisionTraditional__rest">
            {listRandom.map((character, key) => {
              return (
                <div key={key}>
                  <H2
                    key={key}
                    textAlign="right"
                    fontWeight="900"
                    color="#8c7197"
                    fontSize="4rem"
                    margin="1px 0 3px"
                  >
                    {character}
                  </H2>
                </div>
              )
            })}

            {dataGame.answers[dataGame.answers.length - 1] !== 'NaN'
              ? listRandom.map((_, key) => {
                  return (
                    <Fragment key={key}>
                      <div
                        className="OptionGame__input"
                        style={{
                          gridRow: `${2 + key}`,
                          gridColumn: `${key + 1}`,
                        }}
                      >
                        <input
                          style={{ width: '25px' }}
                          onChange={(e) => handleAnswers(e, key)}
                          type="text"
                          name={`answers[${key}]`}
                          value={answers.answers[key]}
                          autoComplete="off"
                        />
                      </div>
                      <div
                        style={{
                          gridRow: `${2 + key}`,
                          gridColumn: `${key > 1 ? 'auto' : key + 2}`,
                        }}
                      >
                        <H2
                          key={key}
                          textAlign="center"
                          fontWeight="900"
                          color="#8c7197"
                          fontSize="4rem"
                          margin="0"
                        >
                          {listRandom[key + 1]}
                        </H2>
                      </div>
                      <div className=""></div>
                    </Fragment>
                  )
                })
              : listRandom.map((_, key) => {
                  if (key === 0) {
                    return null
                  } else {
                    return (
                      <Fragment key={key}>
                        {dataGame.answers[dataGame.answers.length - 1 - key] ===
                        'NaN' ? (
                          <>
                            <div className=""></div>
                            <div className=""></div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                gridRow: `${2 + key}`,
                                gridColumn: `${key + 1}`,
                              }}
                              className="OptionGame__input"
                            >
                              <input
                                style={{ width: '30px' }}
                                onChange={(e) => handleAnswers(e, key)}
                                type="text"
                                name={`answers[${key}]`}
                                value={answers.answers[key]}
                                autocomplete="off"
                              />
                            </div>
                            <div
                              style={{
                                gridRow: `${2 + key}`,
                                gridColumn: `${key > 1 ? 'auto' : key + 2}`,
                              }}
                            >
                              <H2
                                textAlign="center"
                                fontWeight="900"
                                color="#8c7197"
                                fontSize="4rem"
                                margin="0"
                              >
                                {listRandom[key + 1]}
                              </H2>
                            </div>
                          </>
                        )}
                        <div className=""></div>
                      </Fragment>
                    )
                  }
                })}
          </div>
        </div>
        <div className="DivisionTraditional__divider">
          <div className="DivisionTraditional__div">
            <div className='DivisionTraditional__divdivider' >
              {dataGame.random[1]}
            </div>
          </div>
          <br />
          <div className="OptionGame__input">
            <input
              autoFocus
              onChange={handleAnswer}
              type="text"
              name="rightAnswer"
              id="rightAnswer"
              value={answers.rightAnswer}
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <DefaultButton
      style={{marginTop:"2rem"}}
        onClick={handleTotal}
        width="fit-content"
        padding="5px 20px"
        fontSize="2.5rem"
        // disabled={handleDisabled()}
      >
        Verificar respuesta
      </DefaultButton>
    </div>
  )
}
export default DivisionTraditional
