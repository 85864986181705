import { connect } from 'react-redux'
import { useAxios } from '../../hook'
import FormChildren from './FormChildren'
import { useState, useEffect } from 'react'
import { makeid } from '../../utils/Generate'
import { domainURL } from '../../config.json'
import { Title, H2, H3 } from '../../components/Text'
import { ButtonFloating } from '../../components/common'
import { validateArray, validateStatus } from '../../utils/Validation'
import { StringToJson } from '../../utils/Generate'
import { putAction, postAction } from '../../services/actions/AuthAction'
import { LineProgress, CircleProgress, InputDefault, UserData } from './Payment'
import { startSession, changeModal, openSnackbar } from '../../store/actions'
// import { Facebook, Google } from '../../components/buttons'
import { Google } from '../../components/buttons'
import SelectChild from './SelectChild'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import RegisterForMe from './RegisterForMe'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const FormPayment = (props) => {
  const {
    startSession: startSes,
    startSession,
    sesionUser,
    // changeModal,
    openSnackbar,
    match: { params },
  } = props

  const history = useHistory();

  // useEffect(() => {
  //   if (sesionUser?.user?.id ) { 
  //     history.push('/management');
  //   }
  // }, [sesionUser, history]);

  const [view, setview] = useState(0)
  const [showRegister, setShowRegister] = useState(StringToJson(window.localStorage.getItem('ChildDate'))?.Id ? false : true);
  const [showForme, setshowForme] = useState(true);
  const [data, setdata] = useState({
    user: StringToJson(window.localStorage.getItem('FatherDate'), {
      name: sesionUser.user.firstName || '',
      lastname: sesionUser.user.lastName || '',
      email: sesionUser.user.email || '',
      paisIndex: '',
      estado_lbl: '',
      phone: '',
      Amount: '350.00',
      Currency: 'BOB',
      Order_id: makeid(8),
      PriceId: 1,
    }),
    child: StringToJson(window.localStorage.getItem('ChildDate'), null),
    preregisterId: null,
  })





  const { response, loading } = useAxios({
    method: 'GET',
    url: `/paymentPlan/${params.pid}`,
    // url: '/Price/main',
  })
  useEffect(() => {
    setdata((a) => ({
      ...a,
      user: {
        ...a.user,
        PriceId: response?.id ? response.id : 1,
        Amount: response?.amount ? `${response.amount}.00` : '350.00',
        Currency: response?.currency ? response.currency : 'BOB',
      },
    }))
  }, [response, loading])


  async function handleFormUser() {
    await putAction('/users/email', {
      // FatherName: data.user.name,
      // FatherLastName: data.user.lastname,
      Email: data.user.email,
      // Password: data.user.password,
      // CountryId: data.user.paisIndex,
      // City: data.user.estado_lbl,
      // FatherPhone: data.user.phone,
      // Order_Id: data.user.Order_id,
      // Amount: data.user.Amount,
      // Currency: data.user.Currency,
      // PriceId: data.user.PriceId,
    }).then((resp) => {
      if (validateStatus(resp.status)) {
        setview(1)
        window.localStorage.setItem('FatherDate', JSON.stringify(data.user))
      } else {
        openSnackbar('El correo ya esta siendo utilizado', true, true)
      }
    })
  }
  async function handleFormChild(page, child) {
    window.localStorage.setItem('ChildDate', JSON.stringify(child))
    if (data.preregisterId) {
      setview(2)
      setTimeout(() => {
        document.getElementById('id-page').submit()
      }, 10)
    } else {
      await postAction(`/auth/preregisterOrder`, {
        FatherName: data.user.name,
        FatherLastName: data.user.lastname,
        Email: data.user.email,
        FatherPhone: data.user.phone,
        City: data.user.estado_lbl,
        // Country: data.user.,
        Order_Id: data.user.Order_id,
      }).then((response) => {
        if (validateStatus(response.status)) {
          setdata((a) => ({ ...a, preregisterId: response.data.id }))
          openSnackbar('Pedido realizado con exito', true, true)
          setview(page)
          document.getElementById('id-page').submit()
        } else {
          openSnackbar('Error al realizar el pedido', true, true)
        }
      })
    }
  }
  return (
    <div className="Payment">
      <div className="Payment__container">
        <div className="Payment__main">
          <div className="Payment__header">
            <img
              src="https://res.cloudinary.com/dsgaf5zoj/image/upload/c_scale,f_auto,h_150/v1628866633/assets/nhdv4hcv63cksskvfloy.png"
              alt=""
            />
            {!window.localStorage.getItem("id") ?
              <div className="Payment__title">
                <Title fontSize="4rem" fontWeight="900">
                  <span style={{ color: '#8c7197' }}>Regístrate </span>
                  {/* <span style={{ color: '#8c7197' }}>Comprar curso </span> */}
                  {/* <span style={{ color: '#6a3081' }}>de matemáticas</span> */}
                </Title>
              </div> : null}
          </div>
          <br />
          <div className="Payment__section">
            <div className="Payment__ContainerPrice">
              <H2
                color="#6a3081"
                fontSize="2.5rem"
                fontWeight="600"
                width="auto"
              >
                Formulario de compra
              </H2>
              <div className="Payment__price">
                {/* <span>70.00 USD</span> */}
                {/* <span>500,00 Bs.</span> */}
                {!loading && (
                  <p>
                    {data.user.Amount}{' '}
                    {data.user.Currency === 'BOB' ? 'Bs.' : data.user.Currency}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="Payment__nav">
              <LineProgress progress={view * 50} />
              <div className="Payment__circleContent">
                <CircleProgress background={view >= 0 ? '#6a3081' : '#d0c7d4'}>
                  1
                </CircleProgress>
                <CircleProgress background={view >= 1 ? '#6a3081' : '#d0c7d4'}>
                  2
                </CircleProgress>
                <CircleProgress background={view >= 2 ? '#6a3081' : '#d0c7d4'}>
                  3
                </CircleProgress>
              </div>
            </div>
            <br />
            <H2
              textAlign="center"
              color="#8c7197"
              fontSize="3rem"
              fontWeight="600"
            >
              {
                [
                  'DATOS DEL TITULAR',
                  '',
                  'REALIZAR EL PAGO',
                ][view]
              }
            </H2>
            <UserForm
              data={data}
              view={view}
              setdata={setdata}
              pid={params?.pid}
              handleView={handleFormUser}
              startSession={startSession}
              startSes={startSes}
              sesionUser={sesionUser}
              history={history}
              openSnackbar={openSnackbar}
            />
            {view === 1 && (
              <>
                {showForme &&
                  <RegisterForMe
                    {...props}
                    dataChild={data.child}
                    dataUser={data.user}

                    handleView={handleFormChild}
                    setdata={setdata}
                    setview={setview}
                    setShowRegister={setShowRegister}

                  />}

                <div>
                  <SelectChild
                    {...props}
                    data={data.child}
                    handleView={handleFormChild}
                    setdata={setdata}
                    setview={setview}
                    setShowRegister={setShowRegister}
                  />
                </div>

                {
                  showRegister &&
                  <FormChildren
                    {...props}
                    data={data.child}
                    handleView={handleFormChild}
                    setdata={setdata}
                    setview={setview}
                    setshowForme={setshowForme}
                    showForme={showForme}

                  />
                }


              </>
            )}
            {view === 2 && (
              <>
                <br />
                <iframe id="form-page" name="form-page"></iframe>
                <H2 color="#3e3c3f" fontSize="2.2rem" fontWeight="500">
                  En caso de no contar con pagos por internet, contáctate con
                  nuestro numero de WhatsApp.{' '}
                  <a
                    style={{ color: 'var(--purple)' }}
                    href="https://wa.me/59170140353?text=%C2%A1Hola%21+Quisiera+m%C3%A1s+informaci%C3%B3n+acerca+del+curso+de+matemáticas&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Haz click aquí para ponerte en contacto
                  </a>
                </H2>
                {/* <Link to={`/order-result/${data.preregisterId}/${params.pid}/direct?result=${data.user.Order_id}&order_id=SIMPLE-18712045683502180412`}> presionar acá  </Link> */}
                {/* {console.log(data)} */}
                <div className="PaymentMethods__btn">
                  <button
                    type="button"
                    onClick={() => setview(1)}
                    className="UserData__btn"
                    style={{
                      background: 'white',
                      color: '#6a3081',
                      border: '1px solid #6a3081',
                    }}
                  >
                    Volver al paso anterior
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ButtonFloating />
    </div>
  )
}

const UserForm = (props) => {
  const { data, setdata, handleView, view, openSnackbar, pid, startSes, sesionUser, history } = props
  const [sending, setSending] = useState(false)
  const { response: respCountry, loading: loadCountry } = useAxios({
    method: 'GET',
    url: `/Country`,
  })
  const { response: respCities, loading: loadCities, reload } = useAxios({
    method: 'GET',
    url:
      data?.user?.paisIndex && respCountry
        ? `/Country/${respCountry[data.user.paisIndex].code}/cities`
        : null,
  })

  useEffect(() => {
    if (data.user.paisIndex) {
      reload()
    }
  }, [data.user.paisIndex, respCountry])



  function handleOnChange(e) {
    const { name, value } = e.target
    setdata((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }))
  }
  function handleOnChange2(name, value) {
    // const { name, value } = e.target
    setdata((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }))
  }
  function handleDisabled() {
    let aux = false
    if (data?.user) {
      for (let key in data.user) {
        if (key !== 'password' && data.user[key] === '') {
          aux = true
        }
      }
    }
    return aux
  }

  const handleRegisterAndLogin = async () => {
    setSending(true)
    if (!sesionUser.authenticate) {
      await postAction('/auth/registerOrLogin', data.user)
        .then((res) => {
          if (res.status === 200 && res) {
            openSnackbar('Inicio Exitoso', true, true)
            const { institutions, user, token, rols, userDataTest } = res.data
            window.localStorage.setItem('id', user.id)
            window.localStorage.setItem('token_seguridad', token)

            startSes(user, rols || [], institutions, true, userDataTest)
            handleOnChange2('name', res.data?.user?.firstName ?? '')
            handleOnChange2('lastname', res.data?.user?.lastName ?? '')
            handleOnChange2('email', res.data?.user?.email ?? '')
            handleView(1)
          } else {
            openSnackbar(
              res.data ? res.data : 'Error al iniciar sesión',
              true,
              false,
            )
          }
        })
    }
    else {
      handleView(1)
    }
    setSending(false)
  }
  return (
    <div className="UserData" style={{ display: view === 0 ? '' : 'none' }}>
      <form
        target="form-page"
        action="https://www.livees.net/Checkout/api4"
        method="POST"
        id="id-page"
      >
        <input
          type="hidden"
          name="_"
          defaultValue="6n254pj3q89mth8c81f5u9yo9as2xbdglw46fvz7k908er5i0"
        />
        <input
          type="hidden"
          name="__"
          defaultValue="ae8jg10ayld1pb7fcf7an1tm21b5burz943992hsq6vkwo6ix"
        />
        <input
          type="hidden"
          name="postURL"
          defaultValue={`${domainURL}/order-result/${data.preregisterId}/${pid}/direct`}
        // defaultValue={`${domainURL}/order-result/${userChild}`}
        />
        <input type="hidden" name="amt2" defaultValue={data.user.Amount} />
        <input
          type="hidden"
          name="currency"
          defaultValue={data.user.Currency}
        />
        {!window.localStorage.getItem("id") ?

          <div style={{ display: "flex", justifyContent: "center", maxWidth: '300px', margin:'auto' }}>   <Google {...props} handleOnChange2={handleOnChange2} fillingFileds={true} isRegister={true} history={history} style={{ width: "80%" }} /></div> : null

        }
        <input type="hidden" name="invno" defaultValue={data.user.Order_id} />
        <InputDefault
          label="Nombres *"
          type="text"
          name="name"
          onChange={handleOnChange}
          value={data.user.name}
        />
        <InputDefault
          label="Apellidos *"
          type="text"
          name="lastname"
          onChange={handleOnChange}
          value={data.user.lastname}
        />
        <InputDefault
          label="Correo electrónico *"
          type="email"
          name="email"
          onChange={handleOnChange}
          value={data.user.email}
        />
        {
          !window.localStorage.getItem("id") &&
          <InputDefault
            label="Contraseña "
            type="password"
            name="password"
            onChange={handleOnChange}
            value={data.user.password}
            required
          />
        }
        <label htmlFor="paisIndex">País *</label>
        <select
          onChange={handleOnChange}
          value={data.user.paisIndex}
          name="paisIndex"
        >
          <option defaultValue="" value="">
            País:
          </option>
          {loadCountry
            ? null
            : validateArray(respCountry)
              ? respCountry.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))
              : null}
        </select>
        <input
          type="hidden"
          name="pais"
          defaultValue={
            respCountry?.length > 0 && data.user.paisIndex
              ? respCountry[data.user.paisIndex].code
              : ''
          }
        />
        <label htmlFor="estado_lbl">Departamento *</label>
        <select
          onChange={handleOnChange}
          value={data.user.estado_lbl}
          name="estado_lbl"
        >
          <option defaultValue="">Departamento:</option>
          {loadCities
            ? null
            : respCities && validateArray(respCities)
              ? respCities.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))
              : null}
        </select>
        <label htmlFor="phone">Teléfono o celular *</label>
        <div style={{ display: 'flex', gap: '5px' }}>
          <span
            style={{
              background: '#b3b3b3',
              padding: '0 10px',
              height: '28px',
              textAlign: 'center',
              lineHeight: '28px',
              borderRadius: '5px',
              fontSize: '1.6rem',
            }}
          >
            {respCountry?.length > 0 && data.user.paisIndex
              ? '+' + respCountry[data.user.paisIndex].phoneCode
              : null}
          </span>
          <input
            style={{ width: '100%' }}
            type="text"
            name="phone"
            onChange={handleOnChange}
            value={data.user.phone}
          />
        </div>
        <div>
          {/* <Facebook {...props} /> */}
          {/* <br /> */}
          <div className="Auth__containerHr">
            <div className="Auth__hr"></div>
            <H3
              color="gray"
              fontSize="2rem"
              margin="0 5px"
              width="min-content"
            >

            </H3>
            <div className="Auth__hr"></div>
          </div>
        </div>
        {
          sending ?
            <button
              className="UserData__btn"
              disabled={true}
            >
              Enviando...
            </button>
            :
            <button
              type="button"
              onClick={() => {
                handleRegisterAndLogin()
              }}
              className="UserData__btn"
              disabled={handleDisabled() ? true : false}
            >
              Siguiente paso
            </button>
        }
        {/* {!window.localStorage.getItem("id") ?

          <div style={{display:"flex", justifyContent:"center"}}>   <Google {...props} handleOnChange2={handleOnChange2} fillingFileds={true} isRegister={true} history ={history} style={{ width:"80%"}}/></div> : null
        
        } */}
        {/* {load ? null : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '15px',
            }}
          >
          </div>
        )} */}
      </form>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,

  }
}
export default connect(mapStateToProps, {
  startSession,
  changeModal,
  openSnackbar,
})(FormPayment)
