import { useState } from 'react'
import { H2 } from '../Text'
const RenderOptions = (props) => {
  const [color, setcolor] = useState(0)
  const ListColor = ['#ffffff', '#963333', '#458E7F']
  const {
    number,
    dataGame,
    timeGamin,
    handleSuccess,
    handleWrong,
    fontWeight = '900',
    background,
  } = props
  function numberCorrect(numberClick) {
    if (dataGame.rightAnswer === numberClick) {
      setcolor(2)
      setTimeout(function () {
        handleSuccess(dataGame, numberClick)
        // handleIndex(dataGame.rightAnswer)
        setcolor(0)
      }, 150)
    } else {
      setcolor(1)
      setTimeout(function () {
        handleWrong(dataGame, numberClick)
        // handleIndex(dataGame.rightAnswer)
        setcolor(0)
      }, 150)
    }
  }
  return (
    <div
      onClick={
        timeGamin === '00:00:00.000'
          ? () => {}
          : () => {
              numberCorrect(number)
            }
      }
      style={{
        background: `${
          timeGamin === '00:00:00.000'
            ? '#38353844'
            : color === 0
            ? background
            : ListColor[color] + 'A1'
        }`,
        // background: `${color === 0 ? background : ListColor[color] + 'A1'}`,
      }}
      className="RenderOptions"
    >
      <H2
        textAlign="center"
        fontWeight={fontWeight}
        color={ListColor[color]}
        fontSize="3.8rem"
        width="auto"
      >
        {number}
      </H2>
    </div>
  )
}
export default RenderOptions
