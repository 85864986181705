import { Link } from 'react-router-dom/cjs/react-router-dom'
import { H2, H3 } from '../../components/Text'
import { ToolTip } from '../../components/boxes'
import { useEffect, useState } from 'react'
import { useAxiosAuth } from '../../hook'

const RegisterForMe = (props) => {
  const { dataUser, dataChild, setdata, handleView, setShowRegister, setview } = props
  const [child, setChild] = useState({})
  function handleSignIn(children) {
    setdata((a) => ({
      ...a,
      child: children,
    }))
    // handleView(2, children)
    setChild(children)
    setShowRegister(false)
  }
  function handleReset(children) {
    setdata((a) => ({
      ...a,
      child: children,
    }))
    setChild(children)
    setShowRegister(true)
  }

  const { response } = useAxiosAuth({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/listChild`,
  })

  let usernameData = `${(dataUser?.email ?? '').split("@")[0]}`
  let usernameChildData = `${(dataChild?.name ?? '').toLowerCase()}.${(dataChild?.lastname ?? '').toLowerCase()}`

  useEffect(() => {
    setChild(dataChild)
  }, [])


  return (
    response?.filter(a => a.username === usernameData)?.length <= 0 &&
    <div style={{ maxWidth: '600px', margin: '0 auto' }} className="RegisterChildren__form">
      <div className="RegisterChildren__circle circle1"></div>
      <div className="RegisterChildren__circle circle2"></div>
      <div className="RegisterChildren__circle circle3"></div>
      <div className="RegisterChildren__circle circle4"></div>
      <div className="RegisterChildren__textContainer">
        <H2 color="var(--white)" textAlign="center">
          COMPRAR PARA MÍ
        </H2>
      </div>
      <br />
      <SubCard
        {...props}
        handleSignIn={handleSignIn}
        handleReset={() => {
          handleReset()
        }}
        dataUser={dataUser}
        dataChild={dataChild}
        usernameData={usernameData}
        usernameChildData={usernameChildData}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <button
          type="button"
          onClick={() => setview(0)}
          className="UserData__btn"
          style={{ background: 'white', color: '#6a3081' }}
        >
          Volver al paso anterior
        </button>
        <button
          type="button"
          onClick={() => handleView(2, child)}
          // onClick={() => console.log(dataChild)}
          className="UserData__btn"
          disabled={usernameData === child?.Username ? false : true}
        >
          Siguiente paso
        </button>
      </div>
    </div >
    // </div >
  )
}

const SubCard = ({
  dataChild,
  dataUser,
  setdata,
  usernameData,
  usernameChildData,
  handleSignIn,
  handleReset,
  changeModal,
  openSnackbar,
  child
}) => {
  function handleSelect() {
    handleSignIn({
      Id: undefined,
      Character: dataUser?.character ?? 0,
      Name: dataUser?.name,
      LastName: dataUser?.lastname,
      BirthDate: dataUser?.birthDate ?? new Date(),
      Username: `${(dataUser?.email ?? '').split("@")[0]}`,
      password: `${(dataUser?.email ?? '').split("@")[0]}`,
      password_confirmation: '',
      IsPersonal: true
    })
  }
  function handleResetData() {
    let resetData = {
      Id: null,
      Character: '',
      Name: '',
      LastName: '',
      BirthDate: '',
      Username: '',
      password: '',
      password_confirmation: '',
      IsPersonal: false
    }
    handleReset(resetData)
    window.localStorage.setItem('ChildDate', JSON.stringify(resetData))
  }
  return (
    <div className="CardChildren" style={{ justifyContent: 'center' }}>
      <div className="CardChildren__option">
        {
          usernameData !== dataChild?.Username &&
          
            <Link
              onClick={() => handleSelect()}
              style={{ background: dataChild?.Username === usernameData ? 'var(--green)' : '#02696b' }}
              to="#"
            >
              Comprar 
            </Link>
          
        }
        {
          usernameData === dataChild?.Username &&
          <Link
            style={{ background: dataChild?.Username === usernameData ? 'var(--green)' : 'silver' }}
            to="#"
          >
            <i className='fa fa-check' />
          </Link>
        }
        {
          dataChild?.Username === usernameData &&
          <ToolTip content="Desmarcar">
            <Link
              onClick={() => handleResetData()}
              style={{ background: 'silver', color: 'white' }}
              to="#"
            >
              Quitar
            </Link>
          </ToolTip>
        }
      </div>
    </div >
  )
}

export default RegisterForMe