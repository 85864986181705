import './ModalDefault.css'
const ModalDefault = (props) => {
  const { background = '#000000a3' } = props
  return (
    <div style={{ background: background }} className="ModalDefault">
      
      
      <div className="ModalDefault__container">
        {props.closeOutside && (
          <div
            // style={{ background: background }}
            className="ModalDefault__close"
            onClick={props.onClose}
          ></div>
        )}
        <div className="ModalDefault__video">
          {props.onClose?
          <button 
            className="ModalDefault__close-button"
            onClick={props.onClose}
          >
            <i className="fas fa-times"></i>
          </button>:null
          }
          {props.children}
        
        </div>

      </div>
    
    </div>
  )
}

export default ModalDefault
