export const validate = (data) => {
  if (data === undefined || data === null) {
    return '--'
  }
  return data
}
export const validateVar = (data) => {
  if (data === undefined || data === null) {
    return ''
  }
  return data
}

// export const validateImg = (img) => {
//     if (img === undefined || img === null) {
//         return urlAvatar;
//     }
//     return img;
// };

export const validateArray = (dataArray) => {
  // return dataArray?.length !== 0 ? true : false
  return dataArray ? (dataArray.length !== 0 ? true : false) : false
}
export const validateStatus = (state) => {
  const status = [200, 201, 202, 203, 204]
  if (status.indexOf(state) > -1) {
    return true
  }
  return false
}

// export const validateDataArray = (state, status) => {
//   // const status = [200, 201];
//     if (status.indexOf(state) > -1) {
//         return true;
//     }
//     return false;
// };

export const validateParam = (param, json) => {
  let aux = ''
  if (json) {
    if (json[param]) {
      aux = json[param]
    }
  }
  return aux
}
// export const convertStringToJson = (str) => {
//     do {
//         if (str[0] === "'" && str[str.length - 1] === "'") {
//         str = str.substring(1, str.length - 1);
//         }
//     } while (str[0] === "'" && str[str.length - 1] === "'");
//     let res = JSON.parse(str.replace(/'/g, '"'));
//     return res;
// };
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const verifyStudent = (rol) => {
  return rol.name === 'student'
}
export const verifyRol = (rol, rolName) => {
  return rol.name === rolName
}
