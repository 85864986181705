import './PlansPayment.css'
import { H3, H4 } from '../../components/Text'
import { useAxios } from '../../hook'
import { validateArray } from '../../utils/Validation'
import { DefaultButton } from '../../components/buttons'
import { Loading } from '../../components/animation'
import { withRouter } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'

const PlansPayment = (props) => {
  const { history, match } = props
  const location = useLocation(); // Obtiene la información de la ruta actual
  const isRecoveryRoute = location.pathname.startsWith('/plans-payment') || location.pathname.startsWith('/payment');
 
  const { response, loading } = useAxios({
    method: 'GET',
    url: '/paymentPlan',
  })
useEffect(() => {
  if(window.localStorage.getItem("pay")=="false"){
    history.push('/management')
  }

  
}, [])


  if (loading) {

    return <Loading />
  } else {
    
    return (

      
      <div className="PlansPayment">
        <img
          className="Bruno"
          src="https://res.cloudinary.com/wmcgi/image/upload/v1655473165/assets/boy_afuera_i1gdyn_zd9bmw.png"
          alt=""
        />
        <div className="PlansPayment__list">
          {validateArray(response) &&
            response.map((item) => (
              <Plans
                key={item.id}
                plan={item}
                match={match}
                history={history}
              />
            ))}
        </div>
        <img
          className="Sofia"
          src="https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/girl_afuera_pkzttp_ml0g8u.png"
          alt=""
        />
      </div>
    )
  }
}
const Plans = (props) => {
  const { plan, history, match } = props
  function handleRedirection() {
    window.localStorage.setItem("pay", "true") 
    history.push(
      match.params.uid
        ? `/payment/${match.params.uid}/${plan.id} `
        : `/payment/${plan.id}`,
    )
  }
  return (

    <div className="Plans">
      <div className="">
        <H3 textAlign="center" margin="0 0 5px" fontSize="45px">
          Plan{' '}
          {
            {
              Monthly: 'Mensual',
              Quarterly: 'Trimestral',
              Biannual: 'Semestral',
              Annual: 'Anual',
            }[plan.type]
          }
        </H3>
        <H4 textAlign="center"  color="var(--purple)" margin="0 0 5px" fontWeight="700" fontSize="30px"  >
          {plan.amount} {plan.currency} /{' '}
          {
            {
              Monthly: 'mensual',
              Quarterly: 'trimestral',
              Biannual: 'semestral',
              Annual: 'anual',
            }[plan.type]
          }
        </H4>
        {plan.savingPercentage ? (
          <H4 textAlign="center" color="var(--purple)" fontWeight="700" fontSize="26px" >
            Ahorras {plan.savingPercentage}% menos
          </H4>
        ) : null}
      </div>
      <center>
        <DefaultButton
          onClick={handleRedirection}
          background="var(--purple)"
          borderRadius="20px"
          className= "button_responsive"
        >
          Comprar
        </DefaultButton>
      </center>
    </div>
  )
}
export default withRouter(PlansPayment)
