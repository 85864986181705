import { ButtonLink } from '../../components/buttons'
import { useAxiosAuth } from '../../hook'
const Seller = (props) => {
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/sales`,
  })
  console.log(response, loading)
  return (
    <div>
      <ButtonLink to={`/register/${window.localStorage.getItem('id')}`}>
        Registrar usuarios
      </ButtonLink>
    </div>
  )
}

export default Seller
