import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import './style/Forms.css'
import { useState } from 'react'
import { connect } from 'react-redux'
import { startSession, openSnackbar } from '../../store/actions'
import * as Yup from 'yup'
import { postAction } from '../../services/actions/AuthAction'
import { Link } from 'react-router-dom'
import './style/Log_in.css'
import { GlobalButton } from '../buttons'
import Loading from '../animation/Loading'
import { verifyStudent } from '../../utils/Validation'
import { H4 } from '../Text'
const Log_in = (props) => {
  const { location, startSession, history, openSnackbar } = props
  const [load, setload] = useState(true)
  const [error, setError] = useState([])
  const initialValues = {
    UsernameOrEmail: '',
    password: '',
  }
  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required('Campo Obligatorio'),
    password: Yup.string()
      .required('Campo Obligatorio')
      .min(4, 'Mínimo 4 caracteres'),
  })
  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Inicio Exitoso', true, true)
      const { institutions, rols, user, token, userDataTest } = res.data
      window.localStorage.setItem('id', user.id)
      window.localStorage.setItem('token_seguridad', token)
      startSession(user, rols || [], institutions, true, userDataTest)
      // si no tiene correo entonces es estudiante (logica de antes)
      // ahora comprar con el rol si es estudiante
      if (verifyStudent(rols[0])) {
        history.push('/lessons')
      } else if (userDataTest.status === 'completed') {
        history.push('/management')
      } else {
        history.push('/welcome')
      }
    } else {
      setError([res.data])
      openSnackbar(res.data ? res.data : 'Datos incorrectos', true, false)
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/login', values).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="log_in">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                // label="Usuario o correo electrónico :"
                label="Correo electrónico o Usuario :"
                name="UsernameOrEmail"
                listError={error}
                values={formik.values.UsernameOrEmail}
                error={['wrong_usernameOrEmail']}
              />
              <FormikControl
                control="input"
                type="password"
                label="Contraseña:"
                name="password"
                values={formik.values.password}
                listError={error}
                error={['invalid_password']}
              />
              {load ? (
                <center>
                  <GlobalButton type="submit">INGRESAR</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <div className="forgotPassword">
        {/* <span>¿Olvidaste tu contraseña? </span> */}
        <Link
          to={{
            pathname: '/auth/recover',
            state: {
              from: location.state ? location.state.from : '/',
            },
          }}
        >
          {/* Recuperar contraseña */}
          ¿Olvidaste tu contraseña?
        </Link>
      </div>
    </div>
  )
}
export default connect(null, { startSession, openSnackbar })(Log_in)
