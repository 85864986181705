import './MobileApp.css'
import { H2, H3 } from '../../components/Text'
import { VideoPlayer } from '../../components/common'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'

const MobileApp = () => {
  return (
    <div className="MobileApp">
      <div className="MobileApp__main">
        <H2
          textAlign="center"
          margin="30px 10px"
          color="var(--green)"
          fontWeight="900"
        >
          {/* PRUEBA NUESTRA APLICACIÓN */}
          PRACTICA EN NUESTRO VIDEOJUEGO EDUCATIVO 
        </H2>
        
        
        
        {/* <div className="MobileApp__video">
          <VideoPlayer
            url="https://vimeo.com/701653974"
            controls={true}
            // playing={true}
          />
        </div> */}

          <div  className="TopicContent__videoContainer">
                  <VideoPlayer
                    url= "https://vimeo.com/701653974"
                    width="100%"
                    controls={true}
                  />
                  <div className="TopicContent__camera"></div>
                  <div className="TopicContent__btn"></div>
          </div>
        



        {/* <Tutorial
          title="¿Tienes problemas con el video juego?"
          subTitle="Mirá el tutorial"
          textBtn="Ir al tutorial de videojuego"
          videoModalTitle="Tutorial de videojuego"
          changeModal={changeModal}
          videoUrl="https://vimeo.com/701653974"
        /> */}
        <div className="MovileApp__Principal">
          <CardTextIcon
            url="https://capacitaglobal.org/media/assets/Window_Heroes.zip"
            // text="Descarga nuestra aplicación para escritorio"
            text="Descarga para la PC"
            icon="fas fa-desktop"
          />
          <CardTextIcon
            url="https://play.google.com/store/apps/details?id=com.TodoGamesStudios.WonderMath"
            // text="Descarga nuestra aplicación desde la play store"
            text="Descarga para el movil"
            icon="fas fa-mobile-alt"
          />
        </div>
      </div>
    </div>
  )
}

function CardTextIcon({ text, icon, url }) {
  return (
    <div className="CardTextIcon">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="LessoContainer__cardPink CardTextIcon__cardPink">
          <div className="LessoContainer__ringed">
            <Ringed />
            <Ringed />
            <Ringed />
          </div>
          <div className="LessoContainer__container">
            <i className={icon}></i>
            <H3
              margin="20px 0"
              textAlign="center"
              width="fit-content"
              fontSize="2rem"
            >
              {text}
            </H3>
          </div>
        </div>
      </a>
    </div>
  )
}
const NumberCircle = (props1) => {
  const { title } = props1
  return (
    <div className="NumberCircle">
      <H2
        textAlign="center"
        margin="20px 0 10px"
        fontSize="3rem"
        color="#E5612B"
      >
        {title}
      </H2>
    </div>
  )
}
const StepsMobileApp = (props2) => {
  const { text } = props2
  return (
    <div className="TextSteps">
      <H3
        width="auto"
        textAlign="left"
        fontSize="2rem"
        color="#E5612B"
        fontWeight="450"
      >
        {text}
      </H3>
    </div>
  )
}

function Ringed() {
  return (
    <div className="Ringed">
      <div className="Ringed__lines">
        <div className="Ringed__line"></div>
        <div className="Ringed__line"></div>
      </div>
      <div className="Ringed__square"></div>
    </div>
  )
}

export default connect(null, { changeModal })(MobileApp)
