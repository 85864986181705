import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { H3 } from '../Text'
const InputPhone = (props) => {
  const { name, uploadValues, label, labelExample } = props

  return (
    <div className="form-control">
      <label>{label}</label>
      <H3 fontSize="1.2rem" margin="5px 0 0" color="var(--gray)">
        {labelExample}
      </H3>
      <PhoneInput
        placeholder="Ingresa tu numero"
        onChange={(value) =>
          uploadValues((a) => ({
            ...a,
            [name]: value,
          }))
        }
      />
    </div>
  )
}

export default InputPhone
