import { validateArray } from '../../utils/Validation'
import { useState } from 'react'
import { H2 } from '../Text'
import { useEffect } from 'react'
import './style/TableSum.css'
const numbers = [
  ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [2, 3, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [3, 4, 5, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [4, 5, 6, 7, 8, 0, 0, 0, 0, 0, 0, 0, 0],
  [5, 6, 7, 8, 9, 10, 0, 0, 0, 0, 0, 0, 0],
  [6, 7, 8, 9, 10, 11, 12, 0, 0, 0, 0, 0, 0],
  [7, 8, 9, 10, 11, 12, 13, 14, 0, 0, 0, 0, 0],
  [8, 9, 10, 11, 12, 13, 14, 15, 16, 0, 0, 0, 0],
  [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 0, 0, 0],
  [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 0, 0],
  [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 0],
  [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
]
const TableSum = (props) => {
  const {
    gameData,
    optionList,
    aciertos,
    fallos,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  //   principal: gameData.baseNumber,
  // })
  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      {...props}
    />
  )
}
function OptionGame(props) {
  const { dataGame, principal } = props
  return (
    <div className="TableSum">
      <br />

      <H2 textAlign="center" fontWeight="900" color="#8c7197" fontSize="6rem">
        {principal} +{' '}
        {validateArray(dataGame.answers) ? dataGame.answers[0] : ''}
      </H2>

      <div className="TableSum__table">
        {numbers.map((list, i) => (
          <div key={i} className="TableSum__row">
            {list.map((number, j) => (
              <TableCell key={j} indexI={i} indexJ={j} {...props}>
                {number}
              </TableCell>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
const TableCell = (props) => {
  const {
    children,
    indexI,
    indexJ,
    dataGame,
    // handleIndex,
    handleSuccess,
    handleWrong,
  } = props

  const [color, setcolor] = useState(0)
  const ListColor = ['#ffffff', '#963333', '#458E7F']
  function handleClick(numberClick) {
    if (parseInt(dataGame.rightAnswer) === numberClick) {
      setcolor(2)
      setTimeout(function () {
        handleSuccess(dataGame, numberClick)
        // handleIndex(dataGame.rightAnswer)
        setcolor(0)
      }, 150)
    } else {
      setcolor(1)
      setTimeout(function () {
        handleWrong(dataGame, numberClick)
        // handleIndex(dataGame.rightAnswer)
        setcolor(0)
      }, 150)
    }
  }
  return (
    <div
      className={`TableCell ${
        indexI === 0 || indexJ === 0 ? 'TableCell__main' : ''
      } ${children === 0 && 'TableCell__empty'} ${
        children !== 0 && indexI > 0 && indexJ > 0 ? 'TableCell__click' : ''
      }`}
      style={{
        background: `${color === 0 ? '' : ListColor[color] + 'D0'}`,
      }}
      onClick={
        children !== 0 && indexI > 0 && indexJ > 0
          ? () => handleClick(children)
          : null
      }
    >
      <H2
        textAlign="center"
        fontWeight="900"
        color="white"
        fontSize="2.5rem"
        width="100%"
      >
        {children === 0 ? '' : children}
      </H2>
    </div>
  )
}
export default TableSum
