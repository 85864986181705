import { Link } from "react-router-dom";
import "./style/ButtonIcon.css";
const ButtonIcon = ({ to = "/", icon = "chevron-left", text = "" }) => {
  return (
    <Link to={to} className="ButtonIcon">
      <i className={`fas fa-${icon}`}></i> <span>{text}</span>
    </Link>
  );
};

export default ButtonIcon;
