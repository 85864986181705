import React from 'react'

const Section = (props) => {
  const {
    children,
    textAlign = 'start',
    fontWeight = '500',
    margin = '0 0 10px',
    fontSize = '1.8 rem',
    color = 'var(--black)',
    width = '100%',
  } = props

  return (
    <p
      className="Section"
      style={{
        width: width,
        color: color,
        fontFamily: "'Roboto', sans-serif",
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
        textAlign: textAlign,
      }}
    >
      {children}
    </p>
  )
}

export default Section
