import { Title, H2, H3, Section } from '../../components/Text'
import { makeid } from '../../utils/Generate'
import { validateArray, validateStatus } from '../../utils/Validation'
import { useAxios } from '../../hook'
import { postAction } from '../../services/actions/Action'
import './payment.css'
import { useState, useEffect } from 'react'
import { startSession, changeModal } from '../../store/actions'
import { connect } from 'react-redux'
import { domainURL } from '../../config.json'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ButtonFloating } from '../../components/common'
const Payment = (props) => {
  const {
    startSession: startSes,
    startSession,
    sesionUser,
    changeModal,
    match: { params },
  } = props
  const [view, setview] = useState(0)
  const [PayData, setPayData] = useState({
    user: {
      name: sesionUser.user.firstName || '',
      lastname: sesionUser.user.lastName || '',
      email: sesionUser.user.email || '',
      paisIndex: '',
      estado_lbl: '',
      phone: '',
      Amount: '350.00',
      Currency: 'BOB',
      Order_id: makeid(8),
      PriceId: 1,
    },
    preregisterId: null,
    payMethods: 1,
  })

  const { response, loading } = useAxios({
    method: 'GET',
    url: `/paymentPlan/${params.pid}`,
  })

  useEffect(() => {
    setPayData((a) => ({
      ...a,
      user: {
        ...a.user,
        PriceId: response?.id ? response.id : 1,
        Amount: response?.amount ? `${response.amount}.00` : '350.00',
        Currency: response?.currency ? response.currency : 'BOB',
      },
    }))
  }, [response, loading])

  return (
    <div className="Payment">
      <div className="Payment__container">
        <div className="Payment__main">
          <div className="Payment__header">
            <img
              src="https://res.cloudinary.com/dsgaf5zoj/image/upload/c_scale,f_auto,h_150/v1628866633/assets/nhdv4hcv63cksskvfloy.png"
              alt=""
            />
            <div className="Payment__title">
              <Title fontSize="4rem" fontWeight="900">
                <span style={{ color: '#8c7197' }}>Comprar curso </span>
                <span style={{ color: '#6a3081' }}>de matemáticas</span>
              </Title>
            </div>
          </div>
          <br />
          <div className="Payment__section">
            <div className="Payment__ContainerPrice">
              <H2
                color="#6a3081"
                fontSize="2.5rem"
                fontWeight="600"
                width="auto"
              >
                Formulario de compra
              </H2>
              <div className="Payment__price">
                {/* <span>70.00 USD.</span> */}
                <p>
                  {PayData.user.Amount}{' '}
                  {PayData.user.Currency === 'BOB'
                    ? 'Bs.'
                    : PayData.user.Currency}
                </p>
              </div>
            </div>
            <br />
            <div className="Payment__nav">
              <LineProgress progress={view * 51} />
              <div className="Payment__circleContent">
                <CircleProgress background={view >= 0 ? '#6a3081' : '#d0c7d4'}>
                  1
                </CircleProgress>
                <CircleProgress background={view >= 1 ? '#6a3081' : '#d0c7d4'}>
                  2
                </CircleProgress>
                <div className=""></div>
              </div>
            </div>
            <br />
            <H2
              textAlign="center"
              color="#8c7197"
              fontSize="2rem"
              fontWeight="600"
            >
              {['DATOS DEL PADRE O TITULAR', 'REALIZAR EL PAGO'][view] ||
                'REALIZAR EL PAGO'}
            </H2>
            <UserData
              {...props}
              startSession={startSession}
              startSes={startSes}
              changeModal={changeModal}
              view={view}
              setview={setview}
              PayData={PayData}
              setPayData={setPayData}
              uid={params.uid}
              pid={params.pid}
            />
            {view === 1 && (
              <>
                <br />
                <iframe id="form-page" name="form-page"></iframe>
                <H2 color="#3e3c3f" fontSize="2.2rem" fontWeight="500" margin="0 0 20px 0">
                  En caso de no contar con pagos por internet, contáctate con
                  nuestro numero de WhatsApp.{' '}
                  <a
                    style={{ color: 'var(--purple)' }}
                    href="https://wa.me/59170140353?text=%C2%A1Hola%21+Quisiera+m%C3%A1s+informaci%C3%B3n+acerca+del+curso+de+matemáticas&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Haz click aquí para ponerte en contacto
                  </a>
                </H2>
                {console.log(PayData)}
                {/* <Link to={`/order-result/${params.uid}/${params.pid.trim()}?result=${PayData.user.Order_id}&order_id=SIMPLE-17936319333001560423`}> presionar acá  </Link> */}
                <div className="PaymentMethods__btn">
                  <button
                    type="button"
                    onClick={() => setview(0)}
                    className="UserData__btn"
                    style={{
                      background: 'white',
                      color: '#6a3081',
                      border: '1px solid #6a3081',
                    }}
                  >
                    Volver al método de pago
                  </button>
                </div>
              </>
            )}
            {/* <>
                {PayData.payMethods === 1 ? (
                  <>
                    
                  </>
                ) : (
                  <div className="Payment__MInfo">
                    <Section color="#2f133a" fontSize="1.8rem" fontWeight="400">
                      Su cuenta de usuario ha sido creada, por favor verifique
                      el correo electrónico enviado con sus datos de usuario y
                      contraseña.
                    </Section>
                    <br />
                    <H2 color="#6a3081" fontSize="2.2rem" fontWeight="500">
                      Contáctate con uno de nuestros asesores de ventas
                    </H2>
                    <br />
                    <div className="Payment__cellPhones">
                      <Section
                        textAlign="center"
                        color="#2f133a"
                        fontSize="1.8rem"
                        fontWeight="400"
                      >
                        +591 67038903
                      </Section>
                      <Section
                        textAlign="center"
                        color="#2f133a"
                        fontSize="1.8rem"
                        fontWeight="400"
                      >
                        +591 62397333
                      </Section>
                      <Section
                        textAlign="center"
                        color="#2f133a"
                        fontSize="1.8rem"
                        fontWeight="400"
                      >
                        +591 77285061
                      </Section>
                    </div>
                  </div>
                </>  
                )} */}
          </div>
        </div>
        <br />
      </div>
      <ButtonFloating />
    </div>
  )
}

export const LineProgress = ({ progress }) => {
  return (
    <div
      style={{
        background: `linear-gradient(90deg, #6a3081 ${progress}%, #d0c7d4 ${progress}%)`,
      }}
      className="LineProgress"
    ></div>
  )
}

export const CircleProgress = ({
  children,
  background,
  onClick = null,
  ...rest
}) => {
  return (
    <div
      style={{ background: background, rest }}
      onClick={onClick}
      className="CircleProgress"
    >
      {children}
    </div>
  )
}
export const UserData = (props) => {
  const {
    setview,
    view,
    PayData,
    setPayData,
    startSes,
    changeModal,
    history,
    uid: userChild,
    pid,
  } = props
  const [load, setload] = useState(false)
  const { response: respCountry, loading: loadCountry } = useAxios({
    method: 'GET',
    url: `/Country`,
  })
  const { response: respCities, loading: loadCities, reload } = useAxios({
    method: 'GET',
    url: PayData.user.paisIndex
      ? `/Country/${respCountry[PayData.user.paisIndex].code}/cities`
      : null,
  })

  useEffect(() => {
    if (PayData.user.paisIndex) {
      reload()
    }
  }, [PayData.user.paisIndex])

  function handleOnChange(e) {
    const { name, value } = e.target
    setPayData((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }))
  }
  function handleDisabled() {
    let aux = false
    if (PayData?.user) {
      for (let key in PayData.user) {
        if (PayData.user[key] === '') {
          aux = true
        }
      }
    }
    return aux
  }
  async function handleOnSubmit() {
    const uid = window.localStorage.getItem('id')
    function saveData(response) {
      if (validateStatus(response.status)) {
        if (response.data !== 'already_exists') {
          const {
            institutions,
            rolsAssignedToList,
            userToReturn,
            token
          } = response.data
          if (!uid) {
            console.log(uid+"entro")
            window.localStorage.setItem('id', userToReturn.id)
            window.localStorage.setItem('token_seguridad', token)
            startSes(userToReturn, rolsAssignedToList, institutions, true)
          }
        }
        setview(1)
        document.getElementById('id-page').submit()
      } else {
        if (response.data === 'already_exists') {
          changeModal(
            true,
            'Tu cuenta ya existe',
            'Debes iniciar sesión para poder continuar con el pago',
            'Iniciar sesión',
            () => history.push('/auth/login'),
          )
        }
        setPayData((a) => ({
          ...a,
          user: {
            ...a.user,
            Order_id: makeid(8),
          },
        }))
      }
    }
    setload(true)
    const {
      name,
      lastname,
      email,
      paisIndex,
      estado_lbl,
      phone,
      Amount,
      Currency,
      Order_id,
      PriceId,
    } = PayData.user


    await postAction(`/auth/child/${userChild}/payment`, {
      Name: name,
      LastName: lastname,
      Email: email,
      Country: respCountry[paisIndex].code,
      City: estado_lbl,
      Telephone: phone,
      PaymentMethod: 'onlinePayment',
      Amount: Amount,
      Currency: Currency,
      PriceId: PriceId,
      Order_id: Order_id,
      paymentPlanId: pid,
    }).then((response) => {
      setload(false)
      saveData(response)
    })
    
  }


  return (
    <div className="UserData" style={{ display: view === 0 ? '' : 'none' }}>
      <form
        target="form-page"
        action="https://www.livees.net/Checkout/api4"
        method="POST"
        id="id-page"
      >
        <input
          type="hidden"
          name="_"
          defaultValue="6n254pj3q89mth8c81f5u9yo9as2xbdglw46fvz7k908er5i0"
        />
        <input
          type="hidden"
          name="__"
          defaultValue="ae8jg10ayld1pb7fcf7an1tm21b5burz943992hsq6vkwo6ix"
        />
        <input
          type="hidden"
          name="postURL"
          defaultValue={`${domainURL}/order-result/${userChild}/${pid}`}
        />
        <input type="hidden" name="amt2" defaultValue={PayData.user.Amount} />
        <input
          type="hidden"
          name="currency"
          defaultValue={PayData.user.Currency}
        />
        <input
          type="hidden"
          name="invno"
          defaultValue={PayData.user.Order_id}
        />
        <InputDefault
          label="Nombres *"
          type="text"
          name="name"
          onChange={handleOnChange}
          value={PayData.user.name}
        />
        <InputDefault
          label="Apellidos *"
          type="text"
          name="lastname"
          onChange={handleOnChange}
          value={PayData.user.lastname}
        />
        <InputDefault
          label="Correo electrónico *"
          type="email"
          name="email"
          onChange={handleOnChange}
          value={PayData.user.email}
        />
        <label htmlFor="paisIndex">País *</label>
        <select
          onChange={handleOnChange}
          value={PayData.user.paisIndex}
          name="paisIndex"
        >
          <option defaultValue="" value="">
            País:
          </option>
          {loadCountry
            ? null
            : validateArray(respCountry)
            ? respCountry.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))
            : null}
        </select>
        <input
          type="hidden"
          name="pais"
          defaultValue={
            respCountry?.length > 0 && PayData.user.paisIndex
              ? respCountry[PayData.user.paisIndex].code
              : ''
          }
        />
        <label htmlFor="estado_lbl">Departamento *</label>
        <select
          onChange={handleOnChange}
          value={PayData.user.estado_lbl}
          name="estado_lbl"
        >
          <option defaultValue="">Departamento:</option>
          {loadCities
            ? null
            : respCities && validateArray(respCities)
            ? respCities.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))
            : null}
        </select>
        <label htmlFor="phone">Teléfono o celular *</label>
        <div style={{ display: 'flex', gap: '5px' }}>
          <span
            style={{
              background: '#b3b3b3',
              padding: '0 10px',
              height: '28px',
              textAlign: 'center',
              lineHeight: '28px',
              borderRadius: '5px',
              fontSize: '1.6rem',
            }}
          >
            {respCountry?.length > 0 && PayData.user.paisIndex
              ? '+' + respCountry[PayData.user.paisIndex].phoneCode
              : null}
          </span>
          <input
            style={{ width: '100%' }}
            type="text"
            name="phone"
            onChange={handleOnChange}
            value={PayData.user.phone}
          />
        </div>
        {load ? null : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '15px',
            }}
          >
            <button
              type="button"
              onClick={handleOnSubmit}
              className="UserData__btn"
              disabled={handleDisabled() ? true : false}
            >
              Siguiente paso
            </button>
          </div>

        )}
      </form>
    </div>
  )
}
const PaymentCard = (props) => {
  const { src, alt, background, padding = '3px 5px 0' } = props
  return (
    <div
      className="PaymentCard"
      style={{ background: background, padding: padding }}
    >
      <img src={src} alt={alt} />
    </div>
  )
}
export const InputDefault = ({ label, name, onChange, value }) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <input type="text" name={name} onChange={onChange} value={value} />
    </>
  )
}

export const PayMethods = (props) => {
  const { PayData, setPayData, setview } = props
  return (
    <div className="PaymentMethods">
      <br />
      <div
        className={`ShoppingAside__option ${
          PayData.payMethods === 1 ? 'checked' : ''
        }`}
        onClick={() => setPayData((a) => ({ ...a, payMethods: 1 }))}
      >
        <H2 fontSize="2rem">Pagar usando tarjeta</H2>
        <br />
        <div className="PaymentMethods__list">
          <PaymentCard
            src="https://www.livees.net/Checkout/qrsimple2k21_black.png"
            alt="Logo qr simple"
            background="#000"
          />
          <PaymentCard
            src="https://www.livees.net/Checkout/visamscd.png"
            alt="Logo master card y visa"
            background={`linear-gradient(90deg, #3B62D9 50%, #232323 50%)`}
          />
          <PaymentCard
            src="https://www.livees.net/Checkout/LogoTigoMoney3.png"
            alt="Logo de tigo money"
            background="#F2B705"
            padding="4px 0 0"
          />
        </div>
      </div>
      <br />
      <div
        className={`ShoppingAside__option ${
          PayData.payMethods === 2 ? 'checked' : ''
        }`}
        onClick={() => setPayData((a) => ({ ...a, payMethods: 2 }))}
      >
        <H2 fontSize="2rem">Contáctate con nosotros</H2>
        <H3 margin="10px 5px" fontSize="1.6rem">
          En caso de no contar con pagos por internet contáctate con uno de
          nuestros asesores de ventas.
        </H3>
        <div className="Payment__cellPhones">
          <Section
            textAlign="center"
            color="#2f133a"
            fontSize="1.8rem"
            fontWeight="400"
          >
            +591 70140353
          </Section>
          {/* <Section
            textAlign="center"
            color="#2f133a"
            fontSize="1.8rem"
            fontWeight="400"
          >
            +591 62397333
          </Section>
          <Section
            textAlign="center"
            color="#2f133a"
            fontSize="1.8rem"
            fontWeight="400"
          >
            +591 77285061
          </Section> */}
        </div>
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
        }}
        className="PaymentMethods__btn"
      >
        {/* <button
          type="button"
          onClick={() => setview(0)}
          className="UserData__btn"
          style={{
            background: 'white',
            color: '#6a3081',
            border: '1px solid #6a3081',
          }}
        >
          Volver al paso anterior
        </button> */}
        <button onClick={() => setview(1)}>Siguiente paso</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { startSession, changeModal })(Payment)
