import './style/GlobalButton.css'

const GlobalButton = (props) => {
  const {
    children,
    type,
    onClick,
    style = '',
    width = '60%',
    disabled = false,
  } = props

  return (
    <button
      type={type ? type : 'button'}
      onClick={onClick}
      style={{ background: style, width: width }}
      className={'button_style'}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default GlobalButton
