import { Title } from '../../../../components/Text'
import { titleSimulation } from '../../../../utils/NameSimulators'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useAxiosAuth } from '../../../../hook'
import { FormikControl } from '../../../../components/inputs'
import { VideoPlayer } from '../../../../components/common'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './SimulationMenu.css'
import { changeModal } from '../../../../store/actions'
import { backgroundImage } from '../../../../utils/Constant'
const SimulationMenu = (props) => {
  const {
    match: { params },
    history,
    changeModal,
  } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/Simulation/${params.sid}`,
  })
  var defaultLevel
  var pos = 0
  response?.simulations?.levels.forEach((item) => {
    if (item.default === true) defaultLevel = pos
    pos++
  })
  var defaultMechanic
  pos = 0
  response?.simulations?.mechanics.forEach((item) => {
    if (item.default === true) defaultMechanic = pos
    pos++
  })
  function handleDefault(list) {
    let index = 0
    list.forEach((item, i) => {
      if (item.default) {
        index = i
      }
    })
    return index
  }
  const initialValues = {
    level: response?.simulations?.levels
      ? response.simulations.levels[handleDefault(response.simulations.levels)]
          .name
      : '',
    baseNumber: response?.simulations?.mechanics
      ? response.simulations.mechanics[
          handleDefault(response.simulations.mechanics)
        ].baseNumber
      : '',
  }
  const onSubmit = async (values) => {
    history.push(
      `/lessons/${params.lid}/topic/${params.tid}/simulation/${params.sid}/${response.type}/${values.level}/${values.baseNumber}`,
    )
  }

  const validationSchema = Yup.object({
    level: Yup.string().required('Campo requerido'),
    baseNumber: Yup.string().required('Campo requerido'),
  })

  function generateDropdown(list, field, order) {
    let aux = []
    const optionKey = {
      2: 'Decena superior',
      3: 'Centena superior',
    }
    const optionKeyComplements = {
      1: '9',
      2: '10',
      3: '100',
    }
    const optionLevel = {
      easy: 'Fácil',
      medium: 'Medio',
      hard: 'Difícil',
      extraHard: 'Muy difícil',
    }
    const Consecutive = {
      1: 'No separados',
      2: 'Separados por una unidad',
    }
    const optionMultBy9 = {
      1: '2 digitos por 9',
      2: '2 digitos por 99',
      3: '2 digitos por 999',
      4: '3 digitos por 9',
      5: '3 digitos por 99',
      6: '3 digitos por 999',
      7: '4 digitos por 9',
      8: '4 digitos por 99',
      9: '4 digitos por 999',
    }

    list
      .sort((a, b) => a[order] - b[order])
      .forEach((item) => {
        switch (response.simulations.name) {
          case 'NumberComplements':
            if (field === 'baseNumber') {
              aux.push({
                key: optionKeyComplements[item[field]],
                value: item[field],
              })
            } else {
              aux.push({ key: optionLevel[item[field]], value: item[field] })
            }
            break
          case 'CountMultiples':
            if (field === 'baseNumber') {
              aux.push({
                key: `${item[field]} en ${item[field]}`,
                value: item[field],
              })
            } else {
              aux.push({ key: optionLevel[item[field]], value: item[field] })
            }
            break
          case 'MultiplicationBy9´s':
            if (field === 'baseNumber') {
              aux.push({
                key: optionMultBy9[item[field]],
                value: item[field],
              })
            } else {
              aux.push({ key: optionLevel[item[field]], value: item[field] })
            }
            break
          case 'AddConsecutiveNumbers':
            if (field === 'baseNumber') {
              aux.push({
                key: Consecutive[item[field]],
                value: item[field],
              })
            } else {
              aux.push({ key: optionLevel[item[field]], value: item[field] })
            }
            break
          default:
            if (
              response.simulations.name === 'SubtractByApproximation' &&
              field === 'baseNumber'
            ) {
              aux.push({ key: optionKey[item[field]], value: item[field] })
            } else {
              if (field === 'name') {
                aux.push({ key: optionLevel[item[field]], value: item[field] })
              } else {
                aux.push({ key: item[field], value: item[field] })
              }
            }
            break
        }
      })
    return aux
  }
  function labelName(item) {
    switch (item) {
      case 'SubtractAdding':
      case 'DescomposeNumber':
      case 'subtractByDecomposition':
      case 'FoldNumbers':
      case 'MultiplationByFive':
      case 'MultiplicationByEleven':
      case 'Squared':
      case 'RaiseAnySquareNumber':
      case 'MultiplicationBy9´s':
      case 'MultiplyNumberByAnotherThatEndsIn0':
      case 'DivisionByYédicoMethod':
      case 'DivisionByTraditionalMethod':
      case 'MultiplyNumberBy10':
      case 'Adding5NumbersIn3Seconds':
      case 'SquaredFive':
      case 'SumByDecomposition':
      case 'AspaSimple':
        return 'Cantidad de dígitos'
      case 'AddConsecutiveNumbers':
        return 'Consecutivos:'
      case 'SubtractByApproximation':
        return 'Aproximar a'
      case 'NumberComplements':
        return 'Complemento a'
      default:
        return 'Número'
    }
  }
  function handleModal() {
    changeModal(
      true,
      loading ? null : ( 
        // <div className="SimulationMenu__videoLoad">
        //   <Title
        //     margin="10px 0"
        //     fontSize="4rem"
        //     fontWeight="900"
        //     textAlign="center"
        //     color="var(--green)"
        //   >
        //     Tutorial del simulador
        //   </Title>
        //   <VideoPlayer
        //     url={response.simulations.urlVideoTutorial}
        //     controls={true}
        //   />
        // </div>
        <div  className="TopicContent__videoContainer">
          <Title
            margin="10px 0"
            fontSize="4rem"
            fontWeight="900"
            textAlign="center"
            color="var(--green)"
          >
            Tutorial del simulador
          </Title>
                  <VideoPlayer
                    url= {response.simulations.urlVideoTutorial}
                    width="100%"
                    controls={true}
                  />
                  <div className="TopicContent__camera"></div>
                  <div className="TopicContent__btn"></div>
        </div>

        

      ),
    )
  }

  return (
    <div
      className="SimulationMenu"
      style={{
        backgroundImage: `url(${backgroundImage[params.lid]})`,
      }}
    >
      <div className="SimulationMenu__container">
        {loading ? null : (
          <>
            <br />
            <br />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className="SimulationMenu__board">
                      <div className="SimulationMenu__main">
                        {response.simulations?.resourceUrl && (
                          <a
                            href={response.simulations.resourceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {/* Descarga nuestra guía{' '} */}
                            Descarga las Tablas de Conteo {' '}
                            <i className="fas fa-cloud-download-alt"></i>
                          </a>
                        )}
                        <Title
                          margin="10px 0"
                          fontSize="4rem"
                          fontWeight="900"
                          textAlign="center"
                          color="white"
                        >
                          {!loading && response.simulations?.labelTitle
                            ? response.simulations.labelTitle
                            : titleSimulation[response.simulations.name]}
                        </Title>
                        <div className="SimulationMenu__select">
                          {response.simulations?.levels?.length > 1 ? (
                            <FormikControl
                              control="select"
                              labelLevel
                              label={
                                response.simulations?.labelLevel
                                  ? response.simulations.labelLevel
                                  : 'Nivel de dificultad'
                              }
                              name="level"
                              options={generateDropdown(
                                response.simulations.levels,
                                'name',
                                'nroLevel',
                                'Nivel',
                              )}
                            />
                          ) : null}
                          {response.simulations?.mechanics?.length > 1 ? (
                            <FormikControl
                              control="select"
                              label={
                                response.simulations?.labelRef
                                  ? response.simulations.labelRef + ':'
                                  : labelName(response.simulations.name)
                              }
                              name="baseNumber"
                              options={generateDropdown(
                                response.simulations.mechanics,
                                'baseNumber',
                                'baseNumber',
                                labelName(response.simulations.name),
                              )}
                            />
                                                   
                          ) : null}
                          

                        </div>
                          <div className="SimulationMenu__btns">
                          <button
                        disabled={!formik.isValid}
                        width="100%"
                        type="submit"
                      >
                        {/* Ir al Simulador */}
                        Comenzar
                      </button>
                        </div>
                      </div>
                    </div>
                    <div className="SimulationMenu__btns">
                      {response?.simulations?.urlVideoTutorial ? (
                        <button
                          onClick={handleModal}
                          // width="fit-content"
                          padding="10px"
                          width="100%"
                          type="button"
                          style={{ background: 'var(--purple)' }}
                        >
                          Ver tutorial <i className="fas fa-play-circle"></i>
                        </button>
                      ) : null}

                      
                      <Link
                        className="SimulationMenu__link"
                        to={`/lessons/${params.lid}/topic/${params.tid}`}
                      >
                        Regresar
                      </Link>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </>
        )}
      </div>
    </div>
  )
}
export default connect(null, { changeModal })(SimulationMenu)
