import HttpClient from '../HttpClient'
export const postAction = (url, body) => {
  return new Promise((resolve) => {
    HttpClient.post(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
