import './style/ExternalLogin.css'
import { postAction } from '../../services/actions/AuthAction'
import FacebookLogin from 'react-facebook-login'
import { verifyStudent } from '../../utils/Validation'
const Facebook = (props) => {
  const {
    startSession,
    history,
    openSnackbar,
    text = 'Regístrese con Facebook',
  } = props
  const responseFacebook = async (response) => {
    if (!response.accessToken) {
      openSnackbar('Error al iniciar sesión', true, false)
    } else {
      const usuario = {
        RegisterOption: 'Facebook',
        AccessToken: response.accessToken,
        UserID: response.id,
        ExpiresIn: response.expiresIn,
        ReauthorizeRequiredIn: '',
        Email: response.email,
        FirstName: response.name,
        LastName: '',
        PhotoUrl: response.picture.data.url,
      }
      await postAction('/auth/registerExternalLogin', usuario).then((res) => {
        if (res?.status === 200) {
          openSnackbar('Inicio Exitoso', true, true)
          const { institutions, user, token, rols, userDataTest } = res.data
          window.localStorage.setItem('id', user.id)
          window.localStorage.setItem('token_seguridad', token)
          startSession(user, rols || [], institutions, true, userDataTest)
          if (verifyStudent(rols[0])) {
            history.push('/lessons')
          } else {
            history.push('/welcome')
          }
          // } else if (res.data.firstSession) {
          // props.history.push('/management')
        } else {
          openSnackbar(
            res.data ? res.data : 'Error al iniciar sesión',
            true,
            false,
          )
        }
      })
    }
  }
  const fbButton = (
    <span>
      <i className="fab fa-facebook-f" />
      {text}
    </span>
  )
  return (
    <>
      <br />
      <FacebookLogin
        appId="1149868705955088"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="btnExternal"
        textButton={fbButton}
      />
    </>
  )
}
export default Facebook
