import './style/sum.css'
import './style/style.css'
import { H2 } from '../Text'
import { useEffect } from 'react'
import React, { useState } from 'react'
import { DefaultButton } from '../buttons'
const Adding5Numbers = (props) => {
  const {
    optionList,
    aciertos,
    fallos,
    onPause,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  // })
  useEffect(() => {
    if (fallos !== 0 || aciertos !== 0) {
      handleIndex()
    }
  }, [fallos, aciertos])
  useEffect(() => {
    onPause(true)
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      handleIndex={handleIndex}
      {...props}
    />
  )
}

const OptionGame = (props) => {
  const { dataGame, handleSuccess, handleWrong, onPause } = props
  const [view, setview] = useState(true)
  const [response, setresponse] = useState({
    answer1: '',
    answer2: '',
    sum: '',
  })
  function handleAnswer(e) {
    const { name, value } = e.target
    setresponse((a) => ({
      ...a,
      [name]: value,
    }))
  }
  function handleSum() {
    onPause(false)
    if (dataGame?.answers?.length > 1) {
      if (
        dataGame.answers[0] === response.answer1 &&
        dataGame.answers[1] === response.answer2
      ) {
        setview(false)
      } else {
        handleWrong(dataGame, null)
      }
    }
  }
  function handleTotal() {
    if (dataGame?.answers?.length > 1) {
      setview(true)
      if (
        dataGame.answers[0] === response.answer1 &&
        dataGame.answers[1] === response.answer2 &&
        dataGame.rightAnswer === response.sum
      ) {
        handleSuccess(dataGame, response.sum)
      } else {
        handleWrong(dataGame, null)
      }
    }
  }
  return (
    <div className="OptionGame__heightNone">
      {dataGame?.random?.length > 2 ? (
        view ? (
          <>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="5rem"
            >
              {dataGame.random[0]}
            </H2>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="5rem"
            >
              {dataGame.random[1]}
            </H2>
            <div className="OptionGame__input">
              <label htmlFor="answer1">Ingresa un número</label>
              <br />
              <input
                onChange={handleAnswer}
                type="text"
                name="answer1"
                id="answer1"
                autoFocus
              />
            </div>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="5rem"
            >
              {dataGame.random[2]}
            </H2>
            <div className="OptionGame__input">
              <label htmlFor="answer2">Ingresa un número</label>
              <br />
              <input
                onChange={handleAnswer}
                type="text"
                name="answer2"
                id="answer2"
              />
            </div>
            <DefaultButton
              onClick={handleSum}
              width="auto"
              padding="5px 20px"
              fontSize="2.5rem"
            >
              Sumar
            </DefaultButton>
          </>
        ) : (
          <>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="4rem"
            >
              {dataGame.random[0]}
            </H2>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="4rem"
            >
              {dataGame.random[1]}
            </H2>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="4rem"
            >
              {response.answer1}
            </H2>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="4rem"
            >
              {dataGame.random[2]}
            </H2>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="4rem"
            >
              {response.answer2}
            </H2>
            <div className="OptionGame__input">
              <label htmlFor="sum">Resultado de la suma</label>
              <br />
              <input onChange={handleAnswer} type="text" name="sum" id="sum" />
            </div>
            <DefaultButton
              onClick={handleTotal}
              width="auto"
              padding="5px 20px"
              fontSize="2.5rem"
            >
              Aceptar
            </DefaultButton>
          </>
        )
      ) : null}
    </div>
  )
}
export default Adding5Numbers
