import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export function subtractTime(
  time,
  formatTime = 'HH:mm:ss.SSS',
  subtractAmount = 100,
  subtractType = 'milliseconds',
) {
  return moment(time, formatTime)
    .subtract(subtractAmount, subtractType)
    .format(formatTime)
}
export function convertDate(dateConv, formatDate) {
  if (dateConv) {
    if (formatDate === 'LLLL') {
      const date = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LL')
      const hour = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LT')
      return date + ' a horas ' + hour
    } else {
      return moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format(formatDate)
    }
  }
  return null
}
export function formatMilliseconds(time) {
  if (time.length > 12) {
    time = time.substring(0, 12)
  }
  if (time.length <= 8) {
    time = time + '.900'
  }
  return time
}
export function convertTimeToMilliseconds(time) {
  return moment(time, 'HH:mm:ss.SSS').diff(
    moment().startOf('day'),
    'milliseconds',
  )
}

export function convertTimeToSeconds(time) {
  return moment(time, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds')
}
export function subtractDates(date1, date2) {
  
  const timeRemaing = moment(date1, 'YYYY-MM-DDTHH:mm:ss').diff(
    moment(date2, 'YYYY-MM-DDTHH:mm:ss'),
    'days',
  )
  return timeRemaing
}
