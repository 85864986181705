import React from 'react'
import ThemeStyle from './style/ThemeStyle'
import { connect } from 'react-redux'
import { GlobalModal } from '../modal'
import { Snackbar } from '../common'
const Theme = ({ themeReducer, children }) => {
  return (
    <>
      <ThemeStyle color={themeReducer} />
      {children}
      <GlobalModal />
      <Snackbar />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    themeReducer: state.themeReducer,
  }
}
export default connect(mapStateToProps, null)(Theme)
