import { Switch, Route, withRouter } from 'react-router-dom'
import ProtectedRoute from '../components/navigation/ProtectedRoute'
import TopicContent from '../containers/lessons/topic/TopicContent'
import {
  Home,
  Auth,
  Topic,
  Scores,
  Payment,
  Lessons,
  Simulation,
  OrderResult,
  SimulationMenu,
  SimulationResults,
  AccountManagement,
  MobileApp,
  Welcome,
  GamesHistory,
  // PaymentRegister,
  Seller,
  FormPayment,
  PlansPayment,
  Statistics,
} from '../view'
import Register from '../view/Auth/Register/Register'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const Main = (props) => {
  const {
    location: { pathname },
    changeNavbar,
  } = props
  useEffect(() => {
    if (pathname.includes('simulation')) {
      changeNavbar(false)
    } else {
      changeNavbar()
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: pathname,
      },
    })
  }, [pathname])
  return (
    <Switch>
      <Route path="/auth" component={Auth} />
      <Route path="/mobile-app" component={MobileApp} />
      <Route path="/register/:uid?" component={Register} />
      {/* <Route path="/payment/:uid" component={FormPayment} /> */}
      <Route path="/payment/:uid/:pid" component={Payment} />
      <Route path="/payment/:pid" component={FormPayment} />
      <Route path="/plans-payment/:uid?" component={PlansPayment} />
      <Route path="/statistics" component={Statistics} />
      <Route path="/order-result/:uid/:pid/:type?" component={OrderResult} />
      <ProtectedRoute
        exact
        path="/seller"
        component={Seller}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/welcome"
        component={Welcome}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons"
        component={Lessons}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/scores"
        component={Scores}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/history/:username/:id"
        component={GamesHistory}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/management"
        component={AccountManagement}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons/:lid/:name/topics"
        component={Topic}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons/:lid/topic/:tid"
        component={TopicContent}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons/:lid/topic/:tid/simulation/:sid"
        component={SimulationMenu}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons/:lid/topic/:tid/simulation/:sid/result/:mid"
        component={SimulationResults}
        validate={['isAuthenticated']}
      />
      <ProtectedRoute
        exact
        path="/lessons/:lid/topic/:tid/simulation/:sid/:name/:level/:baseNumber"
        component={Simulation}
        validate={['isAuthenticated']}
      />
      <Route path="/" component={Home} />
    </Switch>
  )
}

export default withRouter(Main)
