import { useEffect, useState } from 'react'
import { Loading } from '../../components/animation'
import { ModalDefault } from '../../components/modal'
import { Title } from '../../components/Text'
import { ButtonLink } from '../../components/buttons'
import { putAction } from '../../services/actions/AuthAction'
import { validateStatus, validateArray } from '../../utils/Validation'
import { StringToJson } from '../../utils/Generate'
import { useAxiosAuth } from '../../hook'
import { connect } from 'react-redux'
import { startSession, openSnackbar } from '../../store/actions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'




const OrderResult = (props) => {
  const {
    match,
    sesionUser: { user },
    startSess,
    history
  } = props
  const params = new URLSearchParams(props.location.search)
  const father = StringToJson(window.localStorage.getItem('FatherDate'), null)
  const child = StringToJson(window.localStorage.getItem('ChildDate'), null)

  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/listChild`,
  })

  useEffect(() => {
    if(window.localStorage.getItem("pay")=="false"){
      history.push('/management')
    }
  
    
  }, [])

  const [useAxios, setuseAxios] = useState({
    response: null,
    loading: true,
    loadResponse: false,
    status: null,
  })
  useEffect(() => {
    async function onSubmit() {
      await putAction('/users/paymentComplete', {
        Order_id: params.get('result'),
        Transaction_id: params.get('order_id'),
        UserId: match.params.uid,
        paymentPlanId: match.params.pid,
      }).then((response) => {
        if (validateStatus(response.status)) {
          props.openSnackbar('Compra exitosa', true, true)
          const {
            userToReturn,
            token,
            rolsAssignedToList,
            testData,
          } = response.data
          startSess(
            userToReturn,
            rolsAssignedToList || [],
            [],
            true,
            testData,
          )
          window.localStorage.setItem("pay", "false")
        }

        setuseAxios({
          response: response.data,
          loading: false,
          status: response.status,
        })
      })
    }
    async function onSubmitDirect() {
      await putAction('/auth/preregisterComplete', {
        FatherName: father.name,
        FatherLastName: father.lastname,
        FatherPhone: father.phone,
        FatherPassword: father.password,
        Email: father.email,
        Country: father.Currency,
        City: father.estado_lbl,
        Order_Id: params.get('result'),
        ChildName: child.Name,
        ChildLastName: child.LastName,
        ChildUsername: child.Username,
        ChildBirthdate: child.BirthDate,
        ChildPassword: child.password,
        IsPersonal: child.IsPersonal,
        gender: child.Character,
        Transaction_Id: params.get('order_id'),
        paymentPlanId: match.params.pid,
      }).then((response) => {
        if (validateStatus(response.status)) {
          props.openSnackbar('Compra exitosa', true, true)
          const {
            userToReturn,
            token,
            rolsAssignedToList,
            testData,
          } = response.data
          window.localStorage.setItem('id', userToReturn.id)
          window.localStorage.setItem('token_seguridad', token)
          startSess(
            userToReturn,
            rolsAssignedToList || [],
            [],
            true,
            testData,
          )
          window.localStorage.setItem("pay", "false")
          window.localStorage.removeItem('FatherDate')
          window.localStorage.removeItem('ChildDate')
        }
        setuseAxios({
          response: response.data,
          loading: false,
          status: response.status,
        })
      })
    }
    if (
      params &&
      useAxios.loadResponse === false &&
      useAxios.response === null
    ) {
      setuseAxios((a) => ({
        ...a,
        loadResponse: true,
      }))
      if (match.params.type) {
        onSubmitDirect()
      } else {
        onSubmit()
      }
    }
  }, [params])

  function searchUser() {
    let aux = ''
    response.forEach((element) => {
      if (element.id === parseInt(match.params.uid)) {
        aux = aux + element.username
      }
    })
    return aux
  }
  return (
    <ModalDefault
      background="rgba(0, 0, 0, 0.5)"
      onClose={() => props.history.push('/management')}
    >
      <div
        style={{ background: 'white', padding: '10px', borderRadius: '10px' }}
      >
        {useAxios.loading && useAxios.response === null ? (
          <>
            <Title color="var(--green)">Procesando su pedido</Title>
            <Loading />
          </>
        ) : validateStatus(useAxios.status) ? (
          !loading && (
            <>
              <Title
                fontWeight="800"
                textTransform="uppercase"
                textAlign="center"
                color="var(--purple)"
                fontSize="3rem"
              >
                GRACIAS POR SU COMPRA {user.firstName || user.username}
              </Title>
              <Title textAlign="center" width="auto" color="var(--purple)">
                {validateArray(response)
                  ? `¡Le damos la bienvenida a ${searchUser()} este maravilloso mundo de las matematicas!`
                  : '¡Te damos la bienvenida a este maravilloso mundo de las matemáticas!'}
              </Title>
              <br />
              <center>
                <ButtonLink background="var(--purple)" to="/management">
                  Comenzar
                </ButtonLink>
              </center>
              <br />
            </>
          )
        ) : (
          <>
            <Title
              fontWeight="800"
              textAlign="center"
              color="var(--green)"
              fontSize="3rem"
            >
              No se pudo completar su pedido
            </Title>
            <br />
            <br />
            <center>
              <ButtonLink background="var(--green)" to="/">
                Ir al inicio
              </ButtonLink>
            </center>
            <br />
          </>
        )}
      </div>
    </ModalDefault>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { startSess:startSession, openSnackbar })(
  OrderResult,
)
