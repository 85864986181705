import { validateArray } from '../../utils/Validation'
import { DefaultButton } from '../../components/buttons'
import { useState } from 'react'
import { H2 } from '../Text'
import { useEffect } from 'react'

const MultiplicationBy9 = (props) => {
  const {
    optionList,
    aciertos,
    fallos,
    principal,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  //   principal: principal,
  // })
  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])
  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      {...props}
    />
  )
}
function OptionGame(props) {
  const {
    dataGame,
    principal,
    operation,
    handleWrong,
    aciertos,
    fallos,
    handleSuccess,
  } = props
  const [answer, setanswer] = useState({
    answers: ['', '', '', '', ''],
    rightAnswer: '',
  })
  function handleAnswer(e) {
    const { name, value } = e.target
    const list = name.split('-')
    let aux = answer.answers
    aux[list[1] - 1] = value
    setanswer((a) => ({
      ...a,
      answers: [...aux],
    }))
  }
  function handleRightAnswer(e) {
    const { name, value } = e.target
    setanswer((a) => ({
      ...a,
      [name]: value,
    }))
  }
  function handleTotal() {
    let resp = ''
    answer.answers.forEach((item) => {
      resp = resp + item + ','
    })
    if (dataGame.rightAnswer === resp + answer.rightAnswer) {
      handleSuccess(dataGame, resp + answer.rightAnswer)
    } else {
      handleWrong(dataGame, resp + answer.rightAnswer)
    }
  }

  useEffect(() => {
    setanswer({
      rightAnswer: '',
      answers: ['', '', '', '', ''],
    })
  }, [fallos, aciertos])

  return (
    <>
      <div className="OptionGame">
        <H2 textAlign="center" fontWeight="900" color="#8c7197" fontSize="6rem">
          {validateArray(dataGame.random) ? dataGame.random[0] : ''}
          <br />
          {operation} {validateArray(dataGame.random) ? dataGame.random[1] : ''}
        </H2>
      </div>

      <div className="MultiplicationBy9__grid">
        <H2 width="auto" fontWeight="900" color="white" fontSize="8rem">
          1)
        </H2>
        <div className="OptionGame__input">
          <input
            autoFocus
            onChange={handleAnswer}
            type="text"
            name="answer-1"
            id="answer-1"
            value={answer.answers[0]}
            style={{ width: '220px' }}
          />
        </div>
        <div className="OptionGame__input">
          <input
            onChange={handleAnswer}
            type="text"
            name="answer-2"
            id="answer-2"
            value={answer.answers[1]}
            style={{ width: '220px' }}
          />
        </div>
        <H2 width="auto" fontWeight="900" color="white" fontSize="8rem">
          2)
        </H2>
        <div className="OptionGame__input">
          <input
            onChange={handleAnswer}
            type="text"
            name="answer-3"
            id="answer-3"
            value={answer.answers[2]}
            style={{ width: '220px' }}
          />
        </div>
        <div></div>
        <H2 width="auto" fontWeight="900" color="white" fontSize="8rem">
          3)
        </H2>
        <div className="OptionGame__input">
          <input
            onChange={handleAnswer}
            type="text"
            name="answer-4"
            id="answer-4"
            value={answer.answers[3]}
            style={{ width: '220px' }}
          />
        </div>
        <div></div>
        <H2 width="auto" fontWeight="900" color="white" fontSize="8rem">
          4)
        </H2>
        <div></div>
        <div className="OptionGame__input">
          <input
            onChange={handleAnswer}
            type="text"
            name="answer-5"
            id="answer-5"
            value={answer.answers[4]}
            style={{ width: '220px' }}
          />
        </div>
        <H2 width="auto" fontWeight="900" color="white" fontSize="8rem">
          5)
        </H2>
        <div className="OptionGame__input">
          <input
            onChange={handleRightAnswer}
            type="text"
            name="rightAnswer"
            id="rightAnswer"
            value={answer.rightAnswer}
            style={{ width: '220px' }}
          />
        </div>
      </div>
      <center>
        <DefaultButton
          onClick={handleTotal}
          width="auto"
          padding="5px 20px"
          fontSize="2.5rem"
        >
          Verificar respuesta
        </DefaultButton>
      </center>
      <br />
    </>
  )
}

export default MultiplicationBy9
