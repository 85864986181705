import { GlobalButton } from '../buttons'
import { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import Loading from '../animation/Loading'
import { H2 } from '../Text'
import { validateStatus } from '../../utils/Validation'
import { putAction } from '../../services/actions/AuthAction'
const FormChangePass = (props) => {
  const { children, onCancel, openSnackbar } = props
  const [load, setload] = useState(true)
  const initialValues = {
    password: '',
    password_confirmation: '',
  }
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Ingrese una contraseña')
      .min(4, 'Mínimo 4 caracteres'),
    password_confirmation: Yup.string()
      .required('Este campo es requerido')
      .min(4, 'Mínimo 4 caracteres')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await putAction('/auth/newpass', {
        ChildId: children.id,
        password: values.password,
      }).then((response) => {
      setload(true)
      if (validateStatus(response.status)) {
          openSnackbar('Cambio de contraseña exitosa', true, true)
          onCancel()
        } else {
          openSnackbar(
            response.data ? response.data : 'No se pudo cambiar',
            true,
            false,
          )
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <>
      <H2 textAlign="center">Cambiar contraseña</H2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="password"
                label="Contraseña"
                name="password"
              />
              <FormikControl
                control="input"
                type="password"
                label="Confirmar Contraseña"
                name="password_confirmation"
              />
              {load ? (
                <center>
                  <GlobalButton disabled={!formik.isValid} type="submit">
                    Cambiar
                  </GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default FormChangePass
