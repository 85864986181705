import { wrongData } from '../../utils/Constant'
const SnackbarError = ({ message }) => {
  return (
    <div
      className="snackText"
      style={{ background: '#c93f3f', color: 'var(--white)' }}
    >
      <i className="fas fa-times-circle"></i>
      {wrongData[message] || message}
    </div>
  )
}

export default SnackbarError
