import ModalDefault from './ModalDefault'
import { connect } from 'react-redux'
import { changeModal } from '../../store/actions'
import { DefaultButton } from '../buttons'
import './GlobalModal.css'
const GlobalModal = (props) => {
  const { modalReducer, changeModal } = props
  function handelModal() {
    changeModal(!modalReducer.status, '', '', '', null)
  }
  function handelButtonModal() {
    modalReducer.onClick()
    changeModal(!modalReducer.status, '', '', '', null)
  }
  if (modalReducer.status) {
    return (
      <ModalDefault
        background="rgba(0, 0, 0, 0.5)"
        onClose={handelModal}
        closeOutside={modalReducer.message === null}
      >
        <div className="GlobalModal">
          <div className="GlobalModal__title">{modalReducer.title}</div>
          <p>{modalReducer.message}</p>
          <div className="GlobalModal__btns">
            {modalReducer.message !== null ? (
              <DefaultButton
                background="white"
                border="1px solid var(--purple)"
                color="var(--purple)"
                onClick={handelModal}
              >
                Cerrar
              </DefaultButton>
            ) : null}
            {modalReducer.butttonText && (
              <DefaultButton
                background="var(--purple)"
                border="1px solid var(--purple)"
                color="var(--white)"
                onClick={handelButtonModal}
              >
                {modalReducer.butttonText}
              </DefaultButton>
            )}
          </div>
        </div>
      </ModalDefault>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    modalReducer: state.modalReducer,
  }
}
export default connect(mapStateToProps, { changeModal })(GlobalModal)
