import App from './App'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import reducer from './store/reducers'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
const initialState = {
  themeReducer: {
    color_link: '#02696b',
    color_hover: '#469585',
    color_purple: '#773B8E',
    color_button_hover: '#914bad',
    color_input: '#B77AA6',
    color_input_hover: '#E9C4B1',
    color_text: '#6B6262',
    purple_logo: '#A159B6',
    orange_lite: '#fce4d6',
    purple2_logo: '#c792db',
    orange: '#d17742',
    purple: '#6a3081',
    green: '#458e7f',
    white: '#ffffff',
    yellow: '#e3ad2d',
    gray: '#919192',
  },
  statusNavbar: true,
  modalReducer: {
    status: false,
    title: '',
    message: '',
    butttonText: '',
    onClick: null,
  },
  snackbarReducer: {
    open: false,
    message: '',
    kind: true,
  },
  sesionUser: {
    user: {},
    rols: [],
    userDataTest: {},
    institutions: [],
    authenticate: false,
  },
}
const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)
const container = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  container,
)
reportWebVitals()
