import { validateArray } from '../../utils/Validation'
import { useState } from 'react'
import { H2 } from '../Text'
import RenderOptions from './RenderOptions'
import { useEffect } from 'react'
import './style/TableSum.css'
const MultiplationByFive = (props) => {
  const {
    optionList,
    aciertos,
    fallos,
    principal,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  //   principal: principal,
  // })
  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])
  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      {...props}
    />
  )
}
function OptionGame(props) {
  const { dataGame, principal, operation } = props
  const ListColor = ['#6A3081', '#D17742', '#458E7F']
  return (
    <>
      <div className="AspaSimple__multiplication">
        <H2 textAlign="end" fontWeight="900" color="#8c7197" fontSize="8rem">
          {validateArray(dataGame.random) ? dataGame.random[0] : ''}
          <br />
          {operation}{' '}
          {principal
            ? principal
            : validateArray(dataGame.random)
            ? dataGame.random[1]
            : ''}
        </H2>
      </div>
      <div className="TableSum">
        {validateArray(dataGame?.answers) ? (
          <div className="MultipleChoice__options">
            {dataGame.answers.map((number, index) => (
              <RenderOptions
                key={index}
                number={number}
                background={ListColor[index]}
                {...props}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default MultiplationByFive
