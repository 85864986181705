import './style/Icon.css'
const Icon = ({ handleModal, ico, children, disabled }) => {
  return (
    <button
      onClick={handleModal}
      type="button"
      className="IconBtn"
      disabled={disabled ? disabled : false}
    >
      <h3>
        {children}
        {ico && <i className={`fas fa-${ico}`}></i>}
      </h3>
      {/* <center><span ><h3>{children}</h3></span></center> */}
    </button>
  )
}

export default Icon
