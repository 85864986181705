import Home from "../Home/Home";
import { ModalDefault } from "../../components/modal";
import { Title, H2 } from "../../components/Text";
import { DefaultButton } from "../../components/buttons";
import { Loading } from "../../components/animation";
import { validateArray, validateStatus } from "../../utils/Validation";
import { subtractDates } from "../../utils/ConvertDate";
import { useAxiosAuth } from "../../hook";
import { connect } from "react-redux";
import "./Welcome.css";
import { getAction, getActionAuth, postAction } from "../../services/actions/AuthAction";
import { useState } from "react";
import {useHistory} from 'react-router-dom';
import useActionParam from "../../hook/useActionParam";
import { openSnackbar, startSession } from "../../store/actions";
const Welcome = (props) => {
  const {
    history,
    startSession1,
    sesionUser: { userDataTest, user },
  } = props;
  const [mostrarBotones, setMostrarBotones] = useState(true);

  
  const nav = useHistory();
  console.log(user);
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: `/users/${window.localStorage.getItem("id")}/listChild`,
  });
  const validate = userDataTest?.remainingDate
    ? userDataTest.remainingDate[0]
    : null;
  console.log(userDataTest.remainingDate[1])
  const literalTime = userDataTest?.literalTime;  
  // const remaining =
  //   userDataTest?.status === "completed"
  //     ? 1
  //     : `${parseInt(userDataTest.remainingDate.split(".")[0])} días`;


  const remaining =
    userDataTest?.status === "completed"
      ? 1
      : userDataTest &&
        Object.keys(userDataTest).length !== 0 &&
        parseInt(userDataTest?.remainingDate.split(".")[0]) + 1;
  /* : Math.ceil(parseInt(userDataTest.remainingDate.split('.')[0])) */

  function handelModal() {
    history.push("/management");
  }
  function save(res) {
    // setload(true)
    if (res.status === 200) {
      console.log('entro');
      console.log(res.data);
      openSnackbar('Inicio Exitoso', true, true)
      const { institutions,  userToReturn,  testData } = res.data
      startSession1(userToReturn, userToReturn.rols || [], null, true, testData)
      // si no tiene correo entonces es estudiante (logica de antes)
      // ahora comprar con el rol si es estudiante
      // if (verifyStudent(rols[0])) {
      //   history.push('/lessons')
      // } else if (userDataTest.status === 'completed') {
      //   history.push('/management')
      // } else {
      //   history.push('/welcome')
      // }
      history.push('/management')
    } else {
      // setError([res.data])
      openSnackbar(res.data ? res.data : 'Datos incorrectos', true, false)
    }
  }



  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getActionAuth,
    param: window.localStorage.getItem('id') ? `/users/${window.localStorage.getItem('id')}/detailed` : '',
  })
  const OnClickRegisterHimself = async () => {
    try {
      setMostrarBotones(false);
      const response = await postAction(`/auth/register/me/${window.localStorage.getItem('id')}`).then((res)=>{console.log('pasó')})
      
        await getActionAuth(window.localStorage.getItem('id') ? `/users/${window.localStorage.getItem('id')}/detailed` : '').then((res) => {
          save(res)
      
        })

        // if (validateStatus(res.status)) {
        //   reload()
        
        //     const { institutions, userToReturn, testData } = dataRequest
        //     startSession1(
        //       userToReturn,
        //       userToReturn.rols || [],
        //       institutions,
        //       true,
        //       testData,
        //     )
        //   history.push('/management')
        //   props.openSnackbar('Se inscribió correctamente', true, true)
        // } else {
        //   props.openSnackbar('Error al guardar', true)
        // }
      
    } catch (e) {
      // setload(true)
      console.log(e)
    }
  ;
  

  }
  return (
    <>
      <Home />
      <ModalDefault background="rgba(0, 0, 0, 0.5)" >
      {/* <ModalDefault background="rgba(0, 0, 0, 0.5)" onClose={handelModal}> */}
        {loading ? (
          <Loading />
        ) : !validateArray(response) ? (
          validate === "-"  && userDataTest.status !== "completed" ?(
            <RenderModal
              title="Finalizó su tiempo de prueba"
              text1="Fue un gusto haberte tenido una semana con nosotros. Estamos seguros que serás parte de este maravillo curso"
              text2="Para continuar esta maravillosa aventura debes comprar el curso."
              textCancel="Cerrar"
              textBtn="Registrar a tus hij@s y comprar"
              onClick={() => history.push("/management")}
              onCancel={() => history.push("/management")}
            />
          ) : ( 



            <RenderModal
              title="Bienvenido a Wondermath"
              text1="Comenzaremos esta aventura con Bruno y Sofía en la ciudad Maravilla."
              // text2="Debes iniciar registrando a tus hij@s"
              textBtnExtra="Regístrate" 
              onClickExtra={OnClickRegisterHimself}
              textBtn="Registrar a tus hij@s"
              onClick={() => history.push("/management")}
              showButtons={mostrarBotones}
            />



          )
        ) : validate === "-" && userDataTest.status !== "completed" ?  (
          <RenderModal
            title="Finalizó su tiempo de prueba"
            text1="Para continuar esta maravillosa aventura debe comprar el curso."
            text2=""
            textCancel="Cerrar"
            textBtn={`Comprar para ${response[0].username}`}
            onClick={() => history.push(`/plans-payment/${response[0].id}`)}
            onCancel={() => history.push("/")}
          />
        ) : parseInt(validate) > 0 && userDataTest.status !== "completed" ? (
          <RenderModal
            title={`Te quedan ${literalTime} de tu periodo de prueba`}
            text1="Compra el curso para tener acceso al curso completo."
            text2=""
            textBtn={`Comprar para ${response[0].username}`}
            textCancel="Cerrar"
            onClick={() => history.push(`/plans-payment/${response[0].id}`)}
            onCancel={() => history.push("/management")}
          />
        ) 
        // :  (
        //   <RenderModal
        //     title="¡Bienvenid@ a Wondermath!"
        //     text1="Comienza esta aventura con Bruno y Sofía en la ciudad Maravilla."
        //     text2="Debes iniciar registrando a tus hij@s"
        //     textBtn="Registrar a tus hij@s"
        //     onClick={() => history.push("/management")}
        //   />
        // )
        : history.push("/management")
      }
      </ModalDefault>
    </>
  );
};
const RenderModal = (props) => {
  const { title, text1, text2, textBtn, textBtnExtra, textCancel, onCancel, onClick, onClickExtra, showButtons} = props;
  return (
    <div className="Welcome">
      <Title
        fontSize="4rem"
        fontWeight="700"
        textAlign="center"
        color="var(--purple)"
        margin="0.5rem 0"
      >
        {title}
      </Title>
      <H2 margin="0.5rem 0" textAlign="center" fontSize="2rem">
        {text1}
      </H2>
      {text2 && (
        <H2 color="var(--purple)" textAlign="center" fontSize="2rem">
          {text2}
        </H2>
      )}
      <br />
      <div className="Welcome__btns">
        {onCancel && (
          <DefaultButton
            fontSize="1.8rem"
            padding="8px 20px"
            background="var(--gray)"
            onClick={onCancel}
          >
            {textCancel}
          </DefaultButton>
        )}
        

        {showButtons && (
          <>
          <DefaultButton
            fontSize="1.8rem"
            padding="8px 20px"
            background="var(--orange)" 
            onClick={onClickExtra}
          >
            {textBtnExtra}
          </DefaultButton>

          <DefaultButton
          fontSize="1.8rem"
          padding="8px 20px"
          background="var(--purple)"
          onClick={onClick}
          >
          {textBtn}
          </DefaultButton>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
    user: state.user
  };
};
export default connect(mapStateToProps,{startSession1:startSession, openSnackbar} ,null)(Welcome);
