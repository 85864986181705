import { useAxiosAuth } from '../../hook'
import { capitalizeFirstLetter, validateArray } from '../../utils/Validation'
import { H2 } from '../../components/Text'
import { ButtonIcon } from '../../components/buttons'
import styled from 'styled-components'
import { convertDate } from '../../utils/ConvertDate'
import { useState,useEffect } from 'react'
import TableFooter from '../Table/TableFooter'
import Loading from '../../components/animation/Loading'
import TableMain from '../Table/TableMain'
import { optionLevel } from '../../utils/Constant'

const MessageContainer = styled.div`
  background: white;
  padding: 15px;
  border-radius: 10px;
`

const GamesHistory = (props) => {
  const [url, seturl] = useState({
    PageNumber: 1,
    OrderBy: '',
  })
  const { response, loading, headersRequest, reload } = useAxiosAuth({
    method: 'GET',
    url: `/Score/historial/${props.match.params.id}?PageNumber=${url.PageNumber}&ForQuery=status?TypeQuery=finished`,
  })


  
  const header = [
    { key: 'themeName', name: 'Simulador' },
    { key: 'date', name: 'Fecha', type: 'date' },
    { key: 'date', name: 'Hora', type: 'time' },
    { key: 'levelName', name: 'Nivel',type:'levelOption' },
    { key: 'failed', name: 'Errores' },
    { key: 'right', name: 'Aciertos' },
    { key: 'total', name: 'Total' },
  ]
  useEffect(() => {
    
    if( !loading){
      reload();
    }

  }, [url])
  
  
  return (
    <div className="Scores">
      <ButtonIcon to="/management" />
      <div className="Scores__container">
        <div className="Scores__main">
          <H2 textAlign="center" color="var(--white)" fontSize="3rem">
            HISTORIAL DE AVANCE DE {props.match.params.username.toUpperCase()}
          </H2>
          <br />
          {loading ? (
            <Loading background="var(--white)" />
          ) : validateArray(response) ? (
            <>
              <TableMain header={header} main={response} />
              <TableFooter
                seturl={seturl}
                url={url}
                reload={reload}
                pageInfo={headersRequest}
                amount={response.length}
              />
            </>
          ) : (
            <H2 textAlign="center" color="var(--purple)" fontWeight="400">
              <MessageContainer>
                En esta pantalla podrá ver el avance de{' '}
                {capitalizeFirstLetter(props.match.params.username)} cuando
                empiece a jugar el curso
              </MessageContainer>
            </H2>
          )}
        </div>
      </div>
    </div>
  )
}

export default GamesHistory
