import { validateArray } from '../../utils/Validation'
import { useState } from 'react'
import { H2 } from '../Text'
import RenderOptions from './RenderOptions'
import { useEffect } from 'react'
const AddSumaTab = (props) => {
  const {
    gameData,
    optionList,
    aciertos,
    fallos,
    operation = '+',
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  //   principal: gameData.baseNumber,
  // })
  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])
  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      {...props}
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      operation={operation}
    />
  )
}
function OptionGame(props) {
  const { dataGame, principal, operation } = props
  const ListColor = ['#6A3081', '#D17742', '#458E7F']
  return (
    <div className="OptionGame">
      <H2 textAlign="center" fontWeight="900" color="#8c7197" fontSize="8rem">
        {principal} {operation}{' '}
        {validateArray(dataGame.random) ? dataGame.random[0] : ''}
      </H2>
      {validateArray(dataGame?.answers) ? (
        <div className="OptionGame__list1">
          {dataGame.answers.map((number, index) => (
            <RenderOptions
              key={index}
              number={number}
              background={ListColor[index]}
              {...props}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default AddSumaTab
