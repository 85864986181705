import React, { useState } from 'react'
import { H2 } from '../Text'
import { DefaultButton } from '../buttons'
import { useEffect } from 'react'
const DivisionVédico = (props) => {
  const { optionList, aciertos, fallos, indexGame, setindexGame } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  // })

  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return <OptionGame dataGame={optionList[indexGame.index]} {...props} />
}
const OptionGame = (props) => {
  const { dataGame, fallos, aciertos, handleSuccess, handleWrong } = props
  const [answers, setanswers] = useState({
    answers: [...new Array(dataGame.random[0].length).fill('')],
    rightAnswer: '',
  })
  function handleAnswer(e) {
    const { name, value } = e.target
    setanswers((a) => ({
      ...a,
      [name]: value,
    }))
  }
  function handleAnswers(e, index) {
    const { value } = e.target
    let aux = answers.answers
    aux[index] = value
    setanswers((a) => ({
      ...a,
      answers: [...aux],
    }))
  }
  useEffect(() => {
    setanswers({
      rightAnswer: '',
      answers: new Array(dataGame.random[0].length).fill(''),
    })
  }, [fallos, aciertos])
  function handleTotal() {
    let resp = ''
    answers.answers.forEach((item) => {
      resp = resp + item + ','
    })
    console.log(dataGame.rightAnswer, resp + answers.rightAnswer)
    if (dataGame.rightAnswer === resp + answers.rightAnswer) {
      handleSuccess(dataGame, resp + answers.rightAnswer)
    } else {
      handleWrong(dataGame, resp + answers.rightAnswer)
    }
  }
  return (
    <div className="TableSum">
      <br />
      <div className="DivisionTraditional__main">
        <div className="DivisionTraditional__dividend">
          <div
            className="DivisionTraditional__rest"
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            {dataGame.random[0].split('').map((character, key) => {
              return (
                <React.Fragment key={key}>
                  <div className='DivisionTraditional__divdivident'
                    key={key}
                    
                  >
                    {character}
                  </div>
                  {dataGame.answers[dataGame.answers.length - key - 1] !==
                  'NaN' ? (
                    <div className="OptionGame__input">
                      <input
                        style={{
                          width: '30px',
                          marginTop: '60px',
                          height: '20px',
                          fontSize: '3.5rem',
                        }}
                        onChange={(e) => handleAnswers(e, key)}
                        type="text"
                        name={`answers[${key}]`}
                        value={answers.answers[key]}
                        autocomplete="off"
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className="DivisionTraditional__divider">
          <div className="DivisionTraditional__div">
            < div className='DivisionTraditional__divdivider'
            >
              {dataGame.random[1]}
            </div>
          </div>
          <br />
          <div className="OptionGame__input">
            <input
              style={{width: '80px',
              
              height: '40px',
              fontSize: '3.5rem',}}
              onChange={handleAnswer}
              type="text"
              name="rightAnswer"
              id="rightAnswer"
              autocomplete="off"
              value={answers.rightAnswer}
            />
          </div>
        </div>
      </div>
      <DefaultButton
        onClick={handleTotal}
        width="auto"
        padding="5px 20px"
        fontSize="2.5rem"
        // disabled={handleDisabled()}
      >
        Verificar respuesta
      </DefaultButton>
    </div>
  )
}
export default DivisionVédico
