import { H3 } from '../../components/Text'
import { Loading } from '../../components/animation'
import { DefaultButton } from '../../components/buttons'
import { FormikControl } from '../../components/inputs'
import { postAction } from '../../services/actions/AuthAction'
import { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { requestURL2 } from '../../config.json'
import { validateStatus } from '../../utils/Validation'
import { parsePhoneNumber } from 'react-phone-number-input'

import './FormContact.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
const FormContact = ({ openSnackbar, changeModal }) => {
  const [load, setload] = useState(true)
  const initialValues = {
    Name: '',
    Phone: '',
    City: '',
  }
  const validationSchema = Yup.object({
    Name: Yup.string().required('Campo Obligatorio'),
    Phone: Yup.string().required('Campo Obligatorio'),
    City: Yup.string().required('Campo Obligatorio'),
  })
  const onSubmit = async (values) => {
    if (values.Phone !== undefined) {
      const codeNumber = parsePhoneNumber(values.Phone)
      const data = {
        ...values,
        Description: 'Matemáticas WonderMath',
        Country: codeNumber.country,
        CountryCode: codeNumber.countryCallingCode,
        NationalNumber: codeNumber.nationalNumber,
      }
      setload(false)
      await postAction(`${requestURL2}/contact/external`, data).then(
        (response) => {
          setload(true)
          if (validateStatus(response.status)) {
            openSnackbar('Datos enviados', true, true)
            changeModal(false, null)
          } else {
            openSnackbar(
              response.data ? response.data : 'Error al enviar mensaje',
              true,
              false,
            )
          }
        },
      )
    } else {
      setload(true)
      openSnackbar('Datos del telefono incorrecto', true)
    }
  }
  return (
    <div className="FormContact">
      {/* <img
        src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
        alt="Logotipo"
      /> */}
      {/* <H3 color="var(--purple)" textAlign="center" margin="0 0 10px">
        Tenemos una oferta especial para ti
      </H3> */}
      <H3
        fontWeight="700"
        fontSize="2rem"
        color="var(--purple)"
        textAlign="center"
      >
        ¿Quieres probar nuestra plataforma? Obtén un día de ACCESO GRATIS
        rellenando el siguiente formulario:
        {/* Un asesor educativo te espera con un gran descuento <br /> para acceder
        a nuestro curso */}
      </H3>
      <div className="FormContact__form">
        {/* <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormikControl
                  control="input"
                  type="text"
                  label="Nombre:"
                  name="Name"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Ciudad:"
                  name="City"
                />
                <FormikControl
                  control="Phone"
                  type="text"
                  label="Teléfono o celular"
                  labelExample="Ejemplo: +591 70140353"
                  name="Phone"
                  uploadValues={formik.setValues}
                />
                {load ? (
                  <center>
                    <DefaultButton type="submit">ENVIAR</DefaultButton>
                    <DefaultButton type="submit"><Link to="/register" >
            Prueba Gratis
          </Link></DefaultButton>
                  </center>
                ) : (
                  <Loading />
                )}
              </Form>
            )
          }}
        </Formik> */}
       
            <Link to="/register" className="button_nav NavigatorNav__green1 ">
            Prueba Gratis
          </Link>
                
      </div>
    </div>
  )
}
export default FormContact
// http://localhost:5000/api/contact/external
// {
//     "Name": "contacto nombre",
//     "Phone": "99119911"
// }
