import React, { useState } from 'react'
import { H2 } from '../Text'
import RenderOptions from './RenderOptions'
import { useEffect } from 'react'
import { validateArray } from '../../utils/Validation'
const GameSquared = (props) => {
  const { optionList, aciertos, fallos, indexGame, setindexGame } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  // })

  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      handleIndex={handleIndex}
      {...props}
    />
  )
}
const OptionGame = (props) => {
  const { dataGame, handleIndex, squared } = props
  const ListColor = ['#6A3081', '#D17742', '#458E7F']
  return (
    <div className="OptionGame">
      <div
        className="OptionGame__list"
        style={{ alignItems: 'start', gap: '5px' }}
      >
        <H2 fontWeight="900" color="#8c7197" fontSize="6rem">
          {validateArray(dataGame.random) ? dataGame.random[0] : ''}
        </H2>
        <div style={{ transform: 'translateY(-20px)' }}>
          <H2 fontWeight="900" color="#8c7197" fontSize="3.5rem">
            {squared}
          </H2>
        </div>
      </div>
      <div className="OptionGame__list1">
        {dataGame.answers.map((number, index) => (
          <RenderOptions
            {...props}
            key={index}
            number={number}
            background={ListColor[index]}
            handleIndex={handleIndex}
            dataGame={dataGame}
          />
        ))}
      </div>
    </div>
  )
}
export default GameSquared
