export const getData = (data) => ({
  type: 'OPEN_SNACKBAR',
  data,
})
export const openSnackbar = (content, status, kind) => ({
  type: 'OPEN_SNACKBAR',
  content,
  status,
  kind,
})
export const startSession = (
  user,
  rols,
  institutions,
  status,
  userDataTest,
) => ({
  type: 'START_SESSION',
  user,
  rols,
  institutions,
  status,
  userDataTest,
})
export const closeSession = () => ({
  type: 'CLOSE_SESSION',
})
export const changeNavbar = (status = true) => ({
  type: 'CHANGE_NAVBAR',
  status,
})
export const changeModal = (status, title, message, butttonText, onClick,Link) => ({
  type: 'CHANGE_MODAL',
  status,
  title,
  message,
  butttonText,
  onClick,
  Link
})
