import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import './style/Forms.css'
import { useState } from 'react'
import * as Yup from 'yup'
import { postAction } from '../../services/actions/AuthAction'
import { Link } from 'react-router-dom'
import './style/Log_in.css'
import { GlobalButton } from '../buttons'
import Loading from '../animation/Loading'
import { validateStatus } from '../../utils/Validation'
import { H2 } from '../Text'
import { connect } from 'react-redux'
import { openSnackbar } from '../../store/actions'
const PasswordRecovery = (props) => {
  const { location, openSnackbar } = props
  const { verifyKey } = props.match.params
  const [load, setload] = useState(true)
  const initialValues = {
    Email: '',
    Password: '',
    Password_confirmation: '',
  }
  const validationSchema = Yup.object({
    Email: Yup.string()
      .email('Ingrese un email válido')
      .required('Campo obligatorio'),
    Password: Yup.string()
      .required('Campo obligatorio')
      .min(4, 'mínimo 4 caracteres requeridos'),
    Password_confirmation: Yup.string()
      .required('Campo Obligatorio')
      .min(4, 'mínimo 4 caracteres requeridos')
      .oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/verifyrecovery', {
        ...values,
        VerifyKey: verifyKey,
      }).then((response) => {
        if (response && validateStatus(response.status)) {
          props.openSnackbar('Ahora puede usar su nueva contraseña', true, true)
          props.history.push('/auth/login')
        } else {
          props.openSnackbar('El enlace no es valido', true, false)
        }
        setload(true)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="log_in">
      <H2 fontSize="2rem" margin="0 0 10px">
        Restablecer Contraseña
      </H2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="email"
                label="Correo electrónico:"
                name="Email"
              />
              <FormikControl
                control="input"
                type="password"
                label="Contraseña"
                name="Password"
              />
              <FormikControl
                control="input"
                type="password"
                label="Confirmar Contraseña"
                name="Password_confirmation"
              />
              {load ? (
                <center>
                  <GlobalButton type="submit">GUARDAR CONTRASEÑA</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <span className="forgotPassword">
        Volver al{' '}
        <Link
          to={{
            pathname: '/auth/login',
            state: {
              from: location.state ? location.state.from : '/',
            },
          }}
        >
          Inicio de sesión
        </Link>
      </span>
    </div>
  )
}
export default connect(null, { openSnackbar })(PasswordRecovery)
