import Input from './Input'
import Textarea from './Textarea'
import Select from './Select'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import InputPhone from './InputPhone'
// import DatePicker from "./DatePicker";
// import EditorInput from "./EditorInput";
// import DropzoneInput from "./DropzoneInput";
import { useEffect, useState } from 'react'
function FormikControl(props) {
  const { control, listError = [], error = [], values = '', ...rest } = props
  const [err, seterr] = useState(false)
  useEffect(() => {
    function handleError() {
      for (let i = 0; i < error.length; i++) {
        if (listError.indexOf(error[i]) > -1) {
          seterr(true)
        }
      }
    }
    handleError()
  }, [listError])
  useEffect(() => {
    seterr(false)
  }, [values])
  switch (control) {
    case 'input':
      return <Input {...rest} errorBoolean={err} />
    case 'textarea':
      return <Textarea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <CheckboxGroup {...rest} />
    case 'Phone':
      return <InputPhone {...rest} />
    // case "date":
    // return <DatePicker {...rest} />;
    // case "editor":
    // return <EditorInput {...rest} />;
    // case "file":
    // return <DropzoneInput {...rest} />;
    // case "chakraInput":
    //   return <ChakraInput {...rest} />;
    default:
      return null
  }
}

export default FormikControl
