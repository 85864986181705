export function getRandomInt(size, number, min, max) {
  let miarray = Array.from({ length: size - 1 }, () =>
    Math.floor(Math.random() * max),
  )
  miarray.push(number)
  return desordenar(miarray)
}

export function desordenar(data) {
  var t = data.sort(function (a, b) {
    return Math.random() - 0.5
  })
  return [...t]
}

export function ordenarArrays(arrays) {
  if (arrays) {
    let auxi = arrays
    // console.log(auxi);
    auxi.sort(function (a, b) {
      if (a.orderNumber > b.orderNumber) {
        return 1
      }
      if (a.orderNumber < b.orderNumber) {
        return -1
      }
      // a must be equal to b
      return 0
    })
    return auxi
  }
  return []
}

export function ordenarArraysTheme(arrays) {
  // console.log(">>>>>",arrays);

  if (arrays) {
    let auxi = arrays
    // console.log(auxi);
    auxi.sort(function (a, b) {
      if (a.order > b.order) {
        return 1
      }
      if (a.order < b.order) {
        return -1
      }
      // a must be equal to b
      return 0
    })
    return auxi
  }
  return []
}
export function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

export function makeid(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function StringToJson(str, initial) {
  let objeto = null
  if (str) {
    try {
      objeto = JSON.parse(str)
    } catch (error) {
      objeto = initial
    }
  } else {
    objeto = initial
  }
  return objeto
}
