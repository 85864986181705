import "./Nav.css";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { H3 } from "../../../components/Text";
import { validateArray } from "../../../utils/Validation";
const Nav = (props) => {
  const {
    closeSession,
    sesionUser: { rols },
  } = props;
  const [view, setview] = useState(false);
  function closeAuth() {
    localStorage.removeItem("token_seguridad");
    localStorage.removeItem("id");
    localStorage.removeItem("FatherDate");
    localStorage.removeItem("ChildDate");
    localStorage.removeItem("pay")
    setview(false);
    closeSession();
  }
  return (
    <div className="ContainerNav">
      <Link
        to={
          validateArray(rols)
            ? validateArray(rols.filter((item) => item.name === "student"))
              ? "/lessons"
              : "/"
            : "/"
        }
      >
        <img
          className="ContainerNav__img"
          src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/logo_2_okkya4_zuaobh.png"
          alt="Logotipo Capacita Global"
        />
      </Link>
      <Sidebar {...props} closeAuth={closeAuth} />
      <ButtonModalS
        {...props}
        closeAuth={closeAuth}
        view={view}
        setview={setview}
      />
    </div>
  );
};
const Sidebar = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="Sidebar">
      <Menu {...props} open={open} setOpen={setOpen} />
      <Burger {...props} open={open} setOpen={setOpen} />
    </div>
  );
};
function Burger({ open, setOpen, sesionUser }) {
  return (
    <div className="Burger__icon" onClick={() => setOpen(!open)}>
      {open ? (
        <i className={`fas fa-${open ? "times" : "bars"}`}></i>
      ) : (
        <>
          <h3 className="Burger__h3">
            {sesionUser?.authenticate
              ? `Hola ${sesionUser.user.firstName
                ? sesionUser.user.firstName
                : sesionUser.user.username
              }`
              : "Menú"}
            <i className={`fas fa-${open ? "times" : "bars"}`}></i>
          </h3>
        </>
      )}
    </div>
  );
}
const Menu = (props) => {
  const { open, setOpen, sesionUser, closeAuth, location } = props;
  return (
    <>
      <nav
        className="Menu__nav"
        open={open}
        style={{
          display: !open && "none",
          transform: !open ? "translateX(0%)" : "translateX(-100%)",
        }}
      >
        <div className="Menu__liks" onClick={() => setOpen(!open)}>
          <Link to="/">
            <img
              className="Menu__img"
              id="logo"
              src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/logo_2_okkya4_zuaobh.png"
              alt="Logotipo Capacita Global"
            />
          </Link>
          {sesionUser?.authenticate ? (
            <>
              {validateArray(sesionUser?.rols) &&
                sesionUser.rols.map((rol) => {
                  switch (rol.name) {
                    case "admin":
                    case "simple":
                      return (
                        <Link key={rol.id} to="/management">
                          Mis hijos
                        </Link>
                      );
                    case "sales":
                      return (
                        <Link key={rol.id} to="/seller" className="button_nav">
                          Ventas
                        </Link>
                      );
                    case "student":
                      if (location.pathname === "/lessons") {
                        return null;
                      } else {
                        return (
                          <Link
                            key={rol.id}
                            to="/lessons"
                            className="button_nav"
                          >
                            Ir al curso
                          </Link>
                        );
                      }
                    case "stats":
                      return (
                        <Link key={rol.id} to="/statistics">
                          Estadísticas
                        </Link>
                      );
                    default:
                      return null;
                  }
                })}

              <Link
                to="/home"
                onClick={closeAuth}
                className="Menu_hamburguer_sesion"
              >
                Cerrar Sesión
              </Link>
            </>
          ) : (
            <>
              <Link
                className="Menu_hamburguer_sesion"
                to="/plans-payment"
                style={{ color: "var(--orange)" }}
              >
                Comprar ahora
              </Link>
              {/* <Link to="/mobile-app" className="Menu_hamburguer">
                Aplicación
              </Link> */}
              <Link
                to="/register"
                // style={{ backgroundColor: 'var(-green)' }}
                className="Menu_hamburguer Menu_hamburguer-green"
              >
                Prueba Gratis
              </Link>
              <Link to="/auth/login" className="Menu_hamburguer_sesion">
                Iniciar sesión
              </Link>
            </>
          )}
        </div>
      </nav>
      {open && (
        <div onClick={() => setOpen(!open)} className="Menu__close"></div>
      )}
    </>
  );
};

const ButtonModalS = (props) => {
  const {
    closeAuth,
    sesionUser,
    view,
    setview,
    sesionUser: { userDataTest },
    location,
  } = props;
  const remaining =
    userDataTest?.status === "completed"
      ? 1
      : userDataTest &&
      Object.keys(userDataTest).length !== 0 &&
      parseInt(userDataTest?.remainingDate.split(".")[0]) + 1;

  const personal =
    userDataTest?.isPersonal
  const literalTime = userDataTest?.literalTime;
  return (
    <div className="ButtonModalS">
      {sesionUser?.authenticate ? (
        <>
          {validateArray(sesionUser?.rols) &&
            sesionUser.rols.map((rol) => {
              switch (rol.name) {
                case "admin":
                case "simple":
                  return (
                    <div>
                      {!personal ?
                        <Link key={rol.id} to="/management">
                          Mis hijos
                        </Link>
                        : null}
                    </div>
                  );
                case "sales":
                  return (
                    <Link key={rol.id} to="/seller" className="button_nav">
                      Ventas
                    </Link>
                  );
                case "stats":
                  return (
                    <Link key={rol.id} to="/statistics">
                      Estadísticas
                    </Link>
                  );
                case "student":
                  if (location.pathname === "/lessons") {
                    return null;
                  } else {
                    return (
                      <Link key={rol.id} to="/lessons" className="button_nav">
                        Ir al curso
                      </Link>
                    );
                  }
                default:
                  return null;
              }
            })}

          {userDataTest.status !== "completed" && (
            <H3 width="fit-content" fontSize="2rem" color="var(--purple)">
              {parseInt(remaining) < 0
                ? "Tu periodo de prueba ha finalizado"
                : `Te quedan ${literalTime}  de prueba`}
            </H3>
          )}
          <div className="NavigatorNav__auth">
            <Link
              to="#"
              onClick={() => setview(!view)}
              style={{ background: "var(--green)" }}
              className="NavigatorNav__auth"
            >
              {" "}
              {sesionUser.user.firstName
                ? sesionUser.user.firstName.split(' ')[0]
                : sesionUser.user.username.split(' ')[0]}
            </Link>
            {view && (
              <>
                <div className="card_navClose">
                  <div style={{ marginBottom: '20px', fontSize: '14px' }}>
                    <span
                      className="navItem"
                    >
                      {sesionUser.user.firstName}
                    </span>
                  </div>
                  <div style={{ marginBottom: '20px', fontSize: '14px' }}>
                    <span
                      className="navItem"
                    >
                      {sesionUser.user.lastName}
                    </span>
                  </div>
                  {
                    sesionUser.user.email !== 'not_have' &&
                    <div style={{ marginBottom: '20px', fontSize: '14px' }}>
                      <span
                        className="navItem"
                      >
                        {sesionUser.user.email}
                      </span>
                    </div>
                  }
                  <div>
                    <Link
                      to="/home"
                      onClick={closeAuth}
                      className="button_navClose"
                    >
                      Cerrar Sesión
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <Link
            className="Home__Hlink_nav"
            to="/plans-payment"
            style={{
              color: "var(--white)",
              backgroundColor: "var(--orange)",
              borderRadius: "20px",
            }} onClick={() => { window.localStorage.setItem("pay", "true") }}
          >
            Comprar ahora
          </Link>
          {/* <Link to="/mobile-app" className="button_nav">
            Aplicación
          </Link> */}
          <Link to="/register" className="button_nav NavigatorNav__green">
            Prueba Gratis
          </Link>
          <Link to="/auth/login" className="NavigatorNav__auth">
            Iniciar sesión <i className="fas fa-user-circle"></i>
          </Link>
        </>
      )}
    </div>
  );
};
export default withRouter(Nav);
