import './AccountManagement.css'
import { useState, useEffect } from 'react'
import { ModalDefault } from '../../components/modal'
import { useAxiosAuth } from '../../hook'
import { RegisterChildren, FormChangePass } from '../../components/forms'
import { Loading } from '../../components/animation'
import { DefaultButton } from '../../components/buttons'
import {
  capitalizeFirstLetter,
  validateArray,
  verifyStudent,
} from '../../utils/Validation'
import { H2, H3, H4 } from '../../components/Text'
import { postAction } from '../../services/actions/Action'
import { openSnackbar, changeModal, startSession } from '../../store/actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ToolTip } from '../../components/boxes'
import { Tutorial } from '../Tutorial/Tutorial'
const AccountManagement = (props) => {
  const { openSnackbar, history, startSession, changeModal, sesionUser: { userDataTest }} = props
  const [modal, setmodal] = useState(false)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/listChild`,
  })
  useEffect(() => {
    if (!validateArray(response) && !loading) {
      setmodal(!modal)
    }
  }, [response, loading])

  function handleSignIn(children) {
    // changeModal(
    //   true,
    //   '¿Estás seguro? Cerrarás tu sesión actual',
    //   `Iniciarás sesión con la cuenta de ${capitalizeFirstLetter(
    //     children.username,
    //   )}`,
    //   'Iniciar sesión',
    //   () => SignIn(children.id, children.username),
    // )
    SignIn(children.id, children.username)
  }
  async function SignIn(id, childrenUsername) {
    const remaining =
    userDataTest?.status === "completed"
      ? 1
      : userDataTest &&
        Object.keys(userDataTest).length !== 0 &&
        parseInt(userDataTest?.remainingDate.split(".")[0]) + 1;
    try {


      await postAction(`/auth/loginkid/${id}`, {}).then((res) => {
        
        if ( parseInt(remaining) >= 0 && res.status === 200) {
          openSnackbar('Inicio Exitoso', true, true)
          const { institutions, rols, user, token, userDataTest } = res.data
          window.localStorage.setItem('id', user.id)
          window.localStorage.setItem('token_seguridad', token)
          startSession(user, rols || [], institutions, true, userDataTest)
          if (verifyStudent(rols[0])) {
            history.push('/lessons')
          } else {
            history.push('/welcome')
          }
        } else {
          changeModal(
            true,
            <>
              <H2>
                <b>Su periodo de prueba ha finalizado</b>
              </H2>
              <a
                href={`/plans-payment/${id}`}
                style={{
                  color: 'var(--purple)',
                  textDecoration: 'none',
                  marginTop: '16px',
                  display: 'block',
                  border: '1px solid var(--purple)',
                  padding: '8px 0',
                  borderRadius: '12px',
                }}
              >
                <i
                  className="fas fa-shopping-cart"
                  style={{
                    marginRight: '8px',
                    fontSize: '2rem',
                  }}
                ></i>
                <span
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  Comprar curso para{' '}
                  <b>{capitalizeFirstLetter(childrenUsername)}</b>
                </span>
              </a>
            </>,
          )
          /* openSnackbar(res.data ? res.data : 'Datos incorrectos', true, false) */
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  const personal = 
  userDataTest?.isPersonal    
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="AccountManagement">
          <div className="AccountManagement__main">
            <div>
                 {!personal ? 
                   <H2
                   fontWeight="600"
                   fontSize="3rem"
                   textAlign="center"
                   color="var(--green)"
                 >
                   NIÑ@S INSCRITOS
                 </H2>
                 : null}
            </div>

            <div className="AccountManagement__tutorial">
              {!personal?
              <CardNewUser handleClick={() => setmodal(!modal)} />:null
              }
              {!personal?
              <Tutorial
                // title="Ver tutorial para padres"
                textBtn="Tutorial para padres"
                videoModalTitle="Tutorial para padres"
                changeModal={changeModal}
                colorBtn="var(--purple)"
                videoUrl="https://vimeo.com/701365711"
              />:null
            }
              
            </div>

            <br />
            {validateArray(response)
              ? response.map((item) => (
                  <CardChildren
                    {...props}
                    handleSignIn={handleSignIn}
                    key={item.id}
                    children={item}
                  />
                ))
              : null}
          </div>
          {modal && (
            <ModalDefault
              onClose={() => setmodal(!modal)}
              background="rgba(0, 0, 0, 0.5)"
            >
              <RegisterChildren
                {...props}
                handleSignIn={() => {}}
                reload={reload}
                onClose={() => setmodal(!modal)}
              />
            </ModalDefault>
          )}
        </div>
      )}
    </>
  )
}

const CardChildren = ({
  children,
  handleSignIn,
  changeModal,
  openSnackbar,
}) => {
  function handlePassword() {
    changeModal(
      true,
      <FormChangePass
        openSnackbar={openSnackbar}
        children={children}
        onCancel={() => changeModal(false, null)}
      />,
      null,
    )
  }
  return (
    <div className="CardChildren">
      <div className="CardChildren__text">
        <img
          src={
            children.character === 'boy'
              ? 'https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/boy_feliz_jp5bbf_vnjy7y.png'
              : 'https://res.cloudinary.com/wmcgi/image/upload/v1655473177/assets/girl_feliz_tnkmki_dmd5lw.png'
          }
          alt=""
        />
        <H3
          textAlign="center"
          color="var(--green)"
          fontSize="2.5rem"
          width="fit-content"
        >
          {capitalizeFirstLetter(children.username)}
        </H3>
      </div>
      <div className="CardChildren__option">
        <DefaultButton
          background="var(--green)"
          fontSize="1.6rem"
          width="fit-content"
          padding="8px 20px"
          onClick={() => handleSignIn(children)}
        >
          Ir al curso
        </DefaultButton>
        <ToolTip content="Tabla de posiciones">
          <Link style={{ background: 'var(--green)' }} to="/scores">
            <i className="fas fa-tasks"></i>
          </Link>
        </ToolTip>
        <ToolTip content="Historial">
          <Link
            style={{ background: 'var(--green)' }}
            to={`/history/${children.username}/${children.id}`}
          >
            <i className="fas fa-clipboard"></i>
          </Link>
        </ToolTip>
        <ToolTip content="Cambiar contraseñas">
          <Link
            onClick={handlePassword}
            style={{ background: 'var(--green)' }}
            to="#"
          >
            <i className="fas fa-unlock-alt"></i>
          </Link>
        </ToolTip>
        {children.statusPayment !== 'completed' && (
          <ToolTip content="Obtener curso completo">
            <Link
              style={{ background: 'var(--orange)' }}
              className="CardChildren__shopping"
              to={`/plans-payment/${children.id}`}
              onClick= {() => {window.localStorage.setItem("pay", "true") }}
            >
              <i className="fas fa-shopping-cart"></i>
            </Link>
          </ToolTip>
        )}
      </div>
    </div>
  )
}
function CardNewUser({ handleClick }) {
  return (
    <div onClick={handleClick} className="CardNewUser">
      <H3
        textAlign="center"
        width="fit-content"
        /*color="var(--green)"*/
        fontSize="2rem"
      >
        <i className="fas fa-user-plus"></i> Registra otr@ niñ@
      </H3>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
    
  }
}
export default connect(mapStateToProps, { openSnackbar, changeModal, startSession })(
  AccountManagement,
)
