import { Link } from 'react-router-dom'
import Load from '../../../components/Loadings/Loading/Load'
import { Title } from '../../../components/Text/index'
import './style/CardTopic.css'
const CardTopic = ({ dataRequest, lid, index, handleModal }) => {
  return dataRequest ? (
    <Link
      to={
        dataRequest.status === 'locked' || dataRequest.status === 'pending'
          ? dataRequest.statusPayment === 'completed'
            ? '#'
            : '/plans-payment'
          : `/lessons/${lid}/topic/${dataRequest.id}`
      }
      onClick={handleModal}
      style={{
        opacity:
          dataRequest.status === 'locked' || dataRequest.status === 'pending'
            ? '0.4'
            : '1',
      }}
      className="CardTopic"
    >
      <div className="CardTopic__card"></div>
      <div className="CardTopic__main">
        <div className="CardTopic__container">
          <FigurePage />
          <div className="CardTopic__text">
            <div className="CardTopic__header">
              <FigureClip />
              <Title textAlign="center" width="100%" color="#d17742">
                {index}. {dataRequest.name}
              </Title>
            </div>
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <Load></Load>
  )
}

function FigurePage() {
  return (
    <svg className="FigurePage" viewBox="0 0 511.775 511.789">
      <path
        id="Path_8"
        data-name="Path 8"
        className="cls-1"
        d="M5324.178,1648.518V1266.2H4828.752v492.881h384.288Z"
        transform="translate(-4820.277 -1255.95)"
      />
      <path
        id="Path_6"
        data-name="Path 6"
        className="cls-2"
        d="M5232.816,1457.653H4755.831a17.4,17.4,0,0,0-17.394,17.395v477a17.4,17.4,0,0,0,17.394,17.4h378.663l115.718-115.718V1475.048A17.4,17.4,0,0,0,5232.816,1457.653ZM5139.5,1941.122V1858.8h82.319Zm-384.74,11.991V1473.982H5233.89v368.553H5132.979a9.748,9.748,0,0,0-9.748,9.748v100.83Z"
        transform="translate(-4738.437 -1457.653)"
      />
    </svg>
  )
}
function FigureClip() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="FigureClip"
      viewBox="0 0 101.681 205"
    >
      <path
        id="Path_7"
        data-name="Path 7"
        className="cls-1"
        d="M4851.442,1437.324A33.759,33.759,0,0,1,4884,1462.172h17.839a51.283,51.283,0,0,0-101.681,9.489v115.185c0,21.279,17.572,38.529,39.249,38.529s39.25-17.25,39.25-38.529v-46.234c0-20.751-16.715-37.663-37.648-38.49a9.186,9.186,0,0,0-1.332-.109,8.487,8.487,0,0,0-.864,16.949.81.81,0,0,0,.432.112h.324a21.342,21.342,0,0,1,21.537,21.142v46.629a21.342,21.342,0,0,1-21.537,21.141h-.324a21.341,21.341,0,0,1-21.536-21.141v-44.491h-.017v-71.279A33.753,33.753,0,0,1,4851.442,1437.324Z"
        transform="translate(-4800.156 -1420.375)"
      />
    </svg>
  )
}
export default CardTopic
