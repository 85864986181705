const H2 = (props) => {
  const {
    children,
    textAlign = 'start',
    fontWeight = '500',
    margin = '0',
    fontSize = '2.6rem',
    color = 'var(--black)',
    innerHTML = false,
    width = '100%',
    textTransform = 'none',
    textShadow = 'none',
    ...rest
  } = props

  const textStyle = {
    width: width,
    color: color,
    fontSize: fontSize,
    margin: margin,
    fontWeight: fontWeight,
    textAlign: textAlign,
    textTransform: textTransform,
    textShadow: textShadow,
  }

  return innerHTML ? (
    <h2
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      className="H2"
      style={textStyle}
      {...rest}
    ></h2>
  ) : (
    <h2 className="H2" style={textStyle} {...rest}>
      {children}
    </h2>
  )
}

export default H2
