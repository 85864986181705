import React from 'react'
import './LoadSimulator.css'
import AOS from 'aos'
import { useState, useEffect } from 'react'
import { H2 } from '../../Text'
const Load = () => {
  const [index, setindex] = useState(0)

  useEffect(() => {
    let interval
    interval = setTimeout(function () {
      if (index == 2) {
        setindex(0)
      } else {
        setindex(index + 1)
      }
    }, 5000)
    return function cleanup() {
      clearInterval(interval)
    }
  }, [index])

  AOS.init()
  const message = [
    'Revisa tus mejores logros en el menú de progresos.',
    'Práctica jugando en los diferentes niveles de dificultad.',
    'Practica en el simulador y juega en la aplicación.',
  ]

  return (
    <div className="loading show">
      <div className="spin"></div>
      <H2 textAlign="center" data-aos="fade-right" data-aos-delay="200">
        {message[index]}
      </H2>
    </div>
  )
}

export default Load
