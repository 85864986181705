import ReactPlayer from 'react-player'
const VideoPlayer = (props) => {
  const { loop = false, playing, volume = 1 } = props
  function handlePlay() {
    if (props.handlePlay) {
      props.handlePlay()
    }
  }
  return (
    <ReactPlayer
      className={`reactPlayerC ${props.className}`}
      url={props.url}
      width={props.width}
      height={props.height}
      controls={props.controls ? true : false}
      muted={props.muted ? props.muted : false}
      playing={playing}
      onPlay={handlePlay}
      loop={loop}
      volume={volume}
    />
  )
}

export default VideoPlayer
