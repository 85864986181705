import {
  MultipleChoice,
  AssociativeSum,
  Sum,
  AddSumaTab,
  TableSum,
  DecomposeNumer,
  SubtractDefault,
  SumByDecomposition,
  MultiplationByFive,
  Adding5Numbers,
  GameSquared,
  MultiplicationBy9,
  DivisionTraditional,
  DivisionVédico,
  AspaSimple,
  RenderGame,
} from '../../../../../components/Games'
const GamesControl = (props) => {
  const { control, ...rest } = props
  switch (control) {
    case 'CountMultiples':
      return <RenderGame {...rest} />
    case 'ConmutativeSum':
    case 'AssociativeSum':
      return <RenderGame {...rest} property="listRandom" operation="+" />
    case 'addSumaTab':
      return <RenderGame {...rest} property="listBaseNumber" operation="+" />
    case 'DescomposeNumber':
      return <RenderGame {...rest} property="textDescompose" operation="+" />
    case 'SumByDecomposition':
      return <RenderGame {...rest} property="listRandom" operation="+" />

    //reemplazado
    case 'ConmutativeMultiplication':
      return <Sum {...rest} operation="x" />
    case 'subtractByDecomposition':
      return <Sum {...rest} operation="-" />
    case 'NumberComplements':
    case 'FoldNumbers':
      return <Sum {...rest} operation={null} />
    case 'AssociativeMultiplication':
      return <AssociativeSum {...rest} operation="x" />
    case 'DivisionByVédicoMethod':
      return <DivisionVédico {...rest} />
    case 'DivisionByTraditionalMethod':
      return <DivisionTraditional {...rest} />
    case 'AspaSimple':
      return <AspaSimple {...rest} />
    case 'MultiplicationByEleven':
      return <AspaSimple {...rest} multiplier="11" />
    case 'Square2-DigitNumber':
    case 'Squared':
    case 'SquaredFive':
    case 'RaiseAnySquareNumber':
      return <GameSquared {...rest} squared="2" />
    case 'AddConsecutiveNumbers':
      return <AssociativeSum {...rest} operation="+" />
    case 'MultiplicationTable':
      return <AddSumaTab {...rest} operation="x" />
    case 'TableSum':
      return <TableSum {...rest} />
    case 'TableSubtract':
    case 'SubtractAdding':
    case 'SubtractByApproximation':
      return <SubtractDefault {...rest} />
    case 'MultiplicationDecomposition':
    case 'MultiplyNumberByAnotherThatEndsIn0':
      return <SubtractDefault {...rest} operation="x" />
    case 'MultiplationByFive':
      return <MultiplationByFive {...rest} operation="x" principal="5" />
    case 'MultiplicationBy9´s':
      return <MultiplicationBy9 {...rest} operation="x" principal="9" />
    case 'MultiplyNumberBy10':
      return <MultiplationByFive {...rest} operation="x" />
    case 'Adding5NumbersIn3Seconds':
      return <Adding5Numbers {...rest} />
    case 'SumByApproximation':
      return <AssociativeSum {...rest} operation="" fontWeight="500" />
    default:
      return <AssociativeSum {...rest} operation="" />
  }
}

export default GamesControl
