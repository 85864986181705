import { Title, H2, Section } from '../../components/Text'
import { makeid } from '../../utils/Generate'
import { validateArray, validateStatus } from '../../utils/Validation'
import { useAxios } from '../../hook'
import { postAction } from '../../services/actions/Action'
import { postAction as post } from '../../services/actions/AuthAction'
import { CircleProgress, LineProgress, InputDefault } from './Payment'
import { useState, useEffect } from 'react'
import { startSession, changeModal, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import { domainURL } from '../../config.json'
import { ButtonFloating } from '../../components/common'
import FormChildren from './FormChildren'
import { UserData, PayMethods } from './Payment'
const PaymentRegister = (props) => {
  const {
    startSession,
    sesionUser,
    changeModal,
    openSnackbar,
    match: { params },
  } = props
  const [view, setview] = useState(0)
  const [PayData, setPayData] = useState({
    user: {
      name: sesionUser.user.firstName || '',
      lastname: sesionUser.user.lastName || '',
      email: sesionUser.user.email || '',
      username: '',
      paisIndex: '',
      estado_lbl: '',
      password: '',
      phone: '',
      Country: null,
      Amount: '350.00',
      Currency: 'BOB',
      Order_id: makeid(8),
      PriceId: 1,
      childId: null,
    },
    payMethods: 1,
  })
  async function setCusomView(number) {
    if (number === 1) {
      const {
        name,
        lastname,
        email,
        estado_lbl,
        phone,
        Amount,
        Country,
        Currency,
        Order_id,
        PriceId,
        childId,
      } = PayData.user
      await postAction(`/auth/child/${childId}/payment`, {
        Name: name,
        LastName: lastname,
        Email: email,
        Country: Country,
        City: estado_lbl,
        Telephone: phone,
        PaymentMethod:
          PayData.payMethods === 1 ? 'onlinePayment' : 'contactSeller',
        Amount: Amount,
        Currency: Currency,
        PriceId: PriceId,
        Order_id: Order_id,
        paymentPlanId: params.pid,
      }).then((response) => {
        if (validateStatus(response.status)) {
          props.openSnackbar('Pedido realizado con exito', true, true)
          setview(3)
          if (PayData.payMethods === 1) {
            document.getElementById('id-page').submit()
          }
        } else {
          props.openSnackbar('Error al realizar el pedido', true, true)
        }
      })
    } else {
      setview(1)
    }
  }
  const { response } = useAxios({
    method: 'GET',
    url: `/paymentPlan/${params.pid}`,
    // url: '/Price/main',
  })
  useEffect(() => {
    setPayData((a) => ({
      ...a,
      user: {
        ...a.user,
        PriceId: response?.id ? response.id : 1,
        Amount: response?.amount ? `${response.amount}.00` : '350.00',
        Currency: response?.currency ? response.currency : 'BOB',
      },
    }))
  }, [response])
  return (
    <>
      <script>
        {window.gtag('event', 'conversion', {
          send_to: 'AW-320545567/eLscCJimlrADEJ_G7JgB',
        })}
      </script>
      <div className="Payment">
        <div className="Payment__container">
          <div className="Payment__main">
            <div className="Payment__header">
              <img
                src="https://res.cloudinary.com/dsgaf5zoj/image/upload/c_scale,f_auto,h_150/v1628866633/assets/nhdv4hcv63cksskvfloy.png"
                alt=""
              />
              <div className="Payment__title">
                <Title fontSize="4rem" fontWeight="900">
                  <span style={{ color: '#8c7197' }}>Comprar curso </span>
                  <span style={{ color: '#6a3081' }}>de matemáticas</span>
                </Title>
              </div>
            </div>
            <br />
            <div className="Payment__section">
              <div className="Payment__ContainerPrice">
                <H2
                  color="#6a3081"
                  fontSize="2.5rem"
                  fontWeight="600"
                  width="auto"
                >
                  Formulario de compra
                </H2>
                <div className="Payment__price">
                  <span>70.00 USD</span>
                  <p>50.00 USD</p>
                </div>
              </div>
              <br />
              <div className="Payment__nav">
                <LineProgress progress={view * 35} />
                <div className="Payment__circleContent">
                  <CircleProgress
                    background={view >= 0 ? '#6a3081' : '#d0c7d4'}
                  >
                    1
                  </CircleProgress>
                  <CircleProgress
                    background={view >= 1 ? '#6a3081' : '#d0c7d4'}
                  >
                    2
                  </CircleProgress>
                  <CircleProgress
                    background={view >= 2 ? '#6a3081' : '#d0c7d4'}
                  >
                    3
                  </CircleProgress>
                  <CircleProgress
                    background={view >= 3 ? '#6a3081' : '#d0c7d4'}
                  >
                    4
                  </CircleProgress>
                </div>
              </div>
              <br />
              <H2
                textAlign="center"
                color="#8c7197"
                fontSize="2rem"
                fontWeight="600"
              >
                {
                  [
                    'DATOS DEL TITULAR',
                    'DATOS DEL NIÑO',
                    'MÉTODOS DE PAGO',
                    'REALIZAR EL PAGO',
                  ][view]
                }
              </H2>
              <div style={{ display: 'none' }}>
                <UserData
                  {...props}
                  startSession={startSession}
                  changeModal={changeModal}
                  view={1}
                  setview={setview}
                  PayData={PayData}
                  setPayData={setPayData}
                  pid={params.pid}
                />
              </div>
              {view === 0 && (
                <UserRegister
                  {...props}
                  startSession={startSession}
                  openSnackbar={openSnackbar}
                  changeModal={changeModal}
                  view={view}
                  setview={setview}
                  PayData={PayData}
                  setPayData={setPayData}
                  uid={params.uid}
                />
              )}
              {view === 1 && (
                <FormChildren
                  {...props}
                  setview={setview}
                  setPayData={setPayData}
                />
              )}
              {view === 2 && (
                <PayMethods
                  setview={setCusomView}
                  PayData={PayData}
                  setPayData={setPayData}
                />
              )}
              {view === 3 && (
                <>
                  {PayData.payMethods === 1 ? (
                    <>
                      <iframe id="form-page" name="form-page"></iframe>
                      <div className="PaymentMethods__btn">
                        <button
                          type="button"
                          onClick={() => setview(2)}
                          className="UserData__btn"
                          style={{
                            background: 'white',
                            color: '#6a3081',
                            border: '1px solid #6a3081',
                          }}
                        >
                          Volver al método de pago
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="Payment__MInfo">
                      <Section
                        color="#2f133a"
                        fontSize="1.8rem"
                        fontWeight="400"
                      >
                        Su cuenta de usuario ha sido creada, por favor verifique
                        el correo electrónico enviado con sus datos de usuario y
                        contraseña.
                      </Section>
                      <br />
                      <H2 color="#6a3081" fontSize="2.2rem" fontWeight="500">
                        Contáctate con uno de nuestros asesores de ventas
                      </H2>
                      <br />
                      <div className="Payment__cellPhones">
                        <Section
                          textAlign="center"
                          color="#2f133a"
                          fontSize="1.8rem"
                          fontWeight="400"
                        >
                          +591 67038903
                        </Section>
                        <Section
                          textAlign="center"
                          color="#2f133a"
                          fontSize="1.8rem"
                          fontWeight="400"
                        >
                          +591 62397333
                        </Section>
                        <Section
                          textAlign="center"
                          color="#2f133a"
                          fontSize="1.8rem"
                          fontWeight="400"
                        >
                          +591 77285061
                        </Section>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <br />
          </div>
        </div>
        <ButtonFloating />
      </div>
    </>
  )
}

const UserRegister = (props) => {
  const {
    setview,
    view,
    PayData,
    setPayData,
    changeModal,
    history,
    uid,
  } = props
  const [load, setload] = useState(false)
  const { response: respCountry, loading: loadCountry } = useAxios({
    method: 'GET',
    url: `/Country`,
  })
  const { response: respCities, loading: loadCities, reload } = useAxios({
    method: 'GET',
    url: PayData.user.paisIndex
      ? `/Country/${respCountry[PayData.user.paisIndex].code}/cities`
      : null,
  })
  useEffect(() => {
    if (PayData.user.paisIndex) {
      reload()
    }
  }, [PayData.user.paisIndex])
  function handleOnChange(e) {
    const { name, value } = e.target
    setPayData((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }))
  }
  function handleDisabled() {
    let aux = false
    if (PayData?.user) {
      for (let key in PayData.user) {
        if (PayData.user[key] === '') {
          aux = true
        }
      }
    }
    return aux
  }

  function handleOnSubmit() {
    changeModal(
      true,
      '¿Estás seguro que tus datos son correctos?',
      'Una vez registrados tus datos ya no podrás volver para cambiarlos.',
      'Siguiente paso',
      () => onSubmit(),
    )
  }
  function onSubmit() {
    setload(true)
    const {
      email,
      username,
      paisIndex,
      estado_lbl,
      phone,
      password,
    } = PayData.user
    setPayData((a) => ({
      ...a,
      user: {
        ...a.user,
        Country: respCountry[paisIndex].code,
      },
    }))
    if (!window.localStorage.getItem('id')) {
      post('/auth/register', {
        username: username,
        email: email,
        telephone: phone,
        password: password,
        password_confirmation: password,
        Country: respCountry[paisIndex].code,
        City: estado_lbl,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          props.openSnackbar('Registro Exitoso', true, true)
          const { userToReturn, token, rolsAssignedToList, testData } = res.data
          window.localStorage.setItem('id', userToReturn.id)
          window.localStorage.setItem('token_seguridad', token)
          props.startSession(
            userToReturn,
            rolsAssignedToList || [],
            [],
            true,
            testData,
          )
          setview(1)
        } else {
          const wrongMessage = (errorName) => {
            switch (errorName) {
              case 'username_exists':
                return `El usuario "${username}" ya existe`
              case 'email_exists':
                return `El correo "${email}" ya esta siendo usado`
              default:
                return errorName
            }
          }
          changeModal(
            true,
            'Tu cuenta ya existe',
            'Debes iniciar sesión para poder continuar con el pago',
            'Iniciar sesión',
            () => history.push('/auth/login'),
          )
          props.openSnackbar(
            res.data ? wrongMessage(res.data) : 'Error al guardar',
            true,
          )
        }
      })
    } else {
      setview(1)
    }
  }

  return (
    <div className="UserData" style={{ display: view === 0 ? '' : 'none' }}>
      <form>
        <input
          type="hidden"
          name="_"
          defaultValue="6n254pj3q89mth8c81f5u9yo9as2xbdglw46fvz7k908er5i0"
        />
        <input
          type="hidden"
          name="__"
          defaultValue="ae8jg10ayld1pb7fcf7an1tm21b5burz943992hsq6vkwo6ix"
        />
        <input
          type="hidden"
          name="postURL"
          defaultValue={`${domainURL}/order-result/${uid}`}
        />
        <input type="hidden" name="amt2" defaultValue={PayData.user.Amount} />
        <input
          type="hidden"
          name="currency"
          defaultValue={PayData.user.Currency}
        />
        <input
          type="hidden"
          name="invno"
          defaultValue={PayData.user.Order_id}
        />
        <InputDefault
          label="Nombres *"
          type="text"
          name="name"
          onChange={handleOnChange}
          value={PayData.user.name}
        />
        <InputDefault
          label="Apellidos *"
          type="text"
          name="lastname"
          onChange={handleOnChange}
          value={PayData.user.lastname}
        />
        <InputDefault
          label="Correo electrónico *"
          type="email"
          name="email"
          onChange={handleOnChange}
          value={PayData.user.email}
        />
        <InputDefault
          label="Usuario *"
          type="text"
          name="username"
          onChange={handleOnChange}
          value={PayData.user.username}
        />
        <InputDefault
          label="Contraseña *"
          type="password"
          name="password"
          onChange={handleOnChange}
          value={PayData.user.password}
        />
        <label htmlFor="paisIndex">País *</label>
        <select
          onChange={handleOnChange}
          value={PayData.user.paisIndex}
          name="paisIndex"
        >
          <option defaultValue="" value="">
            País:
          </option>
          {loadCountry
            ? null
            : validateArray(respCountry)
            ? respCountry.map((item, index) => (
                <option key={index} value={index}>
                  {item.name}
                </option>
              ))
            : null}
        </select>
        <input
          type="hidden"
          name="pais"
          defaultValue={
            respCountry?.length > 0 && PayData.user.paisIndex
              ? respCountry[PayData.user.paisIndex].code
              : ''
          }
        />
        <label htmlFor="estado_lbl">Departamento *</label>
        <select
          onChange={handleOnChange}
          value={PayData.user.estado_lbl}
          name="estado_lbl"
        >
          <option defaultValue="">Departamento:</option>
          {loadCities
            ? null
            : respCities && validateArray(respCities)
            ? respCities.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))
            : null}
        </select>
        <label htmlFor="phone">Teléfono o celular *</label>
        <div style={{ display: 'flex', gap: '5px' }}>
          <span
            style={{
              background: '#b3b3b3',
              padding: '0 10px',
              height: '28px',
              textAlign: 'center',
              lineHeight: '28px',
              borderRadius: '5px',
              fontSize: '1.6rem',
            }}
          >
            {respCountry?.length > 0 && PayData.user.paisIndex
              ? '+' + respCountry[PayData.user.paisIndex].phoneCode
              : null}
          </span>
          <input
            style={{ width: '100%' }}
            type="text"
            name="phone"
            onChange={handleOnChange}
            value={PayData.user.phone}
          />
        </div>
        {load ? null : (
          <button
            type="button"
            onClick={handleOnSubmit}
            className="UserData__btn"
            disabled={handleDisabled() ? true : false}
          >
            Siguiente paso
          </button>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  startSession,
  changeModal,
  openSnackbar,
})(PaymentRegister)
