import { DefaultButton } from '../../components/buttons'
import { VideoPlayer } from '../../components/common'
import { H4, Title } from '../../components/Text'

export const Tutorial = (props) => {
  const {
    title,
    subTitle,
    textBtn,
    colorBtn = 'var(--green)',
    changeModal,
    videoModalTitle = 'Tutorial',
    videoUrl = 'https://vimeo.com/658998238',
  } = props
  return (
    <div className="Auth__tutorial">
      <H4 color={colorBtn} fontSize="1.6rem" textAlign="center">
        {title}
      </H4>
      {subTitle && (
        <H4 color={colorBtn} textAlign="center" fontSize="1.6rem">
          {subTitle}
        </H4>
      )}
      <DefaultButton
        width="auto"
        background={colorBtn}
        padding="0.6rem 3rem"
        onClick={() =>
          changeModal(
            true,
            <TutorialVideoLoad
              videoModalTitle={videoModalTitle}
              videoUrl={videoUrl}
            />,
            null,
          )
        }
      >
        {textBtn}
      </DefaultButton>
    </div>
  )
}

export const TutorialVideoLoad = ({ videoModalTitle, videoUrl }) => {
  return (
    <div className="SimulationMenu__videoLoad">
      <Title
        margin="10px 0"
        fontSize="4rem"
        fontWeight="900"
        textAlign="center"
        color="var(--green)"
      >
        {videoModalTitle}
      </Title>
      <VideoPlayer url={videoUrl} controls={true} width="100%" /><div className="TopicContent__camera"></div>
                  <div className="TopicContent__btn"></div>

      
    </div>
  )
}
