import './LessoContainer.css'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Title, Section } from '../../components/Text/index'

const LessoContainer = ({
  index,
  background,
  dataRequest,
  handleModal,
  backgroundBtn,
  textBtn = 'Ver Contenido',
}) => {
  const [description, setdescription] = useState(true)
  return dataRequest ? (
    <div className="LessoContainer">
      <div
        style={{
          background: background,
        }}
        className="LessoContainer__cardPink"
      >
        <div className="LessoContainer__ringed">
          <Ringed />
          <Ringed />
          <Ringed />
          <Ringed />
          <Ringed />
        </div>
        <div className="LessoContainer__container">
          <LineDot />
          <Title
            margin="15px 0"
            color="white"
            textAlign="center"
            fontSize="3rem"
            textTransform="uppercase"
            fontWeight="700"
          >
            {dataRequest.name}
          </Title>
          <div className="LessoContainer__main">
            <div className="LessoContainer__img">
              <Link
                onClick={
                  dataRequest.statusPayment === 'empty'
                    ? () =>
                        handleModal(
                          index === 0
                            ? 'Para ver esta lección, debes ver el tutorial.'
                            : 'Si quieres seguir con esta aventura debes adquirir el curso completo.',
                          true,
                          index,
                        )
                    : () =>
                        handleModal(
                          'Debes terminar las lecciones habilitadas para pasar a la siguiente.',
                          false,
                        )
                }
                to={
                  dataRequest.status === 'locked' ||
                  dataRequest.status === 'pending'
                    ? '#'
                    : `/lessons/${dataRequest.lesssonId}/${dataRequest.name}/topics`
                }
              >
                <img src={dataRequest.imageUrl} alt="Portada del tema" />
              </Link>
            </div>
            <div className="LessoContainer__text">
              <Section
                width="100%"
                fontWeight="400"
                color="white"
                fontSize="2.0rem"
              >
                {description
                  ? dataRequest.description.length > 300
                    ? dataRequest.description.substring(0, 300) + '...'
                    : dataRequest.description
                  : dataRequest.description + '   '}
                {dataRequest.description.length > 300 && (
                  <span
                    style={{ color: '#6a3081', cursor: 'pointer' }}
                    onClick={() => {
                      setdescription(!description)
                    }}
                  >
                    {description ? ' ver más' : ' ver menos'}
                  </span>
                )}
              </Section>
              <Link
                style={{
                  background: backgroundBtn,
                }}
                className="LessoContainer__button"
                onClick={
                  dataRequest.statusPayment === 'empty'
                    ? () =>
                        handleModal(
                          index === 0
                            ? 'Para ver esta lección, debes ver el tutorial.'
                            : 'Si quieres seguir con esta aventura debes adquirir el curso completo.',
                          true,
                          index,
                        )
                    : () =>
                        handleModal(
                          'Debes terminar las lecciones habilitadas para pasar a la siguiente.',
                          false,
                        )
                }
                style={{
                  background: backgroundBtn
                    ? backgroundBtn
                    : dataRequest.status === 'locked' ||
                      dataRequest.status === 'pending'
                    ? dataRequest.color === 'green'
                      ? ''
                      : 'gray'
                    : '',
                }}
                to={
                  dataRequest.status === 'locked' ||
                  dataRequest.status === 'pending'
                    ? '#'
                    : `/lessons/${dataRequest.lesssonId}/${dataRequest.name}/topics`
                }
              >
                {textBtn}{' '}
                <i
                  className={`fas fa-${
                    dataRequest.status === 'locked' ||
                    dataRequest.status === 'pending'
                      ? dataRequest.color === 'green'
                        ? 'lock-open'
                        : 'lock'
                      : 'lock-open'
                  } `}
                ></i>
              </Link>
            </div>
          </div>
          <LineDot
            flexDirection="row-reverse"
            justifyContent="flex-end"
            margin="0 25px 10px"
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}
function LineDot(props) {
  const {
    justifyContent = 'center',
    margin = '10px 0 25px',
    flexDirection = 'row',
  } = props
  return (
    <div
      style={{
        margin: margin,
        display: 'flex',
        flexDirection: flexDirection,
        gap: '5px',
        alignItems: 'center',
        justifyContent: justifyContent,
      }}
    >
      <div className="LineDot__line"></div>
      <div className="LineDot__dot"></div>
    </div>
  )
}
function Ringed() {
  return (
    <div className="Ringed">
      <div className="Ringed__lines">
        <div className="Ringed__line"></div>
        <div className="Ringed__line"></div>
      </div>
      <div className="Ringed__square"></div>
    </div>
  )
}
export default LessoContainer
