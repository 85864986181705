import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import './style/Forms.css'
import { useState } from 'react'
import * as Yup from 'yup'
import { postAction } from '../../services/actions/AuthAction'
import { Link } from 'react-router-dom'
import './style/Log_in.css'
import { GlobalButton } from '../buttons'
import Loading from '../animation/Loading'
import { validateStatus } from '../../utils/Validation'
import { H3, H2 } from '../Text'
import { connect } from 'react-redux'
import { startSession, openSnackbar } from '../../store/actions'
const Recover_in = (props) => {
  const { location } = props
  const [load, setload] = useState(true)
  const initialValues = {
    UsernameOrEmail: '',
  }
  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required('Campo Obligatorio'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/recoveryAccount', values).then((res) => {
        setload(true)
        if (validateStatus(res.status)) {
          props.openSnackbar('Mensaje enviado, revisa tu correo', true, true)
        } else {
          props.openSnackbar('El usuario no existe', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    
    <div className="log_in">
      <H2 fontSize="2.5rem" margin="0 0 10px" color="var(--green)" textAlign= 'center'>
        ¿Olvidaste tu contraseña?
      </H2>
      <H3 fontSize="2rem" margin="0 0 20px 0">
        Ingresa tu correo electrónico y sigue las instrucciones ques se envian
        por email.
      </H3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Usuario o correo electrónico:"
                name="UsernameOrEmail"

              />
              {load ? (
                <center>
                  <GlobalButton type="submit">RECUPERAR CONTRASEÑA</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <span style={ { fontSize: '2.5rem' }} className="forgotPassword">
        Volver al{' '}
        <Link
          to={{
            pathname: '/auth/login',
            state: {
              from: location.state ? location.state.from : '/',
            },
          }}
        >
          Inicio de sesión
        </Link>
      </span>
    </div>
  )
}
export default connect(null, { startSession, openSnackbar })(Recover_in)
