import React from "react";
import "./Load.css";
import AOS from "aos";

const Load = () => {
    AOS.init();
    return (
        <div className="loading show">
          
        <div className="spin">
      

        </div>
        </div>
    );
};

export default Load;
