import { createGlobalStyle } from "styled-components";

const ThemeStyle = createGlobalStyle`
    :root {
        --color_link: ${(props) => props.color.color_link};
        --color_hover: ${(props) => props.color.color_hover};
        --color_purple: ${(props) => props.color.color_purple};

        --color_button_hover: ${(props) => props.color.color_button_hover};
        --color_input: ${(props) => props.color.color_input};
        --color_input_hover: ${(props) => props.color.color_input_hover};

        --color_text: ${(props) => props.color.color_text};

        --purple_logo: ${(props) => props.color.purple_logo};
        --orange_lite: ${(props) => props.color.orange_lite};
        --purple2_logo: ${(props) => props.color.purple2_logo};

        --orange: ${(props) => props.color.orange};
        --purple: ${(props) => props.color.purple};
        --green: ${(props) => props.color.green};
        --white :${(props) => props.color.white};
        --yellow: ${(props) => props.color.yellow};
        --gray: ${(props) => props.color.gray};
    }
`;
export default ThemeStyle;
