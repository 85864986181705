import { GlobalButton } from "../buttons";
import Loading from "../animation/Loading";
import { useState } from "react";
import { Formik, Form } from "formik";
import { FormikControl } from "../inputs";
import * as Yup from "yup";
import "./style/Forms.css";
import { postAction } from "../../services/actions/AuthAction";
import { connect } from "react-redux";
import { startSession, openSnackbar } from "../../store/actions";
import { validateStatus } from "../../utils/Validation";

const Register_in = (props) => {
  const {
    match: { params },
  } = props;
  const [load, setload] = useState(true);
  const [error, setError] = useState([]);
  const initialValues = {
    username: "",
    email: "",
    telephone: "",
    password: "",
    password_confirmation: "",
  };
  const validationSchema = Yup.object({
    // username: Yup.string().required('Requiere un user name'),
    email: Yup.string()
      .email("Ingrese un email valido")
      .required("Este campo es requerido"),
    telephone: Yup.number().min(8, "error de telefono").optional(),
    password: Yup.string()
      .required("Ingrese una contraseña")
      .min(4, "Mínimo 4 caracteres"),
    password_confirmation: Yup.string()
      .required("Este campo es requerido")
      .min(4, "Mínimo 4 caracteres")
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const save = (res, values) => {
    setload(true);
    if (validateStatus(res.status)) {
      if (params.uid) {
        props.openSnackbar("Contacto registrado", true, true);
        props.history.push("/seller");
      } else {
        props.openSnackbar("Registro Exitoso", true, true);
        const { userToReturn, token, rolsAssignedToList, testData } = res.data;
        window.localStorage.setItem("id", userToReturn.id);
        window.localStorage.setItem("token_seguridad", token);
        props.startSession(
          userToReturn,
          rolsAssignedToList || [],
          [],
          true,
          testData
        );
        if (props.location.state) {
          props.history.push(props.location.state.from.pathname);
        } else if (res.data.firstSession) {
          props.history.push("/welcome");
        } else {
          props.history.push("/management");
        }
      }
    } else {
      setError([res.data]);
      const wrongMessage = (errorName) => {
        switch (errorName) {
          case "username_exists":
            return `El usuario "${values["username"]}" ya existe`;
          case "email_exists":
            return `El correo "${values["email"]}" ya esta siendo usado`;
          default:
            return errorName;
        }
      };
      props.openSnackbar(
        res.data ? wrongMessage(res.data) : "Error al guardar",
        true
      );
    }
  };

  const onSubmit = async (values) => {
    try {
      setload(false);
      await postAction(
        "/auth/register",
        params.uid
          ? {
              ...values,
              SellerId: params.uid,
            }
          : {
              ...values,
              username: values.email,
            }
      ).then((response) => {
        save(response, values);
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="email"
                label="Email"
                name="email"
                listError={error}
                values={formik.values.email}
                error={["email_exists"]}
              />
              {/* <FormikControl
                control="input"
                type="text"
                label="Usuario"
                name="username"
                listError={error}
                values={formik.values.username}
                error={['username_exists']}
              /> */}
              {/* <FormikControl
                control="input"
                type="telephone"
                label="Teléfono o celular"
                name="telephone"
              /> */}
              <FormikControl
                control="input"
                type="password"
                label="Contraseña"
                name="password"
              />
              <FormikControl
                control="input"
                type="password"
                label="Confirmar Contraseña"
                name="password_confirmation"
              />
              {load ? (
                <center>
                  <GlobalButton disabled={!formik.isValid} type="submit">
                    Registrar
                  </GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(null, { startSession, openSnackbar })(Register_in);
