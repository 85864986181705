import { subtractTime } from '../utils/ConvertDate'
import { useEffect, useState } from 'react'
const TimerHook = ({ timeLimit, onSaving }) => {
  const [time, settime] = useState(timeLimit)
  const [pause, setpause] = useState(false)
  function reload() {
    settime(timeLimit)
  }
  function onPause(status) {
    setpause(status)
  }
  useEffect(() => {
    let interval
    interval = setTimeout(function () {
      if (time === '00:00:00.000') {
        onSaving()
      } else {
        settime(pause ? time : subtractTime(time))
      }
    }, 100)
    return function cleanup() {
      clearInterval(interval)
    }
  }, [time, pause])
  return { time, reload, onPause }
}

export default TimerHook
