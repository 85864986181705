import { validateArray } from '../../utils/Validation'
import { useState } from 'react'
import { H2 } from '../Text'
import { DefaultButton } from '../buttons'
import { useEffect } from 'react'
const AspaSimple = (props) => {
  const {
    optionList,
    aciertos,
    fallos,
    principal,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  //   principal: principal,
  // })
  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])
  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        ...a,
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      principal={indexGame.principal}
      handleIndex={handleIndex}
      {...props}
    />
  )
}
function OptionGame(props) {
  const {
    dataGame,
    handleWrong,
    handleSuccess,
    fallos,
    aciertos,
    multiplier,
  } = props
  const [response, setresponse] = useState('')
  function handleAnswer(e) {
    const { value } = e.target
    setresponse(value)
  }
  function handleTotal() {
    if (dataGame.rightAnswer === response.split('').reverse().join('')) {
      handleSuccess(dataGame, response.split('').reverse().join(''))
    } else {
      handleWrong(dataGame, response.split('').reverse().join(''))
    }
  }
  useEffect(() => {
    setresponse('')
  }, [fallos, aciertos])

  function onKeyPressed(e) {
    if (e.key === 'Enter') {
      handleTotal()
    }
  }
  return (
    <div onKeyDown={onKeyPressed} tabIndex={0}>
      <div className="AspaSimple__multiplication">
        <H2 textAlign="end" fontWeight="900" color="#8c7197" fontSize="8rem">
          {validateArray(dataGame.random) ? (
            <>
              {dataGame.random[0]}
              <br />x {dataGame.random[1] ? dataGame.random[1] : multiplier}
            </>
          ) : (
            ''
          )}
        </H2>
        <div className="OptionGame__input" autofocus>
          <input
            onChange={handleAnswer}
            autoFocus
            className="OptionGame__input__input__aspa"
            style={{
              width: '100%',
              direction: 'rtl',
              unicodeBidi: 'bidi-override',
              fontSize: '5rem',
            }}
            type="text"
            name="answer1"
            id="answer1"
            value={response}
            autocomplete="off"
          />
        </div>
      </div>
      <br />
      <center>
        <DefaultButton
          onClick={handleTotal}
          width="auto"
          padding="5px 20px"
          fontSize="2.5rem"
        >
          Aceptar
        </DefaultButton>
      </center>
    </div>
  )
}

export default AspaSimple
