import "./Home.css";
import "./HomeHeader.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Section, H2, H3 } from "../../components/Text";
import { DefaultButton } from "../../components/buttons";
import { ModalDefault } from "../../components/modal";
import { VideoPlayer, ButtonFloating } from "../../components/common";
import { Link } from "react-router-dom";
import { changeModal, openSnackbar } from "../../store/actions";
import FormContact from "../../containers/home/FormContact";
import { validateArray } from "../../utils/Validation";
import { connect } from "react-redux";
import { useState } from "react";

const Home = (props) => {
  AOS.init();
  const {
    changeModal,
    openSnackbar,
    // modalDefault = true,
    sesionUser: { rols },
    playVideo = true,
  } = props;

  const [modal, setmodal] = useState(true);
  // function handleCustomModal() {
  //   changeModal(true, <VideoModal />, null)
  // }

  function scrollForm() {
    document
      .getElementById("formContant")
      .scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <div className="Home">
      {/* {modal && (
        <ModalDefault onClose={() => setmodal(!modal)} closeOutside>
          <FormContact openSnackbar={openSnackbar} changeModal={changeModal} />
        </ModalDefault>
      )} */}
      <div className="Home__header">
        <div style={{ textAlign: "center", padding: "10px" }}>
          <img
            style={{ height: "200px", textAlign: "center" }}
            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_504_ftxjeb_mwbprc.png"
            alt="logotipo"
          />
        </div>
        {/* <div className="">
          
          <H2
            color="white"
            fontSize="3.5rem"
            textAlign="center"
            fontWeight="600"
          >
            ¡Fortalece tu mente con Wonder Math! <br />
          </H2>
          <H2
            color="white"
            fontSize="3.5rem"
            textAlign="center"
            margin="0 0 10px"
          >
            Con Bruno y Sofía, brilla en la Ciudad Maravilla.
          </H2>
        </div> */}
        {/* <div className="Home__headVideo"> */}
        <div className="Home__video">
          <img
            className="Bruno"
            style={{ transform: "translateX(20px)" }}
            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473165/assets/boy_afuera_i1gdyn_zd9bmw.png"
            alt=""
          />
          <div className="Home__description">
            <H3 color="purple">
            <div className="">
          {/* <H3 color="white" fontSize="2rem" textAlign="center" margin="20px 0">
            Para niños a partir de los 7 años
          </H3> */}
          <H2
            color="purple"
            fontSize="3.5rem"
            textAlign="center"
            fontWeight="600"
          >
            ¡Fortalece tu mente con Wonder Math! <br />
          </H2>
          <H2
            color="purple"
            fontSize="3.5rem"
            textAlign="center"
            margin="0 0 10px"
          >
            Con Bruno y Sofía, brilla en la Ciudad Maravilla.
          </H2>
        </div>
              {/* Las matemáticas son una pieza clave para el desarrollo de los más
              pequeños porque les ayuda a formar su personalidad, a ser lógicos
              y a razonar con mayor facilidad. */}
              {/* La agilidad mental y el cálculo mental son capacidades que debemos
              desarrollar desde que somos niños, porque nos ayudan a formar
              nuestra personalidad, a ser lógicos y a razonar con mayor
              facilidad. */}
              
            </H3>
          </div>
          {/* <VideoPlayer
            url="https://vimeo.com/672515677"
            controls={true}
            playing={playVideo}
          /> */}
          <img
            style={{ transform: "translateX(-4Apx)" }}
            className="Sofia"
            src="https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/girl_afuera_pkzttp_ml0g8u.png"
            alt=""
          />
        </div>
        <div className="Home__arrow">
          <H3
            onClick={scrollForm}
            color="white"
            fontSize="2rem"
            textAlign="center"
            margin="15px 0"
          >
            Pero...¿Cómo podrían aprender de manera muy sencilla? Baja para
            descubrirlo <i className="fas fa-arrow-alt-circle-down"></i>
          </H3>
        </div>
        {validateArray(rols) &&
        validateArray(rols.filter((item) => item.name === "student")) ? null : (
          <div style={{marginTop:"25px"}}>
            {/* <Link onClick={handleCustomModal} className="Home__Hlink" to="#">
              Presentación <i className="fas fa-play-circle"></i>
            </Link> */}
            
            {!window.localStorage.getItem("id")?
                    <div className="Home__Hbtns"> <Link className="Home__Hlink primary" to="/register"> ¡Prueba gratis! </Link>
                    <Link style={{ background: "var(--orange)", color: "white" }} className="Home__Hlink" to="/plans-payment" onClick= {() => {window.localStorage.setItem("pay", "true") }}> 
                    
                      Comprar ahora
                    </Link></div> : null
                    }
        
      



            
          </div>
        )}
        {/* </div> */}
        {/* <div className="">
          <H3 color="white" fontSize="2rem" textAlign="center" margin="20px 0">
            Para niños a partir de los 7 años
          </H3>
          <H2
            color="white"
            fontSize="3.5rem"
            textAlign="center"
            fontWeight="600"
          >
            ¡Aprende matemáticas! <br />
          </H2>
          <H2 color="white" fontSize="3.5rem" textAlign="center">
            Con Bruno y Sofía <br />
            en la Ciudad Maravilla <br />
          </H2>
        </div> */}
        <br />
        <br />
      </div>
      <br />
      <br />
      <div>
        <H2 textAlign="center" fontWeight="800" fontSize="4rem" color="#722a79">
          METODOLOGÍA
        </H2>
      </div>
      <div className="Home__methodology">
        <div>
          <NumberCircle title="1" />
          <MetodologyImage
            title="APRENDE "
            text="CON LECCIONES INTERACTIVAS"
            url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_492_woj7nb_hnlib6.png"
          />
        </div>
        <div>
          <NumberCircle title="2" />
          <MetodologyImage
            title="PRACTICA"
            text="EN SIMULADORES"
            url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_495_qewmov_mr7slm.png"
          />
        </div>
        <div>
          <NumberCircle title="3" />
          <MetodologyImage
            title="REFUERZA "
            text="CON EL LIBRO"
            url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_494_uhpz1h_vp7dvq.png"
          />
        </div>
        <div>
          <NumberCircle title="4" />
          <MetodologyImage
            title="JUEGA"
            text="EN LA APP"
            url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_493_wlcjyv_syapep.png"
          />
        </div>
      </div>
      <H2
        data-aos="fade-right"
        data-aos-delay="600"
        textAlign="center"
        fontWeight="600"
        fontSize="4rem"
        color="#722a79"
      >
        BENEFICIOS
      </H2>
      <div className="Home__benefits">
        <Benefit
          dataAos="fade-up"
          dataAosDelay="200"
          title="RAZONAMIENTO LÓGICO"
          // text="Tus hij@s desarrollarán esta habilidad para resolver problemas de manera correcta, que les ayudará en sus estudios y en las distintas actividades que realicen en el día a día."
          text="Desarrollarás esta habilidad para resolver problemas de manera correcta, te ayudará en tus estudios y en las distintas actividades que realizas en el día a día."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Grupo_487_jzreuy_k4wagy.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="300"
          title="CÁLCULO MENTAL"
          // text="Tus hij@s serán capaces de realizar cálculos matemáticos mentalmente, que constituye una parte fundamental de las matemáticas."
          text="Serás capaz de realizar cálculos matemáticos mentalmente, sin la ayuda de la calculadora."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Grupo_488_whs0yz_i1y2un.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="400"
          title="AGILIDAD MENTAL"
          // text="Potenciaran su agilidad mental, que les ayudará de sobremanera cuando tengan que rendir exámenes de competencia."
          text="Potenciaras tu agilidad mental, que te ayudará notablemente a la hora que tengas que rendir exámenes."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Grupo_486_dfdkek_ikxdvx.png"
        />
      </div>
      <br />
      <br />
      <br />
      <H2
        data-aos="fade-right"
        data-aos-delay="200"
        textAlign="center"
        fontWeight="800"
        fontSize="4rem"
        color="#722a79"
      >
        CARACTERÍSTICAS
      </H2>
      <div className="Home__benefits">
        <Benefit
          dataAos="fade-up"
          dataAosDelay="300"
          title="FÁCIL"
          text="Una plataforma amigable abierta a todas las edades."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_483_ogersr_rq3roz.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="300"
          title="DIVERTIDO"
          text="Una forma entretenida y alegre de aprender matemáticas."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_484_lefvwg_gv2dgh.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="400"
          title="SEGURO"
          // text="Contamos con seguridad en la protección de la información de tus hij@s."
          text="Contamos con seguridad en la protección de tu información."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_485_goq0q4_wyk9du.png"
        />

        <Benefit
          dataAos="fade-up"
          dataAosDelay="200"
          title="ONLINE"
          // text="Tus hij@s puedes hacer el curso desde la comodidad de tu casa."
          text="Puedes hacer el curso desde la comodidad de tu casa."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Grupo_491_xhh7ro_hrcm3p.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="300"
          title="RESULTADOS EN TIEMPO REAL"
          text="Puedes ver tu progreso del curso en cualquier momento"
          // text="Puedes ver el progreso de tus hij@s en cualquier momento."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Grupo_490_wofyyq_nvlosr.png"
        />
        <Benefit
          dataAos="fade-up"
          dataAosDelay="400"
          title="GAMIFICADO"
          text="Interactuarás progresivamente con los simuladores y el video juego."
          // text="Tus hij@s interactuaran progresivamente con los simuladores y el video juego."
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_493_wlcjyv_syapep.png"
        />
      </div>
      {/* <img
        className="Bruno"
        src="https://res.cloudinary.com/wmcgi/image/upload/v1655473165/assets/boy_afuera_i1gdyn_zd9bmw.png"
        alt=""
      /> */}

      <div id="formContant" className="">
      {!window.localStorage.getItem("id")?
                      <FormContact openSnackbar={openSnackbar} changeModal={changeModal} />: null
                    }
        
      </div>
      {/* <div className="button_4">
        
        <Link to="/register" >
            Prueba Gratis
          </Link>
        </div> */}
      <br />
      <br />
      <br />
      
      
      
      {/* <H2
        data-aos="fade-right"
        data-aos-delay="200"
        textAlign="center"
        fontWeight="800"
        fontSize="4rem"
        color="#722a79"
        margin="0 0 20px"
      >
        REGISTRO DE ESTUDIANTES DE ÚLTIMO SEMESTRE
      </H2> */}
      {/* <center data-aos="fade-right" data-aos-delay="200">
        <img
          data-aos="fade-right"
          data-aos-delay="200"
          className="Home__grafic"
          src="https://res.cloudinary.com/joeldes/image/upload/v1712078587/GRAFICA_WONDERMATH_xazhbl.png"
          // src="https://res.cloudinary.com/wmcgi/image/upload/v1661523019/assets/Mi_proyecto-1_1_fxpix5.png"
          // src="https://res.cloudinary.com/wmcgi/image/upload/v1661522157/assets/Mi_proyecto-1_mkxtl4.png"
          // src="https://res.cloudinary.com/wmcgi/image/upload/v1661516710/assets/image_2022-08-25_22-04-44_v4hejy.png"
          alt=""
        />
      </center> */}
      
      
      
      <br />
      <br />
      <br />
      <div className="Card" data-aos="flip-left" data-aos-delay="200">
        <CardCut
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Grupo_504_ftxjeb_mwbprc.png"
          // url="https://res.cloudinary.com/wmcgi/image/upload/v1655473154/assets/Rect%C3%A1ngulo_397_tvrg9w_bi4run.png"
          // text="Las matemáticas son fundamentales para el desarrollo intelectual de l@s niñ@s, les ayuda a ser lógicos, a razonar ordenadamente y a tener una mente preparada para el pensamiento, la crítica y la abstracción. Tus hij@s podrán ir progresando a su propio ritmo y sin frustraciones con el método WONDERMATH."
          text="WonderMath coadyuva con la formación de los niños y adolescentes mediante el desarrollo de su cálculo mental y agilidad mental, que son habilidades cognitivas fundamentales que tienen una gran importancia.
          Estas habilidades promueven su razonamiento rápido, su pensamiento lógico, su concentración, su autonomía y su confianza, que las aplicaran de manera práctica en su vida diaria y los preparar para el futuro.
          Fomentar y practicar estas habilidades desde temprana edad es beneficioso para su desarrollo intelectual y académico."
        />
      </div>
      <br />
      <H2
        data-aos="fade-right"
        data-aos-delay="200"
        textAlign="center"
        fontWeight="600"
        fontSize="4rem"
        color="#722a79"
      >
        AMBIENTE DEL JUEGO
      </H2>
      <br />
      <H2
        data-aos="fade-right"
        data-aos-delay="600"
        textAlign="center"
        fontSize="2.5rem"
        color="black"
        fontWeight="200"
      >
        Junto a Bruno y Sofía conoce los lugares más emblemáticos de la ciudad
        Maravilla de La Paz
      </H2>
      <div className="Wonderful__places">
        <Places
          dataAos="flip-left"
          dataAosDelay="200"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473162/assets/lugares-02_mwjti9_jd2zxq.png"
          title="Mirador Killi Killi"
        />
        <Places
          dataAos="flip-left"
          dataAosDelay="300"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473162/assets/lugares_Mesa_de_trabajo_1_s2leff_txtrdx.png"
          title="Valle de la Luna"
        />
        <Places
          dataAos="flip-left"
          dataAosDelay="400"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473157/assets/Sin_t%C3%ADtulo-1-04_vytxpr_gz9cxh.png"
          title="Mercado de las Brujas"
        />

        <Places
          dataAos="flip-left"
          dataAosDelay="500"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473164/assets/5-05_iduou2_p2nqqa.png"
          title="Teleferico Rojo"
        />

        <Places
          dataAos="flip-left"
          dataAosDelay="600"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473152/assets/lugares-03_qyrzv6_n07btu.png"
          title="Muela del Diablo"
        />
      </div>
      <br />
      <H2
        data-aos="fade-right"
        data-aos-delay="200"
        textAlign="center"
        fontSize="2.5rem"
        color="black"
        fontWeight="400"
      >
        Supera los desafíos y gana las 5 gemas de la SABIDURÍA
      </H2>
      <br />
      <br />
      <br />
      <div className="Card"></div>
      <H2
        data-aos="fade-right"
        data-aos-delay="200"
        textAlign="center"
        fontWeight="600"
        fontSize="4rem"
        color="#722a79"
      >
        CONOCE A NUESTROS PERSONAJES
      </H2>
      <br />
      <div className="Classmate">
        <ClassmateBruno
          classTriangle="derecha"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473162/assets/Rect%C3%A1ngulo_401_p7vsou_x1xiaq.png"
          title="Bruno"
          text="Bruno es un niño es travieso, despreocupado, curioso, lógico, 
          aventurero y extrovertido. Él te alentará a aprender 
          cada día más y más."
        />
        <br />
      </div>
      <br />
      <div className="Classmate__Sofia">
        <ClassmateSofia
          flexDirection="row-reverse"
          classTriangle="izquierda"
          url="https://res.cloudinary.com/wmcgi/image/upload/v1655473156/assets/Rect%C3%A1ngulo_400_axhego_zdgo3i.png"
          title="Sofía"
          text="Sofía es una niña organizada, formal, metódica, 
          intuitiva y curiosa. Ella será paciente contigo 
          y te apoyará en todo momento."
        />
        <br />
        <br />
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="200"
        id="section"
        className="Home__footer"
      >
        <div className="Home__Fdirection">
          <i className="fas fa-map-marker-alt"></i>
          <Section textAlign="center" fontSize="2rem" fontWeight="400">
            {/* Av. Montenegro - EDIF. L&M - PISO 1 (San Miguel) */}
            Edif. torre Goya Of. 2 #1578 . Pasonskanki entre Cuba y Brazil
          </Section>
          <Section textAlign="center" fontSize="2rem" fontWeight="400">
            {/* Edif. Viveros Piso 7 Of. C, Av. Villazón (Frente al monoblock) */}
            Edif. Arzobispado Piso 3 #616 Plaza Murillo entre Ballivián y
            Bolivar
          </Section>
        </div>
        <br />
        <div className="Home__Femail">
          <i className="fas fa-envelope"></i>
          <Section fontSize="2rem" textAlign="center" fontWeight="400">
            info@capacitaglobal.org
          </Section>
          <br />
          <div className="Home__Ficons">
            <a
              href="https://www.facebook.com/CapcitaGlobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://www.instagram.com/capacitaglobal/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://wa.me/59170140353?text=%C2%A1Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20los%20cursos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
          <div>
            <Section fontSize="2.0rem" fontWeight="400">
              © 2021 Aula Virtual de Capacita Global Internacional.
            </Section>
          </div>
        </div>
        <div className="Home__Fphone">
          <i className="fas fa-phone"></i>
          <Section fontSize="2.0rem" fontWeight="400">
            +591 70140353
          </Section>
          <Section fontSize="2.0rem" fontWeight="400">
            +591 69711888
          </Section>
          {/* <Section fontSize="2.0rem" fontWeight="400">
            +591 2 2312135
          </Section> */}
        </div>
        <br />
        <br />
        <br />
      </div>
      <ButtonFloating />
    </div>
  );
};

const VideoModal = ({ children }) => {
  return (
    <>
      {children && children}
      <div
        style={{
          background: "var(--white)",
          padding: "15px 20px",
          borderRadius: "20px",
        }}
      >
        <VideoPlayer url="https://vimeo.com/672515677" controls={true} />
        <br />
        <div className="video_btn">
          {/* <a
          style={{ background: 'var(--purple)', color: 'white' }}
          className="Home__Hlink__New"
          href="/register "
        >
          ¡Prueba gratis!
        </a> */}
          <a
            style={{ background: "var(--orange)", color: "white" }}
            className="Home__Hlink__New"
            href="/plans-payment"
          >
            Comprar ahora
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="Home__Hlink__New"
            style={{ background: "var(--purple)", color: "white" }}
            href="https://res.cloudinary.com/wmcgi/image/upload/v1655473175/assets/manual_usuario_final_loxxwn_x0awyn.pdf"
          >
            Guía de la plataforma
          </a>
        </div>
      </div>
    </>
  );
};

const HomeCharacter = (props) => {
  const { url, text, title } = props;
  return (
    <div className="HomeCharacter">
      <img src={url} alt="logo" />
      {
        <H2
          textAlign="center"
          /*fontSize="3rem"*/
          color="#6a3081"
          fontWeight="800"
          margin="40px"
        >
          {title}
        </H2>
      }
      <DefaultButton>{text}</DefaultButton>
      {/* <button>{text}</button> */}
      {/* <Section textAlign="center" fontSize="2rem" color="white"></Section> */}
    </div>
  );
};
const Benefit = (props) => {
  const { url, text, title, dataAos, dataAosDelay } = props;
  return (
    <div data-aos={dataAos} data-aos-delay={dataAosDelay} className="Benefit">
      <img src={url} alt="logo" />

      <H2
        textAlign="center"
        margin="0px 0px 0px"
        fontSize="2.5rem"
        color="#E5612B"
        fontWeight="600"
      >
        {title}
      </H2>

      <Section textAlign="center" fontSize="2.3rem" color="black">
        {text}
      </Section>
    </div>
  );
};
const NumberCircle = (props1) => {
  const { title } = props1;
  return (
    <div className="NumberCircle">
      <H2 textAlign="auto" margin="20px 0 10px" fontSize="5rem" color="#E5612B">
        {title}
      </H2>
    </div>
  );
};

const MetodologyImage = (props2) => {
  const { url, text, title } = props2;
  return (
    <div className="MetodologyImage">
      <img src={url} alt="logo" />

      <H2
        textAlign="center"
        margin="0px 0px 0px"
        fontSize="3rem"
        color="#E5612B"
        fontWeight="450"
      >
        {title}
      </H2>

      <Section textAlign="center" fontSize="2rem" color="#E5612B">
        {text}
      </Section>
      <div className="MetodologyImage__hr"></div>
    </div>
  );
};

const CardCut = (props) => {
  const { text, url } = props;
  return (
    <div className="CardCut">
      <img src={url} alt="logo" />

      <Section textAlign="center" fontSize="2.5rem" color="white">
        {text}
      </Section>
    </div>
  );
};
const Places = (props) => {
  const { url, title, dataAos, dataAosDelay } = props;
  return (
    <div data-aos={dataAos} data-aos-delay={dataAosDelay} className="Places">
      <img src={url} alt="place" />
      <H2
        textAlign="center"
        margin="0px 0px 0px"
        fontSize="2rem"
        color="#f2840f"
        fontWeight="700"
      >
        {title}
      </H2>
    </div>
  );
};
const ClassmateBruno = (props) => {
  const { text, url, title, flexDirection, classTriangle } = props;

  return (
    <div
      data-aos="fade-right"
      data-aos-delay="600"
      className="ClassmateBruno"
      style={{ flexDirection: flexDirection }}
    >
      <img src={url} alt="logo" />

      <div
        data-aos="fade-down-left"
        data-aos-delay="800"
        className="ClassmateBruno__container"
      >
        <div
          data-aos="fade-up-left"
          data-aos-delay="1000"
          className="ClassmateBruno__card"
        >
          <H2
            data-aos="fade-down-left"
            data-aos-delay="1200"
            margin="10px 10px 10px"
            fontSize="3rem"
            color="#F2840F"
            fontWeight="800"
            textAlign="left"
          >
            {title}
          </H2>
          <Section
            fontSize="2rem"
            color="black"
            width=""
            margin="0px 30px 60px"
            textAlign="left"
          >
            {text}
          </Section>
        </div>
      </div>
      <div className={`Triangulo1 ${classTriangle}`}></div>
    </div>
  );
};
const ClassmateSofia = (props) => {
  const { text, url, title, flexDirection, classTriangle } = props;

  return (
    <div
      data-aos="fade-left"
      data-aos-delay="600"
      className="ClassmateSofia"
      style={{ flexDirection: flexDirection }}
    >
      <img src={url} alt="logo" />

      <div
        data-aos="fade-down-right"
        data-aos-delay="800"
        className="ClassmateSofia__container"
      >
        <div
          data-aos="fade-up-right"
          data-aos-delay="1000"
          className="ClassmateSofia__card"
        >
          <H2
            data-aos="fade-down-right"
            data-aos-delay="1200"
            margin="10px -20px 0px -20px"
            fontSize="3rem"
            color="#F2840F"
            fontWeight="800"
            textAlign="right"
          >
            {title}
          </H2>
          <Section
            fontSize="2rem"
            color="black"
            width=""
            margin="0px 30px 60px"
            textAlign="right"
          >
            {text}
          </Section>
        </div>
      </div>
      <div className={`Triangulo2 ${classTriangle}`}></div>
    </div>
  );
};
const mapStateToProps = (state) => ({ sesionUser: state.sesionUser });
export default connect(mapStateToProps, { changeModal, openSnackbar })(Home);
