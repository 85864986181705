import { validateArray } from "../../utils/Validation";
import DataType from "./DataType";
const CellMobile = ({ cell, id, HandleActivate, header, activate }) => {
  return (
    <>
      <div className="TableDefault__container">
        <div
          onClick={() => HandleActivate(id, cell)}
          className={`TableDefault__cell ${
            activate === id ? "TableDefault__cell-activate" : ""
          }`}
        >
          {validateArray(header)
            ? header.map((a, i) => {
                return (
                  <div className="TableDefault__column" key={i}>
                    <h2 className="TableDefault__head">{a.name}</h2>
                    <DataType a={a} key={i} head={cell} />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <hr />
    </>
  );
};

export default CellMobile;
