const reducer = (state, action) => {
  switch (action.type) {
    case 'START_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: action.user,
          rols: action.rols,
          institutions: action.institutions,
          authenticate: action.status,
          userDataTest: action.userDataTest,
        },
      }
    case 'CLOSE_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: {},
          authenticate: false,
        },
      }
    case 'CHANGE_MODAL':
      return {
        ...state,
        modalReducer: {
          title: action.title,
          status: action.status,
          message: action.message,
          butttonText: action.butttonText,
          onClick: action.onClick,
        },
      }
    case 'CHANGE_NAVBAR':
      return {
        ...state,
        statusNavbar: action.status,
      }
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        snackbarReducer: {
          open: action.status,
          message: action.content,
          kind: action.kind,
        },
      }
    default:
      return state
  }
}
export default reducer
