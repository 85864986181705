import React, { useState } from 'react'
import { H2 } from '../Text'
import RenderOptions from './RenderOptions'
import { useEffect } from 'react'
const AssociativeSum = (props) => {
  const {
    optionList,
    aciertos,
    fallos,
    fontWeight,
    indexGame,
    setindexGame,
  } = props
  // const [indexGame, setindexGame] = useState({
  //   index: 0,
  // })

  useEffect(() => {
    handleIndex()
  }, [fallos, aciertos])

  function handleIndex() {
    if (indexGame.index < optionList.length - 1) {
      setindexGame((a) => ({
        index: a.index + 1,
      }))
    } else {
      console.log('Se termino el array')
    }
  }
  return (
    <OptionGame
      dataGame={optionList[indexGame.index]}
      handleIndex={handleIndex}
      fontWeight={fontWeight}
      {...props}
    />
  )
}
const OptionGame = (props) => {
  const { dataGame, handleIndex, operation, fontWeight } = props
  const ListColor = ['#6A3081', '#D17742', '#458E7F']
  return (
    <div className="OptionGame">
      <div className="OptionGame__list">
        {dataGame.random.map((number, index) => (
          <React.Fragment key={index}>
            <H2
              textAlign="center"
              fontWeight="900"
              color="#8c7197"
              fontSize="6rem"
              width="100%"
            >
              {number}
              {index >= dataGame.random.length - 1 ? '' : operation}
            </H2>
          </React.Fragment>
        ))}
      </div>
      <div className="MultipleChoice__options">
        {dataGame.answers.map((number, index) => (
          <RenderOptions
            {...props}
            key={index}
            number={number}
            background={ListColor[index]}
            handleIndex={handleIndex}
            dataGame={dataGame}
            fontWeight={fontWeight}
          />
        ))}
      </div>
    </div>
  )
}
export default AssociativeSum
