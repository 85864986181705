import React from 'react'
import './BarProgress.css'
import { H3 } from '../Text'
const BarProgress = (props) => {
  const { time, totalTime } = props
  const percentage = (100 * time) / totalTime
  const color = returnColors(percentage)
  function returnColors(porcent) {
    if (porcent < 34) {
      return 'f42b04'
    }
    if (porcent > 34 && porcent < 67) {
      return 'fde81d'
    }
    return '458e7f'
  }
  return (
  //   <div className="BarProgress" style={{ backgroundColor: 'orange' }} >
  //     <div className="BarProgress__clock">
  //       <img
  //         src="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_85_xzw4vr_aghjmc.png"
  //         alt="Clock"
  //       />
  //     </div>
  //     <div className="BarProgress__lineBar">
  //       <div
  //         style={{
  //           width: `${percentage}%`,
  //           background: `#${color}`,
  //         }}
  //         className="BarProgress__line"
  //       ></div>
  //       <br />
  //       <H3
  //         margin="5px 10px"
  //         fontSize="2.5rem"
  //         fontWeight="700"
  //         color="var(--purple)"
  //       >
  //         Tiempo de respuesta
  //       </H3>
  //     </div>
  //   </div>
  // )


  <div
  className="BarProgress"
  style={{
    backgroundColor: 'orange',
    padding: '30px 7px', 
    border: '2px solid black', 
    borderRadius: '5px',
    
  }}
>
  <div className="BarProgress__clock">
    <img
      src="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_85_xzw4vr_aghjmc.png"
      alt="Clock"
    />
  </div>
  <div className="BarProgress__lineBar">
    <div
      style={{
        width: `${percentage}%`,
        background: `#${color}`,
        height: '10px' 
      }}
      className="BarProgress__line"
    ></div>
    <br />
    <h3
      style={{
        margin: '5px 10px',
        fontSize: '2.5rem',
        fontWeight: '700',
        color: 'var(--purple)'
      }}
    >
      Tiempo de respuesta
    </h3>
  </div>
</div>
)}

export default BarProgress
