import './Topic.css'
import { CardTopic } from '../../../containers/lessons'
import { useAxiosAuth } from '../../../hook/'
import Load from '../../../components/Loadings/Loading/Load'
import { validateArray } from '../../../utils/Validation'
import { Title } from '../../../components/Text'
import { ButtonIcon } from '../../../components/buttons'
import { ModalLocked } from '../../../components/modal'
import TopicContent from '../../../containers/lessons/topic/TopicContent'
import { useState } from 'react'
const Topic = (props) => {
  const {
    match: { params },
  } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/Lesson/${params.lid}/themes`,
  })
  const [modal, setmodal] = useState(false)
  return (
    <>
      <div className="Topic">
        {loading ? (
          <Load />
        ) : validateArray(response) ? (
          response.length > 1 ? (
            <>
              <br />
              <ButtonIcon to="/lessons" />
              <div className="Topic__main">
                {response
                  .sort((a, b) => a.order - b.order)
                  .map((data, index) => (
                    <CardTopic
                      key={data.id}
                      dataRequest={data}
                      lid={params.lid}
                      index={index + 1}
                      handleModal={() => setmodal(!modal)}
                    />
                  ))}
              </div>
            </>
          ) : (
            response.map((data) => (
              <TopicContent
                {...props}
                key={data.id}
                background
                dataRequest={data}
                lid={params.lid}
                match={{ params: { lid: params.lid, tid: data.id } }}
                handleModal={() => setmodal(!modal)}
              />
            ))
          )
        ) : null}
      </div>
      <ModalLocked
        text="los temas habilitados"
        modal={modal}
        handleModal={() => setmodal(!modal)}
      />
    </>
  )
}

export default Topic
