import { useEffect } from 'react'
import { Title } from '../../../../../components/Text'
import BarProgress from '../../../../../components/progressBar/BarProgress'
import { TimerHook } from '../../../../../hook'
import './GameControllers.css'
import {
  convertTimeToMilliseconds,
  formatMilliseconds,
} from '../../../../../utils/ConvertDate'
import { getName } from '../../../../../utils/NameSimulators'
const GameControllers = (props) => {
  const {
    gameData,
    controllers,
    handleMenu,
    handleTimeGlobal,
    reloadBoolean,
    rel,
    timeGamin,
    totalTimeGamin,
    colorLevel,
    record,
    recordLoad,
    mute,
    setmute,
  } = props
  useEffect(() => {
    rel()
  }, [reloadBoolean])

  function onSaving() {
    handleTimeGlobal()
  }

  const { time } = TimerHook({
    onSaving: onSaving,
    timeLimit: formatMilliseconds(gameData.globalTime),
  })
  return (
    <div className="GameControllers">
      <div className="GameControllers__title">
        <div style={{ display: 'flex', gap: '10px' }}>
          <i
            className="fas fa-undo GameControllers__undo"
            onClick={() => {
              handleMenu(`/simulation/${gameData.id}`, 'Empezar el juego')
            }}
          ></i>
          <i
            className={`fas fa-${
              mute ? 'volume-mute' : 'volume-up'
            } GameControllers__undo`}
            onClick={() => setmute(!mute)}
          ></i>
        </div>
        <Title
          innerHTML
          color={colorLevel}
          fontSize="5rem"
          width="auto"
          fontWeight="900"
          textAlign="center"
        >
          {getName(gameData.name, gameData.baseNumber)}
        </Title>
        <div className="Controller__LifeContainer">
          <ProgressGlobal
            time={convertTimeToMilliseconds(time)}
            totalTime={convertTimeToMilliseconds(gameData.globalTime)}
          />
          <div className="Controller__life">
            <h3>{controllers.aciertos}</h3>
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473155/assets/Grupo_83_efc5cr_dgk4ba.png"
              alt="star"
            />
            <h4>Aciertos</h4>
          </div>
          <div className="Controller__life">
            <h3>{controllers.fallos}</h3>
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473153/assets/Grupo_81_aq4s4o_kdyp0f.png"
              alt=""
            />
            <h4>Fallos</h4>
          </div>
          {recordLoad ? null : (
            <div className="Controller__life">
              <h3>{record.right}</h3>
              <h4>Record Aciertos</h4>
            </div>
          )}
        </div>
      </div>
      {totalTimeGamin !== '00:01:00.900' ? (
        <div className="Controller__BarProgress">
          <BarProgress
            time={convertTimeToMilliseconds(timeGamin)}
            totalTime={convertTimeToMilliseconds(totalTimeGamin)}
          />
        </div>
      ) : null}
    </div>
  )
}

function ProgressGlobal(props) {
  const { time, totalTime } = props
  const percentage = (100 * time) / totalTime
  return (
    <div
      className="Controller__life Controller__timeGlobal"
      style={{
        background: `transparent linear-gradient(90deg, #458E7F ${percentage}%, #ffffffcc ${percentage}%`,
      }}
    >
      <h3 style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Tiempo Global</h3>
    </div>
  )
}
export default GameControllers
