import { connect } from "react-redux";
import { Register_in } from "../../../components/forms";
import { Facebook, Google } from "../../../components/buttons";
import ModalDefault from "../../../components/modal/ModalDefault";
import Home from "../../Home/Home";
import Seller from "../../Seller/Seller";
import { Title, H3 } from "../../../components/Text";
import "./Register.css";
import {
  startSession,
  openSnackbar,
  changeModal,
} from "../../../store/actions";
import log from "../../../assets/img/logo2.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Tutorial } from "../../Tutorial/Tutorial";

const Register = (props) => {
  const {
    match: { params },
  } = props;
  const [view, setview] = useState(params.uid ? 2 : 1);

  return (
    <div className="Auth">
      {params.uid ? <Seller /> : <Home />}
      <ModalDefault
        closeOutside={true}
        onClose={() => props.history.push(params.uid ? "/seller" : "/")}
      >
        <div className="Auth__rotate">
          <div className="Auth__view">
            {!params.uid && (
              <H3
                fontSize="2rem"
                textAlign="end"
                margin="0 0 20px"
                color="var(--green)"
              >
                <Link to="/auth/login">INICIAR SESIÓN</Link>
              </H3>
            )}
            <Title textAlign="center" fontSize="2.5rem" color="#6A3081">
              {params.uid
                ? "FORMULARIO DE CONTACTO"
                : "ACCESO GRATIS POR 7 DÍAS"}
            </Title>
            {/* <Tutorial
              title="¿Tienes problemas con el registro?"
              subTitle="Mira el tutorial"
              textBtn="Tutorial"
              {...props}
              videoModalTitle="Tutorial de registro"
            /> */}
            <div className="Auth__container">
              {view === 1 ? (
                <div className="Auth__image">
                  <div className="">
                    <img alt="logo" src={log} />
                  </div>

                  <div className="Auth__RegisterContainer">
                    <Register_in {...props} />
                  </div>

                  <div>
                    {/* <Facebook {...props} /> */}
                    {/* <br /> */}
                    <Google {...props} />
                    <div className="Auth__containerHr">
                      <div className="Auth__hr"></div>
                      <H3
                        color="gray"
                        fontSize="2rem"
                        margin="1px 5px"
                        width="min-content"
                      ></H3>
                    </div>
                    {/* <button onClick={() => setview(2)} className="btnExternal">
                      <i className="fas fa-envelope"></i> Regístrese con su
                      email
                    </button> */}
                  </div>
                </div>
              ) : (
                <div className="Auth__RegisterContainer">
                  <button onClick={() => setview(1)} className="Auth__btn">
                    <i className="fas fa-undo"></i>
                  </button>
                  <Register_in {...props} />
                </div>
              )}
            </div>
            {/* <span className="forgotPassword">
              <Link
                to={{
                  pathname: '/auth/login',
                  state: {
                    from: props.location.state
                      ? props.location.state.from
                      : '/',
                  },
                }}
              >
                Iniciar sesión
              </Link>
            </span> */}
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default connect(null, { startSession, openSnackbar, changeModal })(
  Register
);
