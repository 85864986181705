import { useEffect } from 'react'
import './assets/style/globalStyle.css'
import { Theme } from './components/theme'
import useActionParam from './hook/useActionParam'
import { connect } from 'react-redux'
import { startSession, closeSession, changeNavbar } from './store/actions'
import { getActionAuth } from './services/actions/AuthAction'
import { validateStatus } from './utils/Validation'
import Load from './components/Loadings/Loading/Load'
import { Layout } from './containers/Layout'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-54ZCDG9',
}
TagManager.initialize(tagManagerArgs)

const App = (props) => {
  const { startSession, sesionUser, closeSession } = props
  const idLs = window.localStorage.getItem('id')
  const { dataRequest, statusRequest } = useActionParam({
    action: getActionAuth,
    param: idLs ? `/users/${idLs}/detailed` : '',
  })

  useEffect(() => {
    if (statusRequest && validateStatus(statusRequest)) {
      const { institutions, userToReturn, testData } = dataRequest
      startSession(
        userToReturn,
        userToReturn.rols || [],
        institutions,
        true,
        testData,
      )
    }
  }, [dataRequest, statusRequest, startSession])
  useEffect(() => {
    if (statusRequest !== 200 && statusRequest !== null) {
      closeSession()
      localStorage.removeItem('token_seguridad')
      localStorage.removeItem('id')
    }
  }, [statusRequest, closeSession])
  if (
    (!sesionUser.authenticate && !idLs) ||
    (idLs && sesionUser.authenticate)
  ) {
    return (
      <Theme>
        <Layout {...props} />
      </Theme>
    )
  } else {
    return <Load />
  }
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
    statusNavbar: state.statusNavbar,
  }
}
export default connect(mapStateToProps, {
  startSession,
  closeSession,
  changeNavbar,
})(App)
