import HttpClient from '../HttpClient'

export const putAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putActionEmpty = (url) => {
  return new Promise((resolve, eject) => {
    HttpClient.putEmpty(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}

export const postAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
