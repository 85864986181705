import { RegisterChildren } from '../../components/forms'
const FormChildren = (props) => {
  const { setdata, handleView, data, setview,setshowForme,showForme } = props
  function handleSignIn(children) {
    setdata((a) => ({
      ...a,
      child: children,
    }))
    handleView(2, children)
  }

  function handleDisabled() {
    let aux = false
    if (data) {
      for (let key in data) {
        if (data[key] === '') {
          aux = true
        }
      }
    } else {
      aux = true
    }
    return aux
  }
  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <RegisterChildren
        {...props}
        handleSignIn={handleSignIn}
        reload={() => {}}
        onClose={() => {}}
        submit={false}
        data={data}
        setshowForme = {setshowForme}
        showForme = {showForme}
        
      />
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <button
          type="button"
          onClick={() => setview(0)}
          className="UserData__btn"
          style={{ background: 'white', color: '#6a3081' }}
        >
          Volver al paso anterior
        </button>
        <button
          type="button"
          onClick={() => {handleView(2)}}
          className="UserData__btn"
          disabled={handleDisabled()}
        >
          Siguiente paso
        </button>
      </div>
    </div>
  )
}

export default FormChildren
