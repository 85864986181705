import { withRouter } from 'react-router-dom'
import { Simulator } from '../../../../containers/lessons'
import { useAxiosAuth } from '../../../../hook'
import Loading from '../../../../components/Loadings/Loading/LoadSimulation'
import { VideoPlayer } from '../../../../components/common'
import './Simulation.css'
import Aos from 'aos'
import { useState, useEffect } from 'react'
import { backgroundImage } from '../../../../utils/Constant'
const Simulation = (props) => {
  const [view, setview] = useState(false)
  const [mute, setmute] = useState(false)
  const [indexNum, setindexNum] = useState(0)

  const {
    match: { params },
    history,
  } = props

  const validateMultiplication = (state) => {
    const titleMultiplication = [
      'ConmutativeMultiplication',
      'AssociativeMultiplication',
      'DistributiveMultiplication',
      'MultiplicationTable',
      'MultiplationByFive',
      'MultiplicationDecomposition',
      'BladeMethod',
      'MultiplyNumberBy10',
      'MultiplyNumberByAnotherThatEndsIn0',
      'MultiplicationBy9´s',
      'MultiplicationByEleven',
      'Squared',
      'Square2-DigitNumber',
      'RaiseAnySquareNumber',
      'AspaSimple',
    ]
    const titleDivision = [
      'DivisionByYédicoMethod',
      'DivisionByTraditionalMethod',
      'DivisionTable',
    ]
    if (titleMultiplication.indexOf(state) > -1) {
      return 'StartSimulationByMultiplication'
    }
    if (titleDivision.indexOf(state) > -1) {
      return 'StartSimulationByDivision'
    }
    return 'StartSimulation'
  }
  function validateController(type) {
    const controller = {
      Multiplicación: 'StartSimulationByMultiplication',
      División: 'StartSimulationByDivision',
    }
    return controller[type] || 'StartSimulation'
  }

  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/Simulation/${params.sid}/${validateController(params.name)}?level=${
      params.level
    }&baseNumber=${params.baseNumber}`,
  })

  const { response: record, loading: recordLoad } = useAxiosAuth({
    method: 'GET',
    url: `/Simulation/${params.sid}/record`,
  })

  function handleMenu() {
    history.push(
      `/lessons/${params.lid}/topic/${params.tid}/simulation/${params.sid}`,
    )
  }
  useEffect(() => {
    let intervalNum
    if (!loading) {
      intervalNum = setTimeout(function () {
        if (indexNum == 4) {
          setview(true)
        } else {
          setindexNum(indexNum + 1)
        }
      }, 700)
    }
    return function cleanup() {
      clearInterval(intervalNum)
    }
  }, [indexNum, loading])

  Aos.init()
  const messageNum = ['', '3', '2', '1', 'A jugar ']

  return loading ? (
    <Loading />
  ) : (
    <>
      <div style={{ display: 'none' }}>
        <VideoPlayer
          loop={true}
          playing={true}
          volume={mute ? 0 : 0.07}
          url="https://res.cloudinary.com/wmcgi/video/upload/v1655473180/assets/suyw34ed05hvsigepyba_jmqphz_mdodcj.wav"
        />
      </div>
      {view ? (
        <Simulator
          {...props}
          backgroundImage={backgroundImage[params.lid]}
          gameData={response}
          handleMenu={handleMenu}
          record={record}
          mute={mute}
          setmute={setmute}
          recordLoad={recordLoad}
        />
      ) : (
        <h2 className="clock" data-aos="fade-right" data-aos-delay="200">
          {messageNum[indexNum]}
        </h2>
      )}
    </>
  )
}

export default withRouter(Simulation)
