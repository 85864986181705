import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { useState } from 'react'
import './style/Inputs.css'
import InputStyle from './style/InputStyle'

const Input = (props) => {
  const { label, name, disabled, type, errorBoolean, ...rest } = props
  const [check, setcheck] = useState({
    typeInput: 'password',
  })
  const handleCheck = (e) => {
    const { checked } = e.target
    setcheck({
      typeInput: checked ? 'text' : 'password',
    })
  }
  return (
    <InputStyle
      className={`form-control ${errorBoolean && 'form-errorControl'}`}
      type={type}
    >
      <label className="input__check-gray" htmlFor={name}>
        {label}
      </label>
      <Field
        id={name}
        name={name}
        disabled={disabled}
        type={type !== 'password' ? type : check.typeInput}
        step={type === 'time' ? '2' : null}
        className="switch"
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
      {type === 'password' && (
        <div className="Input__check">
          <label className="Input__check-purple" htmlFor="checkBox">
            Mostrar contraseña
          </label>
          <input onChange={handleCheck} type="checkbox" name="checkBox" />
        </div>
      )}
    </InputStyle>
  )
}

export default Input
