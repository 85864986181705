import { useState } from 'react'
import GamesControl from './Controls/GamesControl'
import GameControllers from './Controls/GameControllers'
import './Simulator.css'
import {
  convertTimeToSeconds,
  formatMilliseconds,
} from '../../../../utils/ConvertDate'
import { TimerHook } from '../../../../hook'
import { postAction } from '../../../../services/actions/TokenAction'
import { VideoPlayer } from '../../../../components/common'
const Simulator = (props) => {
  const url = [
    'https://res.cloudinary.com/wmcgi/video/upload/v1655473170/assets/check_o8ddry_jugjwr.wav',
    'https://res.cloudinary.com/wmcgi/video/upload/v1655473151/assets/ojuzjgjderpi7owtz5fp_xm9dls_jgmhoo.wav',
  ]
  const {
    gameData,
    handleMenu,
    history,
    match: { params },
    record,
    recordLoad,
    mute,
    setmute,
    backgroundImage,
  } = props

  const [onStart, setonStart] = useState({
    play: false,
    url: '',
  })
  const [indexGame, setindexGame] = useState({
    index: 0,
    principal: gameData.baseNumber,
    baseNumber: gameData.baseNumber,
  })
  const [gameControllers, setgameControllers] = useState({
    timeGame: convertTimeToSeconds(gameData.globalTime),
    life: gameData.lives,
    lifeMax: gameData.lives,
    aciertos: 0,
    fallos: 0,
  })

  const { reload: rel, time: timeGamin, onPause } = TimerHook({
    onSaving: handleSimulation,
    timeLimit: formatMilliseconds(gameData.levelTime),
  })

  function handleSimulation() {
    setTimeout(function () {
      handleWrong(gameData.optionList[indexGame.index])
    }, 50)
  }

  function handleWrong(option, Answer) {
    setonStart({
      play: true,
      url: url[1],
    })
    setgameControllers((a) => ({
      ...a,
      fallos: gameControllers.fallos + 1,
    }))
    onSubmitStatus(option, Answer, false)
    setTimeout(() => {
      setonStart({
        play: false,
        url: '',
      })
    }, 300)
  }
  function handleSuccess(option, Answer) {
    setonStart({
      play: true,
      url: url[0],
    })
    setgameControllers((a) => ({
      ...a,
      aciertos: gameControllers.aciertos + 1,
    }))
    onSubmitStatus(option, Answer, true)
    setTimeout(() => {
      setonStart({
        play: false,
        url: '',
      })
    }, 300)
  }
  function handleSubLife() {
    if (gameControllers.life > 1) {
      setgameControllers((a) => ({
        ...a,
        fallos: gameControllers.fallos + 1,
      }))
      rel()
    } else {
      handleMenu(`/simulation/${gameData.id}`, 'Empezar el juego')
    }
  }
  function handleTimeOutGlobal() {
    history.push(
      `/lessons/${params.lid}/topic/${params.tid}/simulation/${params.sid}/result/${gameData.mechanicUserId}`,
    )
  }
  async function onSubmitStatus(option, Answer, right) {
    const values = {
      Answer: Answer ? Answer : null,
      Time: timeGamin,
      Right: right,
      MechanicUserId: gameData.mechanicUserId,
      UserId: window.localStorage.getItem('id'),
      OptionId: option ? option.id : 0,
      answers: option ? option.answers : null,
      rightAnswer: option ? option.rightAnswer : null,
    }
    rel()
    try {
      await postAction('/Response', values)
    } catch (e) {
      console.log(e)
    }
  }

  const backgroundLevel = {
    '00:01:00': '#458E7F',
    '00:00:06': '#458E7F',
    '00:00:05': '#458E7F',
    '00:00:04': '#D17742',
    '00:00:03': '#D17742',
    '00:00:02': '#AD3E8E',
    '00:00:01': '#AD3E8E',
    '00:00:00.5000000': '#6A3081',
  }
  return (
    <div
      className="Simulator"
      style={{
        // background: backgroundLevel[gameData.levelTime] + '4d',
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div style={{ display: 'none' }}>
        <VideoPlayer
          controls
          loop={false}
          playing={onStart.play}
          url={onStart.url}
        />
      </div>
      <div className="Simulator__container">
        <GameControllers
          mute={mute}
          setmute={setmute}
          controllers={gameControllers}
          handleTimeOut={handleSubLife}
          handleTimeGlobal={handleTimeOutGlobal}
          gameData={gameData}
          handleMenu={handleMenu}
          rel={rel}
          timeGamin={timeGamin}
          totalTimeGamin={formatMilliseconds(gameData.levelTime)}
          colorLevel={backgroundLevel[gameData.levelTime]}
          record={record}
          recordLoad={recordLoad}
        />
        <GamesControl
          onPause={onPause}
          control={gameData.name}
          gameData={gameData}
          optionList={[...gameData.optionList]}
          handleSuccess={handleSuccess}
          handleWrong={handleWrong}
          aciertos={gameControllers.aciertos}
          fallos={gameControllers.fallos}
          indexGame={indexGame}
          timeGamin={timeGamin}
          setindexGame={setindexGame}
        />
      </div>
    </div>
  )
}

export default Simulator
